import cn from 'classnames'
import React, { FC } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { Section } from '@const/consts'

import { Button, Content } from '@infologistics/frontend-libraries'

import { resetError404 } from '@store/modules/utils/actions'

import { AllPage404Props as AllProps, IPage404PropsFromDispatch as IPropsFromDispatch, IPage404PropsFromState as IPropsFromState } from './types'
import { OrganizationLink } from '@containers/Navigation'
import { IApplicationState } from '@app/store/types/commonTypes'

import styles from './Page404.500.module.css'

const Page404: FC<AllProps> = (props: AllProps) => {
  const { onResetError404, t, isRootMode } = props

  return (
    <Content classes='d-flex justify-content-center align-items-center'>
      <div className='d-flex align-items-center'>
        <div className={styles.content}>
          <p className={cn('fw-700', 'text-brand', 'mb-4', styles.error_code)}>404</p>
          <p className='fw-600 mb-2'>{t('error:404.title')}</p>
          <div className='text-muted font-sm mb-6'>
            <p className='mb-1'>{t('error:404.title_reasons')}</p>
            <ul className='pl-3 mb-2'>
              <li>{t('error:404.one')}</li>
              <li>{t('error:404.two')}</li>
              <li>{t('error:404.three')}</li>
            </ul>
            <p className='mb-0'>{t('error:404.footer')}</p>
          </div>
          <Button theme='primary' onClick={onResetError404}>
            <OrganizationLink to={isRootMode ? Section.DOCUMENTS_IN_PROGRESS : Section.DOCUMENTS_ALL} className={styles.link}>
              {t('error:main')}
            </OrganizationLink>
          </Button>
        </div>
        <img src='/assets/img/error.svg' width='493' height='392' alt='' />
      </div>
    </Content>
  )
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isRootMode: state.utils.isRootMode
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onResetError404: () => dispatch(resetError404)
})

const Page404Class = connect(mapStateToProps, mapDispatchToProps)(Page404)

export default withTranslation('error')(Page404Class)
