import { Dispatch } from 'redux'

import TokensService from '@services/tokensService'

import { clearLocalStorage } from '@utils/utils'

import { IApplicationState } from '@store/types/commonTypes'
import { ILogoutAction, USER_LOGOUT } from './types'

export const logoutAction = (): ILogoutAction => ({ type: USER_LOGOUT })

export const logout = () => (dispatch: Dispatch, getState: () => IApplicationState) => {
  const { oguid } = getState().user.profile

  TokensService.clearCookies(oguid)
  clearLocalStorage()

  dispatch(logoutAction())
}
