import { Actions, INavigationState, NavigationActionTypes } from './types'

export const initialState: INavigationState = {
  activeMenu: '',
  allBadges: {
    taskDueDatetimeViolationQuantity: 0,
    taskQuantity: 0,
    todoQuantity: 0,
    taskUnreadQuantity: 0
  },
  badges: {
    taskDueDatetimeViolationQuantity: 0,
    taskQuantity: 0,
    todoQuantity: 0,
    taskUnreadQuantity: 0
  },
  badgesNotices: {
    needToActionQuantity: 0,
    toDoQuantity: 0,
    infoQuantity: 0
  },
  menuItems: [],
  menuItemsWithFilters: [],
  prevMenu: "",
  taskIdx: -1
}

export default function navigationReducer(state = initialState, action: Actions): INavigationState {
  switch (action.type) {
    case NavigationActionTypes.RESET_NAVIGATION:
      return { ...initialState }

    case NavigationActionTypes.RESET_NAVIGATION_AFTER_ORG_CHANGE:
      return {
        ...state,
        activeMenu: initialState.activeMenu,
        badges: initialState.badges,
        menuItems: initialState.menuItems,
        menuItemsWithFilters: initialState.menuItemsWithFilters
      }

    case NavigationActionTypes.SET_ACTIVE_MENU:
      return {
        ...state,
        activeMenu: action.payload
      }

    case NavigationActionTypes.SET_ALL_BADGES:
      return {
        ...state,
        allBadges: { ...action.payload }
      }

    case NavigationActionTypes.SET_BADGES:
      return {
        ...state,
        badges: { ...action.payload }
      }

    case NavigationActionTypes.SET_BADGES_NOTICES:
      return {
        ...state,
        badgesNotices: { ...action.payload }
      }

    case NavigationActionTypes.SET_MENU_ITEMS:
      return {
        ...state,
        menuItems: [...action.payload]
      }

    case NavigationActionTypes.SET_MENU_ITEMS_WITH_FILTERS:
      return {
        ...state,
        menuItemsWithFilters: [...action.payload]
      }

    case NavigationActionTypes.SET_PREV_MENU:
      return {
        ...state,
        prevMenu: action.payload
      }

    case NavigationActionTypes.SET_TASK_IDX:
      return {
        ...state,
        taskIdx: action.payload
      }

    default:
      return state
  }
}
