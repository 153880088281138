import { Actions, IReceiptsState, ReceiptsActionTypes } from './types'

export const initialState: IReceiptsState = {
  data: []
}

export default function receiptsReducer(state: IReceiptsState = initialState, action: Actions): IReceiptsState {
  switch (action.type) {
    case ReceiptsActionTypes.RESET_RECEIPTS:
      return { ...initialState }

    case ReceiptsActionTypes.GET_RECEIPTS:
      return {
        ...state,
        data: [...action.payload]
      }

    default:
      return state
  }
}
