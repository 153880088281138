import { Action, ActionCreator } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'

import groupsService from '@services/groups'

import { ResponseCode } from '@const/consts'

import { IParams } from '@store/types/commonTypes'
import {
  GroupActions,
  GroupActionTypes,
  GroupThunkDispatch,
  IActionGetGroup,
  IActionGetMembers,
  IActionResetGroup,
  IActionUpdateGroupName,
  IGroup,
  IMember, GroupThunkAction
} from "./types"

export const getGroupAction: ActionCreator<Action> = (group: IGroup): IActionGetGroup => ({
  payload: group,
  type: GroupActionTypes.GET_GROUP
})

export const getMembersAction: ActionCreator<Action> = (members: IMember[]): IActionGetMembers => ({
  payload: members,
  type: GroupActionTypes.GET_MEMBERS
})

export const resetGroup: IActionResetGroup = {
  type: GroupActionTypes.RESET_GROUP
}

export const updateGroupNameAction: ActionCreator<Action> = (groupName: string): IActionUpdateGroupName => ({
  payload: groupName,
  type: GroupActionTypes.UPDATE_GROUP_NAME
})

export const actions: GroupActions = {
  getGroup: getGroupAction,
  getMembers: getMembersAction,
  updateGroupName: updateGroupNameAction
}

// thunk

export const getGroup: ActionCreator<GroupThunkAction> = (
  groupOguid: string
) => (dispatch: GroupThunkDispatch): Promise<AxiosResponse<IGroup>> =>
  groupsService.getGroup(groupOguid)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.getGroup(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const getMembers: ActionCreator<GroupThunkAction> = (
  groupOguid: string,
  params?: IParams
) => (dispatch: GroupThunkDispatch): Promise<AxiosResponse<IMember[]>> =>
  groupsService.getGroupMembers(groupOguid, { params })
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.getMembers(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))
