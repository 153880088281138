import { Action, ActionCreator } from 'redux'

import { IActionCloseModal, IActionShowModal, ModalActionTypes } from './types'

export const showModal: ActionCreator<Action> = (id: string): IActionShowModal => ({
  payload: id,
  type: ModalActionTypes.SHOW_MODAL
})

export const closeModal: IActionCloseModal = { type: ModalActionTypes.CLOSE_MODAL }
