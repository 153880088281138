import Loadable from 'react-loadable'

import { SpinnerWrapper } from '@containers/Navigation'
import { AllRoles, Path } from '@const/consts'
import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'

const Orgs = Loadable({
  loader: () => import('@views/organization/orgs/Orgs'),
  loading: SpinnerWrapper
})

export const routes: IRoute[] = [
  {
    path: Path.ORGS,
    exact: true,
    name: 'Orgs',
    component: Orgs,
    roles: AllRoles,
    isExchangeParticipant: true,
    useRootMode: true
  }
]
