import React, { ReactElement, FC, MouseEvent, useEffect } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Logo } from '@infologistics/frontend-libraries'
import { OrganizationLink } from '@containers/Navigation'
import { IApplicationState } from '@store/types/commonTypes'
import { Section } from '@const/consts'
import { getIsInterfaceDisabled } from '@utils/utils';

import styles from './LogoWrapper.module.css'

const defaultLogoSrc = '/assets/img/docuforce.png'

const LogoWrapper: FC = (): ReactElement => {
  const { logoOrg, profile: { orgs } } = useSelector((state: IApplicationState) => state.user)
  const { isRootMode } = useSelector((state: IApplicationState) => state.utils)

  let isDisabled = false
  const logoOrgSrc = logoOrg ?? defaultLogoSrc
  const logoSrc = isRootMode ? defaultLogoSrc : logoOrgSrc

  const handleLogoLinkClick = (evt: MouseEvent<HTMLAnchorElement>): void => {
    if (isDisabled) {
      evt.preventDefault()
    }
  }

  useEffect(() => {
    isDisabled = getIsInterfaceDisabled()
  }, [orgs])

  return (
    <div className={cn(styles.logo_wrapper, !isRootMode && logoOrg && styles.logo_custom_wrapper)}>
      <OrganizationLink
        to={isRootMode ? Section.DOCUMENTS_IN_PROGRESS : Section.DOCUMENTS_ALL}
        className='d-flex align-items-center'
        onClick={handleLogoLinkClick}
        onAuxClick={handleLogoLinkClick}
      >
        <Logo src={logoSrc} alt='docuForce'/>
      </OrganizationLink>
    </div>
  )
}

export default LogoWrapper
