import React, { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { connect, useSelector, useDispatch } from 'react-redux'
import cn from 'classnames'

import { Button, IconTimes, IconRefresh, Tabs, ILibTab } from '@infologistics/frontend-libraries'

import { NoticeCategory } from '@const/consts'
import { Notices as NoticesTranslate } from '@const/translations'
import { displayErrorNotification, getNoticesParams } from '@utils/utils'
import { getBadgesNotices } from '@store/modules/navigation/actions'
import { toggleNotices } from '@store/modules/utils/actions'
import { getNotices, actions } from '@store/modules/notices/actions'
import { IApplicationState } from '@store/types/commonTypes'
import {
  AllNoticesProps as IProps,
  INoticesPropsFromState as IPropsFromState
} from './types'
import NoticesTab from '../NoticesTab'

import styles from './Notices.module.css'

const tabsContent = {
  [NoticeCategory.NEED_TO_ACTION]: <NoticesTab />,
  [NoticeCategory.INFO]: <NoticesTab isInfo={true} />
}
const Notices: FC<IProps> = ({
  sideWindowBtnRef
}) => {
  const { t } = useTranslation(['notification'])

  const { badgesNotices: badges } = useSelector((state: IApplicationState) => state.navigation)
  const { isLoading, isLoadingFromActionButtons } = useSelector((state: IApplicationState) => state.notices)
  const dispatch = useDispatch<any>()

  const [activeTab, setActiveTab] = useState(NoticeCategory.NEED_TO_ACTION)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleClickOutside = ({ target }: MouseEvent): void => {
      if (target instanceof Node && (sideWindowBtnRef.current?.contains(target) ?? wrapperRef.current?.contains(target))) return
      dispatch(toggleNotices(false))
    }

    document.addEventListener('click', handleClickOutside, true)
    return () => document.removeEventListener('click', handleClickOutside, true)
  })

  const handleTabToggle = (tab: string) => () => {
    if (tab === activeTab) return

    setActiveTab(NoticeCategory[tab])

    dispatch(actions.setNotices([]))
  }

  const getTabs = (): ILibTab[] => {
    const { needToActionQuantity, infoQuantity } = badges

    return [
      {
        name: NoticeCategory.NEED_TO_ACTION,
        translation: t(NoticesTranslate.tabs.needToAction),
        onClick: handleTabToggle,
        badgeProps: {
          value: needToActionQuantity > 0 ? needToActionQuantity.toString() : '',
          theme: 'danger'
        },
        isDisabled: isLoading || isLoadingFromActionButtons
      },
      {
        name: NoticeCategory.INFO,
        translation: t(NoticesTranslate.tabs.info),
        onClick: handleTabToggle,
        badgeProps: {
          value: infoQuantity > 0 ? infoQuantity.toString() : '',
          theme: 'default'
        },
        isDisabled: isLoading || isLoadingFromActionButtons
      }
    ]
  }

  const handleClose = (): void => {
    dispatch(toggleNotices(false))
  }

  const handleRefresh = (): void => {
    const params = getNoticesParams(activeTab)

    dispatch(actions.setNoticesPage(1))
    dispatch(actions.toggleLoader(true))

    Promise.all([dispatch(getNotices(params)), dispatch(getBadgesNotices())])
      .finally(() => dispatch(actions.toggleLoader(false)))
      .catch(displayErrorNotification)
  }

  return (
    <div className={cn(styles.notices, 'absolute')} ref={wrapperRef}>
      <p className={cn(styles.notices_header, 'py-4 text-center mb-0 relative')}>
        {t(NoticesTranslate.title)}
        <Button theme='text' externalClass={cn(styles.refresh, 'absolute')} onClick={handleRefresh} isDisabled={isLoading}>
          <IconRefresh color='light' />
        </Button>
        <Button theme='text' externalClass={cn(styles.close, 'absolute')} onClick={handleClose}>
          <IconTimes color='light' />
        </Button>
      </p>
      <Tabs tabs={getTabs()} content={tabsContent} activeTab={activeTab} theme='flat' />
    </div>
  )
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  notices: state.notices.notices
})

export default connect(mapStateToProps)(Notices)
