import { connect } from 'react-redux'

import MessagesTab from '@common/Messages/MessagesTab/MessagesTab'

import { getMessages, actions } from '@store/modules/messages/actions'

import { IApplicationState, IParams, Nullable } from '@store/types/commonTypes'
import {
  IMessagesTabPropsFromDispatch as IPropsFromDispatch,
  IMessagesTabPropsFromState as IPropsFromState
} from '@containers/Messages/MessagesTab/types'
import { getAllBadges } from '@app/store/modules/navigation/actions'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isLoading: state.messages.isLoading,
  messages: state.messages.messages,
  orgOguid: state.user.activeOrganization.oguid,
  page: state.messages.page
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetBadges: () => dispatch(getAllBadges()),
  onGetMessages: (params: IParams, isPushMessages?: boolean) => dispatch(getMessages(params, isPushMessages)),
  onToggleLoader: (isLoading: boolean) => dispatch(actions.toggleLoader(isLoading)),
  onSetMessagesPage: (page: Nullable<number>) => dispatch(actions.setMassagesPage(page))
})

export default connect(mapStateToProps, mapDispatchToProps)(MessagesTab)
