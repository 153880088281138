import React, { FC } from 'react'
import cn from 'classnames'

import { Header } from '@infologistics/frontend-libraries'

import { OrganizationsList } from '@containers'
import { LogoWrapper } from '@common/Layout'
import Buttons from './components/Buttons/Buttons'
import UserMenu from './components/UserMenu'

import styles from './Header.module.css'

export const HeaderWrapper: FC = () => (
  <Header>
    <LogoWrapper />

    <OrganizationsList />

    <div className={cn(styles.user_name,'d-flex align-items-center ml-auto mr-3 text-muted-600 text-middle')}>
      <Buttons />
      <UserMenu />
    </div>
  </Header>
)

export default HeaderWrapper
