import { Action, ActionCreator } from 'redux'
import { AxiosResponse } from 'axios'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import {
  IAssignedToSubOrg,
  IAssignedToUser,
  IGroup,
  Nullable
} from '@store/types/commonTypes'
import { AssignMode } from '@const/consts'
import { IConstraintToSet } from '@store/modules/constraints/types'
import { ILibPresetValue } from '@infologistics/frontend-libraries'
import { ICondition } from '@store/modules/routes/types'
import { IStageAssigneeOption } from '@common/Stages/components/Stage/types'

export interface IApprovalSchemeState {
  scheme: Nullable<IScheme>
}

export interface IScheme {
  routeOguid: string
  stages: IApprovalSchemeStage[]
  ownerManagerOfSubOrg: Nullable<IAssignedToSubOrg>
  ownerUser: Nullable<IAssignedToUser>
  ownerGroup: Nullable<IGroup>
  isReadonly?: boolean
}

export interface IApprovalSchemeStage {
  userName: Nullable<string>
  type: string
  constraints: Nullable<Array<string | IConstraintToSet>>
  hint?: Nullable<string>
  hoursToProcess?: Nullable<number>
  isMilestone?: boolean
  assignees: IApprovalSchemeAssignee[]
}

export interface IApprovalSchemeTask {
  assignedToCalculatedUser?: Nullable<ILibPresetValue>
  assignedToGroup: Nullable<IGroup>
  assignedToUser: Nullable<IAssignedToUser>
  assignMode: Nullable<AssignMode>
  type?: string
}

export interface IApprovalSchemeAssignee {
  assignedToCalculatedUser?: Nullable<ILibPresetValue>
  assignedToManagerOfSubOrg?: Nullable<IAssignedToSubOrg>
  assignedToGroup: Nullable<IGroup>
  assignedToUser: Nullable<IAssignedToUser>
  assignMode: Nullable<AssignMode>
  type?: string
  startConditions?: ICondition[]
  hoursToAutoAction?: Nullable<number>
  autoReassignToUser?: Nullable<IAssignedToUser>
  autoReassignToGroup?: Nullable<IStageAssigneeOption>
  autoReassignToManagerOfSubOrg?: Nullable<IStageAssigneeOption>
  autoReassignToOwner?: Nullable<boolean>
}

export interface IApprovedScheme {
  stages?: IApprovedStage[]
}

export interface IApprovedStage {
  userName?: Nullable<string>
  type: string
  hint?: Nullable<string>
  hoursToProcess?: Nullable<number>
  constraints?: Nullable<Array<string | IConstraintToSet>>
  assignees: IApprovedAssignee[]
  isMilestone?: boolean
}

export interface IApprovedTask {
  assignedToGroupOguid: Nullable<string>
  assignedToUserOguid: Nullable<string>
  assignMode: Nullable<AssignMode>
}

export interface IApprovedAssignee {
  assignedToGroupOguid?: Nullable<string>
  assignedToUserOguid?: Nullable<string>
  assignedToManagerOfSubOrgOguid?: Nullable<string>
  assignMode: Nullable<AssignMode>
  startConditions?: ICondition[]
  hoursToAutoAction?: Nullable<number>
  autoReassignToUserOguid?: Nullable<string>
  autoReassignToGroupOguid?: Nullable<string>
  autoReassignToOwner?: Nullable<boolean>
  autoReassignToManagerOfSubOrgOguid?: Nullable<string>
}

export enum ApprovalSchemeActionTypes {
  SET_APPROVAL_ROUTE = 'SET_APPROVAL_ROUTE',
  RESET_APPROVAL_SCHEME = 'RESET_APPROVAL_SCHEME'
}

export interface IActionSetApprovalRoute extends Action<string> {
  payload: IScheme
  type: ApprovalSchemeActionTypes.SET_APPROVAL_ROUTE
}

export interface IActionResetApprovalScheme extends Action<string> {
  type: ApprovalSchemeActionTypes.RESET_APPROVAL_SCHEME
}

export type Actions = IActionSetApprovalRoute | IActionResetApprovalScheme

type ActionKeys = 'setApprovalRoute'

export type ApprovalSchemeActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type ApprovalSchemeThunkAction = ThunkAction<
  Promise<AxiosResponse> | Promise<void>,
  IApprovalSchemeState,
  void,
  Action<string>
>

export type ApprovalSchemeThunkDispatch = ThunkDispatch<IApprovalSchemeState, void, Action<string>>
