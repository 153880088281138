import { applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'

import approvalSchemeReducer from '@store/modules/approvalScheme/reducer'
import constraintsReducer from './modules/constraints/reducer'
import contractorsReducer from './modules/contractors/reducer'
import divisionsReducer from './modules/divisions/reducer'
import documentReducer from './modules/document/reducer'
import documentsReducer from './modules/documents/reducer'
import documentsOperationReducer from '@store/modules/documentsOperation/reducer'
import groupReducer from './modules/group/reducer'
import groupsReducer from './modules/groups/reducer'
import handbooksReducer from './modules/handbooks/reducer'
import loaderReducer from './modules/loader/reducer'
import messagesReducer from './modules/messages/reducer'
import metadataReducer from './modules/metadata/reducer'
import modalReducer from './modules/modal/reducer'
import navigationReducer from './modules/navigation/reducer'
import noticesReducer from './modules/notices/reducer'
import paginationReducer from './modules/pagination/reducer'
import receiptsReducer from '@store/modules/receipts/reducer'
import routesReducer from './modules/routes/reducer'
import startupReducer from './modules/startup/reducer'
import tasksReducer from './modules/tasks/reducer'
import userReducer from './modules/user/reducer'
import userFiltersReducer from './modules/userFilters/reducer'
import usersReducer from './modules/users/reducer'
import utilsReducer from './modules/utils/reducer'

import { rootReducer } from '@store/modules/root/reducer'

export const appReducer = combineReducers({
  app: startupReducer,
  approvalScheme: approvalSchemeReducer,
  constraints: constraintsReducer,
  contractors: contractorsReducer,
  divisions: divisionsReducer,
  document: documentReducer,
  documents: documentsReducer,
  documentsOperation: documentsOperationReducer,
  group: groupReducer,
  groups: groupsReducer,
  handbooks: handbooksReducer,
  loader: loaderReducer,
  messages: messagesReducer,
  metadata: metadataReducer,
  modal: modalReducer,
  navigation: navigationReducer,
  notices: noticesReducer,
  pagination: paginationReducer,
  receipts: receiptsReducer,
  routes: routesReducer,
  tasks: tasksReducer,
  user: userReducer,
  userFilters: userFiltersReducer,
  users: usersReducer,
  utils: utilsReducer
})

const composeEnhancer = composeWithDevTools({ trace: true })

const store = createStore(rootReducer, composeEnhancer(applyMiddleware(thunk)))

export default store
