import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import { getPagination } from '@utils/utils'

import { ResponseCode } from '@const/consts'
import routesService from '@services/routes'

import { IParams, SyncThunk } from '@store/types/commonTypes'
import { setPagination } from '@store/modules/pagination/actions'

import {
  IActionDeleteRoute,
  IActionResetRoutes,
  IActionSetRoutes,
  IGetAssignee,
  IRoute,
  RoutesActions,
  RoutesActionTypes,
  RoutesThunkAction,
  RoutesThunkDispatch
} from './types'

// actions
const deleteRouteAction: ActionCreator<Action> = (routeOguid: string): IActionDeleteRoute => ({
  payload: routeOguid,
  type: RoutesActionTypes.DELETE_ROUTE
})

export const resetRoutes: IActionResetRoutes = {
  type: RoutesActionTypes.RESET_ROUTES
}

const setRoutesAction: ActionCreator<Action> = (data: Array<IRoute<IGetAssignee>>): IActionSetRoutes => ({
  payload: data,
  type: RoutesActionTypes.SET_ROUTES
})

export const actions: RoutesActions = {
  deleteRoute: deleteRouteAction,
  setRoutes: setRoutesAction
}

// thunks

export const getRoutes: ActionCreator<RoutesThunkAction> = (params?: IParams) => (
  dispatch: RoutesThunkDispatch
): Promise<AxiosResponse<Array<IRoute<IGetAssignee>>>> =>
  routesService
    .getRoutes({ params })
    .then((resp) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(setRoutesAction(resp.data ?? []))

        const pagination = getPagination(resp.headers)
        pagination && dispatch(setPagination(pagination))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const deleteRoute: ActionCreator<RoutesThunkAction> = (routeOguid: string) => (
  dispatch: RoutesThunkDispatch
): Promise<AxiosResponse> =>
  routesService
    .deleteRoute(routeOguid)
    .then((resp) => {
      if (resp.status === ResponseCode.DEL) {
        dispatch(deleteRouteAction(routeOguid))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const clearRoutes: SyncThunk = () => (dispatch: RoutesThunkDispatch): void => {
  dispatch(setRoutesAction([]))
}
