import { AxiosRequestConfig, AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IAttachmentBody } from '@app/store/modules/documents/types'
import { ICorrectUPD, IHash, IPatchMessage, ISignAttach, ISignFile } from '@services/attachments/types'
import queryString from 'query-string'
import { IFileFieldValue } from '@store/types/commonTypes'
import { FileFieldAction } from '@common/Field/types'
import { IFileFieldVersion } from '@common/Field/modals/FileFieldVersions/types'

class AttachmentsService extends AbstractHttpService {
  private readonly url = urls.attachments

  async generateTemplateFile(typeKey: string, data: any): Promise<AxiosResponse> {
    const url = this.url.templateFile.replace('{{ typeKey }}', typeKey)

    return await this._http.post(url, data, {
      params: {
        isNeedResetError: true
      }
    })
  }

  async getDocumentFile(attachmentOguid: string, orgOguid?: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.documentFile.replace('{{ oguid }}', attachmentOguid)

    return await this._http.get(url, {
      responseType: 'blob'
    }, !orgOguid, orgOguid)
  }

  async getDocumentFiles(documentOguids: string[], isTicketsIncluded = false): Promise<AxiosResponse<Blob>> {
    const url = this.url.documentsFiles

    return await this._http.get(url, {
      params: {
        documentOguids,
        isNeedResetError: true,
        isTicketsIncluded
      },
      paramsSerializer: { serialize: (params) => queryString.stringify(params) },
      responseType: 'blob'
    })
  }

  async getAttachments(documentOguid: string): Promise<AxiosResponse> {
    const url = this.url.get.replace('{{ oguid }}', documentOguid)

    return await this._http.get(url)
  }

  async postAttachment(documentOguid: string, data: IAttachmentBody): Promise<AxiosResponse> {
    const url = this.url.add.replace('{{ oguid }}', documentOguid)

    return await this._http.post(url, data)
  }

  async uploadToShelf(data: FormData, orgOguid?: string): Promise<AxiosResponse> {
    const url = this.url.shelf

    return await this._http.post(url, data, {}, !orgOguid, orgOguid)
  }

  async getTicketReceipt(attachmentOguid: string, signer: any): Promise<AxiosResponse> {
    const url = this.url.getTicketReceipt.replace('{{ attachmentOguid }}', attachmentOguid)

    return await this._http.post(url, signer)
  }

  // `${BASE_URL}orgs/{orgOguid}/attachments/hash`
  async getHashFile(params: IHash, orgOguid?: string): Promise<AxiosResponse> {
    const url = this.url.hash
    const { attachmentOguid = null, standard, uploadedFileOguid = null } = params
    const body = {
      attachmentOguid,
      standard,
      uploadedFileOguid
    }

    return await this._http.post(url, body, {}, !orgOguid, orgOguid)
  }

  // `${BASE_URL}orgs/{orgOguid}/shelfSignatureBase64`
  async sendSign(body: ISignFile, orgOguid?: string): Promise<AxiosResponse> {
    const url = this.url.sendSign

    return await this._http.post(url, body, {}, !orgOguid, orgOguid)
  }

  // `${BASE_URL}orgs/{orgOguid}/messagesPatch`
  async patchMessage(body: [IPatchMessage]): Promise<AxiosResponse<string[]>> {
    const url = this.url.patchMessage

    return await this._http.post(url, body)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/attachments/signatures`
  async attachSignature(documentOguid: string, body: ISignAttach, orgOguid?: string): Promise<AxiosResponse> {
    const url = this.url.attachSignature.replace('{{ documentOguid }}', documentOguid)

    return await this._http.post(url, body, {}, !orgOguid, orgOguid)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/correctionRequest`
  async correctionRequest(body: ICorrectUPD): Promise<AxiosResponse<string>> {
    const url = this.url.correctionRequest

    return await this._http.post(url, body)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}/index/{index}/checkOut/{action}`
  async checkoutFileFieldFile(documentOguid: string, fieldKey: string, index: number, action: FileFieldAction): Promise<AxiosResponse>{
    const url = this.url.fileFieldActions.array
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)
      .replace('{{ index }}', `${index}`)
      .replace('{{ action }}', action)

    return await this._http.post(url, null)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}/checkOut/{action}`
  async checkoutSingleFileFieldFile(documentOguid: string, fieldKey: string, action: FileFieldAction): Promise<AxiosResponse>{
    const url = this.url.fileFieldActions.single
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)
      .replace('{{ action }}', action)

    return await this._http.post(url, null)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}/index/{index}/history`
  async getFileFieldFileHistory(documentOguid: string, fieldKey: string, index: number): Promise<AxiosResponse<IFileFieldVersion[]>>{
    const url = this.url.fileFieldHistory.array
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)
      .replace('{{ index }}', `${index}`)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}/history`
  async getSingleFileFieldFileHistory(documentOguid: string, fieldKey: string): Promise<AxiosResponse<IFileFieldVersion[]>>{
    const url = this.url.fileFieldHistory.single
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}/index/{index}/file`
  async getFileFieldFile(documentOguid: string, fieldKey: string, index: number, versionNumber?: number): Promise<AxiosResponse>{
    const url = this.url.fileFieldGet.array
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)
      .replace('{{ index }}', `${index}`)

    const config: AxiosRequestConfig = {
      responseType: 'blob'
    }

    versionNumber && (
      config.params = {
        versionNumber
      }
    )

    return await this._http.get(url, config)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}/file`
  async getSingleFileFieldFile(documentOguid: string, fieldKey: string, versionNumber?: number): Promise<AxiosResponse>{
    const url = this.url.fileFieldGet.single
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)

    const config: AxiosRequestConfig = {
      responseType: 'blob'
    }

    versionNumber && (
      config.params = {
        versionNumber
      }
    )

    return await this._http.get(url, config)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}`
  async postFileFieldFile(documentOguid: string, fieldKey: string, fileOguid: string): Promise<AxiosResponse<IFileFieldValue>>{
    const url = this.url.fileField
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)

    const body = {
      oguid: fileOguid
    }

    return await this._http.post(url, body)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}/index/{index}`
  async removeFileFieldFile(documentOguid: string, fieldKey: string, index: number): Promise<AxiosResponse>{
    const url = this.url.fileFieldByIndex
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)
      .replace('{{ index }}', `${index}`)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}`
  async removeSingleFileFieldFile(documentOguid: string, fieldKey: string): Promise<AxiosResponse>{
    const url = this.url.fileField
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}/index/{index}`
  async replaceFileFieldFile(documentOguid: string, fieldKey: string, index: number, fileOguid: string): Promise<AxiosResponse<IFileFieldValue>>{
    const url = this.url.fileFieldByIndex
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)
      .replace('{{ index }}', `${index}`)

    const body = {
      oguid: fileOguid
    }

    return await this._http.put(url, body)
  }

  // `${BASE_URL}orgs/{orgOguid}/documents/{documentOguid}/fileField/{fieldKey}`
  async replaceSingleFileFieldFile(documentOguid: string, fieldKey: string, fileOguid: string): Promise<AxiosResponse<IFileFieldValue>>{
    const url = this.url.fileField
      .replace('{{ oguid }}', documentOguid)
      .replace('{{ fieldKey }}', fieldKey)

    const body = {
      oguid: fileOguid
    }

    return await this._http.put(url, body)
  }
}

export default new AttachmentsService()
