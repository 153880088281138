import { Actions, IDocumentsOperationState, DocumentOperationActionTypes } from './types'

export const initialState: IDocumentsOperationState = {}

export default function documentsOperationReducer(
  state = { ...initialState },
  action: Actions
): IDocumentsOperationState {
  switch (action.type) {
    case DocumentOperationActionTypes.ADD_DOCUMENTS: {
      return { ...action.payload }
    }
    case DocumentOperationActionTypes.UPDATE_DOCUMENT: {
      const { id, document } = action.payload
      return { ...state, [id]: { ...document } }
    }
    case DocumentOperationActionTypes.RESET_DOCUMENTS_OPERATIONS: {
      return { ...initialState }
    }
    default:
      return state
  }
}
