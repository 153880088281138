import cn from 'classnames'
import React, { FC } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { BrowserStorage, RouteName, SSO_URL } from '@const/consts'

import { Button, Content } from '@infologistics/frontend-libraries'

import { AllPageErrorOrgsProps as AllProps } from './types'

import styles from './Page404.500.module.css'
import urls from '@app/const/urls'
import tokensService from '@app/services/tokensService'
import { useSelector } from 'react-redux'
import { IApplicationState } from '@app/store/types/commonTypes'
import { useDispatch } from 'react-redux'
import { logout } from '@app/store/modules/root/actions'
import { actions as loaderActions } from '@store/modules/loader/actions'

const PageErrorOrgs: FC<AllProps> = (props: AllProps) => {
  const { t, history } = props

  const { profile, federation } = useSelector((state: IApplicationState) => state.user)

  const federationToken = tokensService.getTokenFromCookies(BrowserStorage.TOKEN_FEDERATION, profile.oguid)

  const ssoUrl = `${SSO_URL}${urls.users.SSOLogout}`
  const ssoUser = !!federation || (federationToken && federationToken!=='')

  const dispatch = useDispatch<any>()

  const handleClick = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const url = RouteName.SIGN_IN

    dispatch(logout())

    history.push(url)

    if (ssoUser) {
      dispatch(loaderActions.showLoader)
      e.currentTarget.submit();
    }
  }

  return (
    <Content classes='d-flex justify-content-center align-items-center'>
      <div className='d-flex align-items-center'>
        <div className={styles.content}>
          <p className={cn('fw-700', 'text-brand', 'mb-4', styles.error_code)}>{t('common:error')}</p>
          <p className='fw-600 mb-2'>{t('error:orgs.title')}</p>
          <p className='text-muted font-sm mb-6'>{t('error:orgs.text')}</p>

          <form
            action={ssoUser ? ssoUrl : undefined}
            method={ssoUser ? 'post' : undefined}
            onSubmit={e => handleClick(e)}
          >
            <Button
              classes='d-flex align-items-center text-nowrap p-2'
              theme='primary'
              type='submit'
              leftIcon='IconSignOut'
            >
              {t('common:signout')}
            </Button>
          </form>
        </div>
        <img src='/assets/img/error.svg' width='493' height='392' alt='' />
      </div>
    </Content>
  )
}

const PageErrorOrgsClass = connect(null, null)(PageErrorOrgs)

export default withTranslation('error')(PageErrorOrgsClass)
