import React, { FC, ReactElement } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'

import { Modal } from '@infologistics/frontend-libraries'

import { closeModal } from '@store/modules/modal/actions'

import { IApplicationState } from '@store/types/commonTypes'
import { IActionCloseModal } from '@store/modules/modal/types'
import {
  AllModalProps as IProps,
  IModalDispatchFromProps as IPropsFromDispatch,
  IModalStateFromProps as IPropsFromState
} from '@common/ModalWrapper/types'

const CustomModal: FC<IProps> = (props): ReactElement => {
  const { children, closeModal, disableHideFn, onCloseModal } = props

  const handleModalHide = disableHideFn
    ? () => null
    : closeModal

  return (
    <Modal {...props} hideModal={handleModalHide} onCloseModal={onCloseModal}>
      {children}
    </Modal>
  )
}

const mapDispatchToProps = (dispatch: Dispatch<IActionCloseModal>): IPropsFromDispatch => ({
  closeModal: () => dispatch(closeModal)
})

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  idFromState: state.modal.id
})

const ModalWrapper = connect(mapStateToProps, mapDispatchToProps)(CustomModal)

export default ModalWrapper
