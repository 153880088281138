import { Actions, IApprovalSchemeState, ApprovalSchemeActionTypes } from './types'

export const initialState: IApprovalSchemeState = {
  scheme: null
}

export default function approvalSchemeReducer(
  state: IApprovalSchemeState = initialState,
  action: Actions
): IApprovalSchemeState {
  switch (action.type) {
    case ApprovalSchemeActionTypes.SET_APPROVAL_ROUTE:
      return {
        ...state,
        scheme: action.payload
      }
    case ApprovalSchemeActionTypes.RESET_APPROVAL_SCHEME:
      return initialState
    default:
      return state
  }
}
