import { AxiosResponse, AxiosRequestConfig } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IFields } from '@store/modules/divisions/types'
import { IParams } from '@app/store/types/commonTypes'
import { ILibDivision as IDivision } from '@infologistics/frontend-libraries'

class DivisionsService extends AbstractHttpService {
  private readonly url = urls.divisions

  async getDivisions(config: AxiosRequestConfig): Promise<AxiosResponse<IDivision[]>> {
    const url = this.url.list

    return await this._http.get(url, config)
  }

  async getDivision(oguid: string, params?: IParams): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ oguid }}', oguid)

    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  async addDivision(data: IFields): Promise<AxiosResponse<IDivision>> {
    const url = this.url.list

    return await this._http.post(url, data)
  }

  async updateDivision(oguid: string, data: IFields): Promise<AxiosResponse<IDivision>> {
    const url = this.url.single.replace('{{ oguid }}', oguid)

    return await this._http.put(url, data)
  }

  async removeDivision(oguid: string): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ oguid }}', oguid)

    return await this._http.delete(url)
  }
}

export default new DivisionsService()
