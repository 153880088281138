import cn from 'classnames'
import React, { FC } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import { Section } from '@const/consts'

import { Button, Content } from '@infologistics/frontend-libraries'

import { resetError500 } from '@store/modules/utils/actions'
import { AllPage500Props as AllProps, IPage500PropsFromDispatch as IPropsFromDispatch } from './types'
import { OrganizationLink } from '@containers/Navigation'

import styles from './Page404.500.module.css'

const Page500: FC<AllProps> = (props: AllProps) => {
  const { onResetError500, t } = props

  return (
    <Content classes='d-flex justify-content-center align-items-center'>
      <div className='d-flex align-items-center'>
        <div className={styles.content}>
          <p className={cn('fw-700', 'text-brand', 'mb-4', styles.error_code)}>500</p>
          <p className='fw-600 mb-2'>{t('error:500.title')}</p>
          <p className='text-muted font-sm mb-6'>{t('error:500.text')}</p>
          <Button theme='primary' onClick={onResetError500}>
            <OrganizationLink to={Section.DOCUMENTS_ALL} className={styles.link}>
              {t('error:main')}
            </OrganizationLink>
          </Button>
        </div>
        <img src='/assets/img/error.svg' width='493' height='392' alt='' />
      </div>
    </Content>
  )
}

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onResetError500: () => dispatch(resetError500)
})

const Page500Class = connect(null, mapDispatchToProps)(Page500)

export default withTranslation('error')(Page500Class)
