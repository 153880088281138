import cn from 'classnames'
import { debounce } from 'lodash'
import React, { FC, ReactNode, useEffect, useRef, useLayoutEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { Spinner } from '@infologistics/frontend-libraries'
import Message from '@common/Messages/Message/Message'

import { displayErrorNotification } from '@utils/utils'
import { setMessagesAction } from '@store/modules/messages/actions'

import { PER_PAGE_ITEMS_DEFAULT, SCROLL_CHECK_BOTTOM_DELAY, ShowMode } from '@const/consts'

import { IMessagesTabProps as IProps } from '@containers/Messages/MessagesTab/types'

import styles from './MessagesTab.module.css'

const messagesLimit = 100

const MessagesTab: FC<IProps> = (props) => {
  const { t } = useTranslation(['tasks'])

  const {
    isLoading,
    isCompleted,
    messages,
    page,
    onSetMessagesPage,
    onGetMessages,
    onToggleLoader,
    onGetBadges
  } = props

  const dispatch = useDispatch()

  const refContainer = useRef<HTMLDivElement>(null)
  const isLoadingMore = page && page > 1 && page <= messagesLimit / PER_PAGE_ITEMS_DEFAULT

  const getMessages = (pageParam: number, isPushMessages = false): void => {
    const params = {
      page: pageParam,
      perPage: PER_PAGE_ITEMS_DEFAULT,
      showMode: ShowMode.TODOS_ONLY,
      isCompleted
    }

    onToggleLoader(true)

    Promise.all([onGetMessages(params, isPushMessages), onGetBadges()])
      .finally(() => onToggleLoader(false))
      .catch(displayErrorNotification)
  }

  const renderLoader = (): ReactNode => (
    <div className={cn(styles.loader, 'absolute')}>
      <Spinner />
    </div>
  )

  const renderTab = (): ReactNode => (
    messages.length
      ? <>
          { (isLoading && isLoadingMore) && renderLoader() }
          {
            messages.map(message =>
              <Message
                key={message.task.oguid}
                message={message}
                isCompleted={isCompleted}
              />)
          }
          {page && page >= (messagesLimit / PER_PAGE_ITEMS_DEFAULT) && !isLoading && (
            <p className='mt-1 text-center text-danger font-xs'>
              {t('tasks:messagesLimit')}
            </p>
          )}
        </>
      : <p className='p-4 font-default'>{t('tasks:noMessages')}</p>
  )

  useLayoutEffect(() => {
    onSetMessagesPage(1)
    dispatch(setMessagesAction([]))
    getMessages(1)
  }, [isCompleted])

  useEffect(() => {
    if (isLoadingMore) {
      onToggleLoader(true)
      page && getMessages(page, true)
    }
  }, [page])

  const handleScroll = (): void => {
    const clientHeightContainer = refContainer.current?.clientHeight ?? 0
    const scrollHeightContainer = refContainer.current?.scrollHeight ?? 0
    const scrollTopContainer = refContainer.current?.scrollTop ?? 0
    const scrollBottomSpace =
      (scrollHeightContainer - clientHeightContainer) - scrollTopContainer

    if (scrollBottomSpace < 5 && page && !isLoading) {
        onSetMessagesPage(page + 1)
    }
  }

  return (
    <div
      ref={refContainer}
      className={cn(styles.scroll, 'overflow-y scrollbar-sidebar')}
      onScroll={debounce(handleScroll, SCROLL_CHECK_BOTTOM_DELAY)}
    >
      {
        (isLoading && page === 1) ? renderLoader() : renderTab()
      }
    </div>
  )
}

export default MessagesTab
