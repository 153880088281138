import { Action, ActionCreator } from 'redux'

import { IDocument, ISelectedDocuments, ITodoAttachment } from '@store/modules/documents/types'
import { IUserOrganization, ModuleThunkDispatch, Nullable } from '@store/types/commonTypes'

import { ILibUser } from '@infologistics/frontend-libraries'
import { ILegalType } from '@store/modules/user/types';

export interface IMessageTask {
  attachment: Nullable<ITodoAttachment>
  author: ILibUser
  completedTimestamp: Nullable<number>
  description: string
  isRead: boolean
  oguid: string
  startedTimestamp: number
  type: string
  state: string
  result: string
  isDueDateTimeViolation: Nullable<boolean>
}

export interface IMessageOrganization {
  oguid: string
  inn: Nullable<string>
  kpp: Nullable<string>
  ogrn: Nullable<string>
  name: string
  nameShort: string
  nameFio: Nullable<string>
  legalType: Nullable<ILegalType>
  alias: string
}

export interface IMessage {
  title: Nullable<string>
  document: IDocument
  task: IMessageTask
  stage: IStage
  org?: IUserOrganization
}

export interface IStage {
  oguid: string
  userName: string
  type: string
  state: string
}

export interface IResponse {
  isOpen: boolean
  recipient: ILibUser
  text: string
}

export interface IResponses {
  [key: string]: IResponse
}

export interface ITasksState {
  messages: IMessage[]
  responses: IResponses
  selectedDocuments: ISelectedDocuments
}

export interface IMessageBody {
  // type is temporarily ignored, type='todo' is always created
  assignedToGroupOguid: Nullable<string>
  assignedToUserOguid: Nullable<string>
  comment?: Nullable<string>
  description: string
  parentTaskOguid: Nullable<string>
}

// actions

export enum TasksActionTypes {
  RESET_SELECTED_DOCUMENTS = 'RESET_SELECTED_DOCUMENTS',
  RESET_TASKS = 'RESET_TASKS',
  SET_PAGINATION = 'SET_PAGINATION',
  SET_RESPONSES = 'SET_RESPONSES',
  SET_SELECTED_DOCUMENTS = 'SET_SELECTED_DOCUMENTS',
  SET_TASKS_MESSAGES = 'SET_TASKS_MESSAGES'
}

export interface IActionSetSelectedDocuments extends Action<string> {
  payload: ISelectedDocuments
  type: TasksActionTypes.SET_SELECTED_DOCUMENTS
}

export interface IActionSetMessages extends Action<string> {
  payload: IMessage[]
  type: TasksActionTypes.SET_TASKS_MESSAGES
}

export interface IActionResetSelectedDocuments extends Action<string> {
  type: TasksActionTypes.RESET_SELECTED_DOCUMENTS
}

export interface IActionResetTasks extends Action<string> {
  type: TasksActionTypes.RESET_TASKS
}

export interface IActionSetResponses extends Action<string> {
  payload: IResponses
  type: TasksActionTypes.SET_RESPONSES
}

export type Actions =
  | IActionSetSelectedDocuments
  | IActionSetMessages
  | IActionSetResponses
  | IActionResetSelectedDocuments
  | IActionResetTasks

type ActionKeys = 'setSelectedDocuments' | 'setMessages' | 'setResponses'

export type CompleteStatus = 'SOLVED' | 'DECLINED'

export interface IApproveContent {
  comment: string
  result: CompleteStatus
}

export type TasksActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type TasksThunkDispatch = ModuleThunkDispatch<ITasksState>
