import cn from 'classnames'
import React, { FC, MouseEvent, ReactNode, useState } from 'react'
import { withTranslation } from 'react-i18next'

import { Button, ILibHorizontalPosition, Popover } from '@infologistics/frontend-libraries'
import { OrganizationLink } from '@containers/Navigation'

import { Section } from '@const/consts'

import { CreateDocumentLinksProps as IProps } from './types'
import { Documents } from '@const/translations'
import { getIsInterfaceDisabled } from '@utils/utils';

import styles from './CreateDocumentLinks.module.css'

const CreateDocumentLinks: FC<IProps> = (props) => {
  const { t, isHorizontalNavMode, isRootMode } = props
  const [openPopover, setOpenPopover] = useState(false)

  const togglePopover = (): void => setOpenPopover(!openPopover)
  const closePopover = (): void => setOpenPopover(false)

  const toggleClose = (isOpenFromPopover: boolean): void => {
    if (!isOpenFromPopover) {
      setOpenPopover(false)
    }
  }

  const handleLinkClick = (evt: MouseEvent): void => {
    const { pathname } = evt.target as HTMLAnchorElement

    if (window.location.pathname === pathname) {
      evt.preventDefault()
    }

    closePopover()
  }

  const renderLinks = (): ReactNode => {
    const { isExchangeParticipant } = props

    return (
      <>
        {getLink('new', Section.DOCUMENTS_ADD)}
        {isExchangeParticipant && getLink('sendDocument', Section.MESSAGES_ADD)}
      </>
    )
  }

  const getLink = (key: string, path: string): ReactNode => (
    <OrganizationLink to={path} onClick={handleLinkClick} className={styles.item}>
      {t(Documents[key])}
    </OrganizationLink>
  )

  const isDisabled = getIsInterfaceDisabled() || isRootMode

  return (
    <div className='d-flex full-width'>
      <Popover
        buttonClasses={cn(styles.button, isHorizontalNavMode ? styles.button_horizontal : null)}
        openButtonClasses={cn(styles.button_open, isHorizontalNavMode ? styles.button_horizontal : null)}
        horizontalPosition={isHorizontalNavMode ? ILibHorizontalPosition.RIGHT : ILibHorizontalPosition.LEFT}
        buttonComponent={
          <Button theme='warning' classes='fw-700' onClick={togglePopover} isDisabled={isDisabled}>
            {t('documents:create')}
          </Button>
        }
        popoverClasses={cn(
          isHorizontalNavMode ? cn(styles.popover, styles.popover_horizontal) : styles.popover,
          `${isHorizontalNavMode ? 'my-1 mx-3' : 'my-2 mx-auto'}`
        )}
        containerClasses={styles.container}
        withoutArrow
        parentManagement={{
          isOpenFromParent: openPopover,
          callbackForClose: toggleClose
        }}
      >
        {renderLinks()}
      </Popover>
    </div>
  )
}

export default withTranslation()(CreateDocumentLinks)
