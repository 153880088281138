import Loadable from 'react-loadable'

import { SpinnerWrapper } from '@containers/Navigation'
import { AllRoles, Path } from '@const/consts'
import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'

const Profile = Loadable({
  loader: () => import('@views/user/Profile'),
  loading: SpinnerWrapper
})

export const routes: IRoute[] = [
  {
    path: Path.PROFILE,
    exact: true,
    name: 'Profile',
    component: Profile,
    roles: AllRoles,
    useRootMode: true
  }
]
