import { Action, ActionCreator } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { AxiosResponse } from 'axios'

import { ModuleThunkDispatch, Nullable } from '@store/types/commonTypes'
import { INotice } from '@common/Notices/Notice/types'

export interface INoticesState {
  notices: INotice[]
  isLoading: boolean
  isLoadingFromActionButtons: boolean
  page: Nullable<number>
}

// actions

export enum NoticesActionTypes {
  SET_NOTICES = 'SET_NOTICES',
  SET_NOTICES_PAGE = 'SET_NOTICES_PAGE',
  TOGGLE_NOTICES_LOADER = 'TOGGLE_NOTICES_LOADER',
  SET_LOADED_FROM_ACTION_BUTTONS = 'SET_LOADED_FROM_ACTION_BUTTONS'
}

export interface IActionSetNotices extends Action<string> {
  payload: INotice[]
  type: NoticesActionTypes.SET_NOTICES
}

export interface IActionSetNoticesPage extends Action<string> {
  payload: Nullable<number>
  type: NoticesActionTypes.SET_NOTICES_PAGE
}

export interface ILoadedFromActionButtons extends Action<string> {
  payload: boolean
  type:NoticesActionTypes.SET_LOADED_FROM_ACTION_BUTTONS
}

export interface IActionToggleLoader extends Action<string> {
  payload: boolean
  type:NoticesActionTypes.TOGGLE_NOTICES_LOADER
}

export type Actions = IActionSetNotices | IActionToggleLoader | IActionSetNoticesPage | ILoadedFromActionButtons

type ActionKeys = 'setNotices' | 'toggleLoader' | 'setNoticesPage' | 'setLoadedFromActiveButtons'

export type NoticesActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type NoticesThunkAction = ThunkAction<Promise<AxiosResponse> | Promise<void>, INoticesState, void, Action<string>>

export type NoticesThunkDispatch = ModuleThunkDispatch<INoticesState>
