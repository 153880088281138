import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { IApplicationState, Nullable } from '@store/types/commonTypes'
import { ILibDivision as IDivision } from '@infologistics/frontend-libraries'

export enum DivisionsActionTypes {
  GET_DIVISIONS = 'GET_DIVISIONS',
  ADD_DIVISION = 'ADD_DIVISION',
  REMOVE_DIVISION = 'REMOVE_DIVISION',
  RESET_DIVISIONS = 'RESET_DIVISIONS',
  UPDATE_DIVISION = 'UPDATE_DIVISION'
}


export interface IFields {
  name: string
  parentOguid: string
  managerOguid: Nullable<string>
}

export interface IActionGetDivisions extends Action<string> {
  payload: IDivision[]
  type: DivisionsActionTypes.GET_DIVISIONS
}

export interface IActionAddDivision extends Action<string> {
  payload: IDivision
  type: DivisionsActionTypes.ADD_DIVISION
}

export interface IActionUpdateDivision extends Action<string> {
  payload: IDivision
  type: DivisionsActionTypes.UPDATE_DIVISION
}

export interface IActionRemoveDivision extends Action<string> {
  payload: string
  type: DivisionsActionTypes.REMOVE_DIVISION
}

export interface IActionResetDivisions extends Action<string> {
  type: DivisionsActionTypes.RESET_DIVISIONS
}

export type Actions =
  | IActionGetDivisions
  | IActionAddDivision
  | IActionUpdateDivision
  | IActionRemoveDivision
  | IActionResetDivisions

type ActionKeys = 'addDivision' | 'getDivisions' | 'removeDivision' | 'updateDivision'

export type DivisionsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type DivisionsThunkAction = ThunkAction<Promise<AxiosResponse>, IDivision[], void, Action<string>>

export type DivisionsThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>
