import { Action, ActionCreator } from 'redux'

export interface IDocumentState {
  mode: DocumentMode
}

export enum DocumentActionTypes {
  SET_MODE = 'SET_MODE'
}

export interface IActionSetMode extends Action<string> {
  payload: DocumentMode
  type: DocumentActionTypes.SET_MODE
}

export type Actions = IActionSetMode

type ActionKeys = 'setMode'

export enum DocumentMode {
  COPY = 'copy',
  EDIT = 'edit',
  NEW_VERSION = 'newVersion',
  VIEW = 'view'
}

export type DocumentActions = { [key in ActionKeys]: ActionCreator<Action<string>> }
