import Loadable from 'react-loadable'
import { SpinnerWrapper } from '@containers/Navigation'
import { Path, Role } from '@const/consts'
import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'

const Contractors = Loadable({
  loader: () => import('@views/organization/contractors/components/Contractors'),
  loading: SpinnerWrapper
})

const ContractorAdd = Loadable({
  loader: () => import('@views/organization/contractors/components/Contractor/ContractorAdd'),
  loading: SpinnerWrapper
})

const ContractorEdit = Loadable({
  loader: () => import('@views/organization/contractors/components/Contractor/ContractorEdit'),
  loading: SpinnerWrapper
})

export const routes: IRoute[] = [
  {
    path: Path.CONTRACTORS_ALL,
    exact: true,
    name: 'Contractors',
    component: Contractors,
    roles: [Role.ADMINISTRATOR, Role.ORGANIZATION]
  },
  {
    path: Path.CONTRACTORS_ADD,
    exact: true,
    name: 'ContractorsAdd',
    component: ContractorAdd,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.CONTRACTORS_EDIT,
    exact: true,
    name: 'ContractorId',
    component: ContractorEdit,
    roles: [Role.ADMINISTRATOR]
  }
]
