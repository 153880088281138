import { Action, ActionCreator } from 'redux'

import {
  IActionAddDocumentsOperation,
  IActionResetDocumentsOperation,
  IActionUpdateDocumentOperation,
  IUpdatedDocumentOperation,
  IDocumentsOperation,
  DocumentOperationActionTypes
} from './types'

export const addDocumentsOperation: ActionCreator<Action> = (
  documents: IDocumentsOperation
): IActionAddDocumentsOperation => ({
  payload: documents,
  type: DocumentOperationActionTypes.ADD_DOCUMENTS
})

export const updateDocumentOperation: ActionCreator<Action> = ({
  id,
  document
}: IUpdatedDocumentOperation): IActionUpdateDocumentOperation => ({
  payload: {
    id,
    document
  },
  type: DocumentOperationActionTypes.UPDATE_DOCUMENT
})

export const resetDocumentsOperation: IActionResetDocumentsOperation = {
  type: DocumentOperationActionTypes.RESET_DOCUMENTS_OPERATIONS
}
