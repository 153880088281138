import { Action, ActionCreator } from 'redux'

import { IActionResetStartup, IActionSetLoaded, StartupActionTypes } from './types'

export const resetStartup: IActionResetStartup = {
  type: StartupActionTypes.RESET_STARTUP
}

export const setLoaded: ActionCreator<Action> = (isLoaded?: boolean): IActionSetLoaded => ({
  payload: isLoaded ?? true,
  type: StartupActionTypes.SET_LOADED
})
