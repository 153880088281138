import { isEqual } from 'lodash'
import React, { ChangeEvent, Component, ReactElement, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'

import {
  Button,
  Checkbox,
  FormLabel,
  getCheckboxCheckedValue,
  Input,
  RadioButton
} from '@infologistics/frontend-libraries'
import SuggestContractorFromAll from '@common/Suggest/SuggestContractorFromAll'

import { closeModal } from '@store/modules/modal/actions'

import { fillAlias, getErrorText, getOgrnTitle, hasError } from '@utils/utils'

import { Instance, NewOrganizationMode, RegisterField } from '@const/consts'
import { foreignModeFields, radioButtons, simpleModeFields, suggestModeFields } from './consts'
import { NewOrganizationLabel } from '@const/translations'

import { IContractor } from '@common/Suggest/SuggestContractorFromAll/types'
import { IApplicationState } from '@store/types/commonTypes'
import {
  INewOrganizationModalFormProps as IProps,
  INewOrganizationModalFormPropsFromState as IPropsFromState,
  INewOrganizationModalFormPropsFromDispatch as IPropsFromDispatch,
  IModeValue,
  IRadioField,
  ITextField
} from './types'

import styles from './NewOrganizationModalForm.module.css'

class NewOrganizationModalForm extends Component<IProps> {
  public componentDidUpdate(prevProps: IProps): void {
    const {
      initialValues: { alias, contractor, name, nameShort, ogrn },
      mode,
      setFieldValue,
      setValues,
      onModeChange,
      values,
      instance
    } = this.props

    const { contractor: currentContractor } = values

    if (instance !== Instance.RUSSIAN && prevProps.instance !== instance) {
      onModeChange(NewOrganizationMode.FOREIGN)
      setFieldValue(RegisterField.IS_FOREIGN, true)
    }

    if (mode !== prevProps.mode) {
      setValues({
        ...values,
        alias,
        contractor,
        name,
        nameShort,
        ogrn
      })
    }

    if (mode === NewOrganizationMode.SUGGEST && !isEqual(currentContractor, prevProps.values.contractor)) {
      fillAlias(setFieldValue, values)
    }
  }

  public render(): ReactElement {
    const { handleSubmit, t, instance, isSaving } = this.props

    const isRussia = instance === Instance.RUSSIAN

    return (
      <form noValidate onSubmit={handleSubmit}>
        <div className='modal-header'>
          <h4 className='m-0'>{t('organizationList:newOrganization')}</h4>
        </div>

        <div className='modal-body p-4'>
          {this.renderRadioButtons()}
          {isRussia && this.renderForeignCheckbox()}
          {this.renderFields()}
          {this.renderContractorInfo()}
        </div>

        <div className='modal-footer text-right'>
          <Button theme='primary' type='submit' loading={isSaving}>
            {t('common:save')}
          </Button>
          <Button externalClass='ml-4' onClick={this.handleCloseModal}>
            {t('common:close')}
          </Button>
        </div>
      </form>
    )
  }

  private readonly handleCloseModal = (): void => {
    const { onChangeIsOpenModal, onCloseModal } = this.props

    onChangeIsOpenModal()
    onCloseModal()
  }

  private readonly renderRadioButton = (radioButton: IRadioField): ReactElement => {
    const { t } = this.props
    const { label, name, value } = radioButton

    return (
      <li className='mt-4' key={label}>
        <FormLabel className='d-flex align-items-start pointer' key={value}>
          <RadioButton
            className='mr-1'
            name={name}
            value={value}
            checked={this.getCheckedState(value)}
            onChange={this.handleRadioChange}
          />
          <span>{t(label)}</span>
        </FormLabel>
      </li>
    )
  }

  private readonly renderRadioButtons = (): ReactElement => {
    const { t } = this.props

    return (
      <>
        <h5 className='mb-0'>{t('organizationList:organizationType')}</h5>
        <ul className='list-unstyled'>{radioButtons.map(this.renderRadioButton)}</ul>
      </>
    )
  }

  private readonly renderForeignCheckbox = (): ReactNode => {
    const {
      t,
      values: { isExchangeParticipant, isForeign }
    } = this.props

    return (
      isExchangeParticipant && (
        <>
          <h5 className='mt-4'>{t('organizationList:organizationLocation')}</h5>
          <FormLabel className='d-flex align-items-start mb-5 pointer'>
            <Checkbox
              className='mr-2'
              checked={getCheckboxCheckedValue(isForeign)}
              name={RegisterField.IS_FOREIGN}
              onChange={this.handleForeignCheckboxChange}
            />
            <span>{t('auth:foreign')}</span>
          </FormLabel>
        </>
      )
    )
  }

  private readonly renderFields = (): ReactElement => {
    const { mode, t } = this.props

    const fields = {
      foreign: foreignModeFields,
      simple: simpleModeFields,
      suggest: suggestModeFields
    }

    return (
      <>
        <h5 className='mt-4'>{t('organizationList:organizationInfo')}</h5>
        <div>{fields[mode].map(this.renderField)}</div>
      </>
    )
  }

  private readonly renderField = (field: ITextField): ReactElement => {
    const { errors, handleChange, setFieldValue, t, touched, values } = this.props
    const { errorTitle, label, name, narrow, required } = field

    const fieldLabel = name === RegisterField.OGRN
      ? `${t(NewOrganizationLabel.ogrnList)} (${t(getOgrnTitle())})`
      : label

    const labelText = `${t(fieldLabel)} ${required ? '*' : ''}`

    const inputClass = narrow ? styles.narrow_field : undefined

    const fieldHasError = hasError(name, errors, touched)
    const errorText = getErrorText(name, errors)
    const errorTitleText = fieldHasError && errorTitle ? t(errorTitle) : ''

    const handleNameShortBlur = name === RegisterField.NAME_SHORT ? () => fillAlias(setFieldValue, values) : undefined

    return (
      <div className='mt-4 relative' key={name}>
        <FormLabel text={labelText} htmlFor={name} className='font-xs' />

        {name === RegisterField.CONTRACTOR ? (
          <SuggestContractorFromAll
            hasError={fieldHasError}
            errorText={t(errorText)}
            id={name}
            onReset={this.handleContractorReset}
            onSelect={this.handleContractorSelect}
            withoutOrg
          />
        ) : (
          <Input
            hasError={fieldHasError}
            errorText={t(errorText)}
            id={name}
            classes={inputClass}
            name={name}
            onBlur={handleNameShortBlur}
            onChange={handleChange}
            title={errorTitleText}
            value={values[name]}
          />
        )}
      </div>
    )
  }

  private readonly renderContractorInfo = (): ReactNode => {
    const {
      t,
      values: { contractor }
    } = this.props

    if (!contractor) return null

    const { addrText, inn, kpp, name, nameShort } = contractor

    const innKpp = inn ?? kpp ? `${t('common:inn')} ${inn ?? ''} / ${t('common:kpp')} ${kpp ?? ''}` : ''

    return (
      <div className='mt-4'>
        <p className='mb-2 fw-600'>{nameShort}</p>
        <p className='mb-2'>{name}</p>
        <p className='mb-2'>{innKpp}</p>
        <p className='m-0'>{addrText}</p>
      </div>
    )
  }

  private readonly getCheckedState = (value: string): boolean => {
    const {
      values: { isExchangeParticipant, isFlowFunctionalityEnabled }
    } = this.props

    const states = {
      [RegisterField.ARCHIHE]: !isFlowFunctionalityEnabled && !isExchangeParticipant,
      [RegisterField.FLOW]: isFlowFunctionalityEnabled && !isExchangeParticipant,
      [RegisterField.EXCHANGE]: !isFlowFunctionalityEnabled && isExchangeParticipant,
      [RegisterField.FLOW_EXCHANGE]: isFlowFunctionalityEnabled && isExchangeParticipant
    }

    return states[value]
  }

  private readonly getChangeModeValue = (value: string): IModeValue => {
    const { values, instance } = this.props

    const isRussia = instance === Instance.RUSSIAN

    const changeModeValues = {
      [RegisterField.ARCHIHE]: {
        mode: NewOrganizationMode.SIMPLE,
        values: {
          ...values,
          isFlowFunctionalityEnabled: false,
          isExchangeParticipant: false,
          isForeign: false
        }
      },
      [RegisterField.FLOW]: {
        mode: NewOrganizationMode.SIMPLE,
        values: {
          ...values,
          isFlowFunctionalityEnabled: true,
          isExchangeParticipant: false,
          isForeign: false
        }
      },
      [RegisterField.EXCHANGE]: {
        mode: isRussia ? this.getMode(values.isForeign) : this.getMode(true),
        values: {
          ...values,
          isFlowFunctionalityEnabled: false,
          isExchangeParticipant: true
        }
      },
      [RegisterField.FLOW_EXCHANGE]: {
        mode: isRussia ? this.getMode(values.isForeign) : this.getMode(true),
        values: {
          ...values,
          isFlowFunctionalityEnabled: true,
          isExchangeParticipant: true
        }
      }
    }

    return changeModeValues[value]
  }

  private readonly handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { onModeChange, setValues } = this.props
    const { value } = e.target
    const { mode, values } = this.getChangeModeValue(value)

    onModeChange(mode)
    setValues(values)
  }

  private readonly handleForeignCheckboxChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { onModeChange, setFieldValue } = this.props
    const { checked } = e.target

    onModeChange(this.getMode(checked))
    setFieldValue(RegisterField.IS_FOREIGN, checked)
  }

  private readonly handleContractorSelect = (contractor: IContractor): void => {
    const { setFieldValue } = this.props

    setFieldValue(RegisterField.CONTRACTOR, contractor)
  }

  private readonly handleContractorReset = (): void => {
    const { setValues, values } = this.props

    setValues({
      ...values,
      [RegisterField.ALIAS]: '',
      [RegisterField.CONTRACTOR]: null
    })
  }

  private readonly getMode = (isForeign: boolean): NewOrganizationMode =>
    isForeign ? NewOrganizationMode.FOREIGN : NewOrganizationMode.SUGGEST
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  instance: state.utils.instance
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onCloseModal: () => dispatch(closeModal)
})

const NewOrganizationModalFormClass = connect(mapStateToProps, mapDispatchToProps)(NewOrganizationModalForm)

export default withTranslation()(NewOrganizationModalFormClass)
