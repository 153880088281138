import { connect } from 'react-redux'

import OrganizationLink from './OrganizationLink'

import { IApplicationState } from '@store/types/commonTypes'
import { ILinkPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  alias: state.user.activeOrganization.alias,
  oguid: state.user.activeOrganization.oguid,
  isRootMode: state.utils.isRootMode
})

export default connect(mapStateToProps)(OrganizationLink)
