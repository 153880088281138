import cn from 'classnames';
import React, { FC } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Path } from '@const/consts';

import { Button, Content } from '@infologistics/frontend-libraries';

import { resetError403 } from '@store/modules/utils/actions';

import {
  AllPage403Props as AllProps,
  IPage403PropsFromDispatch as IPropsFromDispatch,
  IPage404PropsFromState as IPropsFromState,
} from './types';
import { IApplicationState } from '@app/store/types/commonTypes';

import styles from './Page404.500.module.css';
import {
  displayErrorNotification,
  toggleRootModeInUserSettings,
} from '@app/utils/utils';
import { getAllBadges } from '@app/store/modules/navigation/actions';

const Page403: FC<AllProps> = (props: AllProps) => {
  const { onResetError403, t, onGetBadges, history } = props;

  const handleClick = () => {
    onResetError403();

    onGetBadges().catch(displayErrorNotification);
    toggleRootModeInUserSettings(true);

    history.push(Path.ORGS);
  };

  return (
    <Content classes='d-flex justify-content-center align-items-center'>
      <div className='d-flex align-items-center'>
        <div className={styles.content}>
          <p className={cn('fw-700', 'text-brand', 'mb-4', styles.error_code)}>
            403
          </p>
          <p className='fw-600 mb-2'>{t('error:403.title')}</p>
          <div className='text-muted font-sm mb-6'>
            <p className='mb-0'>{t('error:404.footer')}</p>
          </div>
          <Button theme='primary' onClick={handleClick}>
            {t('common:back')}
          </Button>
        </div>
        <img src='/assets/img/error.svg' width='493' height='392' alt='' />
      </div>
    </Content>
  );
};

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isRootMode: state.utils.isRootMode,
});

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onResetError403: () => dispatch(resetError403),
  onGetBadges: () => dispatch(getAllBadges()),
});

const Page403Class = connect(mapStateToProps, mapDispatchToProps)(Page403);

export default withTranslation('error')(Page403Class);
