import cn from 'classnames'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  DropdownList,
  getIcon,
  ILibDropdownItem,
  ILibHorizontalPosition,
  LibIconList,
  Popover
} from '@infologistics/frontend-libraries'

import { setInstance } from '@store/modules/utils/actions'

import { BrowserStorage, Instance } from '@const/consts'
import { dropdownItems } from './consts'
import { getInstance, getDataFromLocalStorage } from '@utils/utils'

import styles from './ButtonInstance.module.css'

const ButtonInstance: FC = () => {
  const [popoverOpened, setPopoverOpened] = useState<boolean>(false)
  const [selectedTitle, setSelectedTitle] = useState<LibIconList>('IconFlagEn')

  const dispatch = useDispatch()
  const instance = getDataFromLocalStorage(BrowserStorage.INSTANCE) as Instance ?? Instance.GLOBAL

  useEffect(() => {
    setSelectedTitle(getFlagName(instance))
  }, [])

  const getFlagName = (value: string): LibIconList => {
    if (value === Instance.RUSSIAN) return 'IconFlagRu'

    if (value === Instance.BRASIL) return 'IconFlagBr'

    if (value === Instance.SOUTH_AFRICA) return 'IconFlagZa'

    return 'IconFlagEn'
  }

  const getSelectedItem = (items: ILibDropdownItem[]): ILibDropdownItem | undefined => items.find(({ name }) => name === instance)

  const toggleClose = (isOpenFromPopover: boolean): void => {
    !isOpenFromPopover && setPopoverOpened(false)
  }

  const handleSelect = ({ name }: ILibDropdownItem): void => {
    setSelectedTitle(getFlagName(name))
    setPopoverOpened(false)

    const instance = getInstance(name)
    window.localStorage.setItem(BrowserStorage.INSTANCE, instance)
    dispatch(setInstance(instance))
  }

  const getButton = (): ReactNode =>
    <div onClick={() => setPopoverOpened(!popoverOpened)}>
      {getIcon(selectedTitle)}
    </div>

  return (
    <div
      className={cn(styles.wrapper, 'fixed')}
    >
      <Popover
        buttonComponent={getButton()}
        withoutArrow
        allowDropdownFlip
        horizontalPosition={ILibHorizontalPosition.RIGHT}
        parentManagement={{
          isOpenFromParent: popoverOpened,
          callbackForClose: toggleClose
        }}
      >
        <DropdownList
          dropdownItems={dropdownItems}
          onSelect={handleSelect}
          itemClasses='p-2'
          selectedItem={getSelectedItem(dropdownItems)}
        />
      </Popover>
    </div>
  )
}

export default ButtonInstance
