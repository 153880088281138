import Loadable from 'react-loadable'

import { SpinnerWrapper } from '@containers/Navigation'
import { AllRoles, DefaultSubMenuItem, Path } from '@const/consts'
import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'

const Messages = Loadable({
  loader: () => import('@views/organization/messages/components/Messages'),
  loading: SpinnerWrapper
})

const Document = Loadable({
  loader: () => import('@views/organization/documents/components/Document'),
  loading: SpinnerWrapper
})

const Receipts = Loadable({
  loader: () => import('@views/organization/messages/components/Receipts'),
  loading: SpinnerWrapper
})

const DocumentEditExternal = Loadable({
  loader: () => import('@views/organization/documents/components/DocumentEdit/DocumentEditExternal'),
  loading: SpinnerWrapper
})

export const routes: IRoute[] = [
  {
    path: Path.RECEIPTS,
    exact: true,
    name: 'Receipts',
    component: Receipts,
    roles: AllRoles,
    isExchangeParticipant: true
  },
  {
    path: Path.MESSAGES_ADD,
    exact: true,
    name: 'MessagesAdd',
    component: DocumentEditExternal,
    roles: AllRoles,
    isExchangeParticipant: true,
    isReadonly: false
  },
  {
    path: Path.MESSAGES_INCOMING,
    exact: true,
    name: 'Messages',
    component: Messages,
    routeAdditionalProp: DefaultSubMenuItem.INCOMING,
    roles: AllRoles,
    isExchangeParticipant: true
  },
  {
    path: Path.MESSAGES_OUTGOING,
    exact: true,
    name: 'Messages',
    component: Messages,
    routeAdditionalProp: DefaultSubMenuItem.OUTGOING,
    roles: AllRoles,
    isExchangeParticipant: true
  },
  {
    path: Path.MESSAGES_EDIT,
    exact: true,
    name: 'Messages',
    component: Document,
    roles: AllRoles,
    isExchangeParticipant: true
  }
]
