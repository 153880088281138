import React, { PureComponent, ReactElement, ReactNode } from 'react'
import { isEqual } from 'lodash'
import { withTranslation } from 'react-i18next'

import { Aside, ILibNavItem, Navigation, LibNavItemLevel } from '@infologistics/frontend-libraries'
import { CreateDocumentLinks, NavMenuItem } from '@containers/Navigation'

import { MenuItems } from '@const'
import { debounce, displayErrorNotification, getIsInterfaceDisabled, removeUnavailableMenuItems } from '@utils/utils'

import { AllRoles, NavMode, UserSettingsKey } from '@const/consts'
import { INavItem, ASIDE_WIDTH_SET_DELAY, NAV_ANIMATION_DELAY, menuRootMode } from '@const/navSidebar'

import TokensService from '@services/tokensService';

import { INavigationProps as IProps, INavigationState as IState } from './types'

import styles from './NavigationWrapper.module.css'

const initialState: IState = {
  isHorizontalNavMode: false
}

export class NavigationWrapper extends PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  public componentDidMount(): void {
    const { userSettings } = this.props
    const isHorizontalNavMode = userSettings?.[UserSettingsKey.NAV_MODE] === NavMode.HORIZONTAL

    this.getNavItems()
    this.setState({ isHorizontalNavMode })
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const {
      isFlowFunctionalityEnabled,
      location: { pathname },
      menuItems,
      onSetMenuItemsWithFilters,
      userFilters,
      userSettings
    } = this.props

    const isAuthenticated = TokensService.checkAccessToken()

    const {
      isFlowFunctionalityEnabled: prevFlowFunctionalityEnabled,
      location: { pathname: prevPathname },
      userFilters: prevUserFilters,
      userSettings: prevUserSettings
    } = prevProps

    const isHorizontalNavMode = userSettings?.[UserSettingsKey.NAV_MODE] === NavMode.HORIZONTAL

    if (isAuthenticated && pathname !== prevPathname) {
      this.props.resetError403()
      this.props.resetError404()
    }

    if (!isEqual(isFlowFunctionalityEnabled, prevFlowFunctionalityEnabled)) this.getNavItems()

    if (!isEqual(userFilters, prevUserFilters)) onSetMenuItemsWithFilters(menuItems, userFilters)

    if (!isEqual(userSettings, prevUserSettings)) this.setState({ isHorizontalNavMode })
  }

  public render(): ReactElement {
    const { isHorizontalNavMode } = this.state

    const isAccessAllowed = this.getAccessAllowed()

    const isDisabled = getIsInterfaceDisabled()

    return isHorizontalNavMode
      ? (
        <Aside isHorizontalNavMode>
          <Navigation
            items={this.getMenuItems(isDisabled)}
            delay={NAV_ANIMATION_DELAY}
            isHorizontalNavMode={isHorizontalNavMode}
            isDisabled={isDisabled}
          />

          {this.getCreateDocumentLinks(isHorizontalNavMode)}
        </Aside>
      )
      : (
        <Aside
          onResize={this.handleAsideResizeWithDebounce}
        >
          {this.getCreateDocumentLinks()}

          <Navigation
            classes={isAccessAllowed ? styles.withPopover : ''}
            items={this.getMenuItems(isDisabled)}
            delay={NAV_ANIMATION_DELAY}
            isDisabled={isDisabled}
          />
        </Aside>
      )
  }

  private readonly getMenuItems = (isDisabled: boolean): ILibNavItem[] => {
    const { menuItemsWithFilters, isRootMode } = this.props

    return isRootMode
      ? this.getExtendedMenuItems(menuRootMode, undefined, isDisabled)
      : this.getExtendedMenuItems(menuItemsWithFilters, undefined, isDisabled)
  }

  private readonly handleAsideResize = (width: string): void => {
    const { onSetAsideWidth, onUpdateUserSettings, userSettings } = this.props

    const updatedUserSettings = {
      ...userSettings,
      [UserSettingsKey.ASIDE_WIDTH]: width
    }

    onSetAsideWidth(width)
    onUpdateUserSettings(updatedUserSettings)
      .catch(displayErrorNotification)
  }

  private readonly handleAsideResizeWithDebounce = debounce(this.handleAsideResize, ASIDE_WIDTH_SET_DELAY)

  private readonly getAccessAllowed = (): boolean => {
    const { isReadonly, role } = this.props

    return AllRoles.includes(role) && !isReadonly
  }

  private readonly getCreateDocumentLinks = (isHorizontalNav = false): ReactNode => {
    const { isRootMode } = this.props
    const isAccessAllowed = this.getAccessAllowed()

    return (isAccessAllowed || isRootMode) && <CreateDocumentLinks isHorizontalNavMode={isHorizontalNav} isRootMode={isRootMode}/>
  }

  private readonly getExtendedMenuItems = (menuItems: INavItem[], level = LibNavItemLevel.TOP, isDisabled?: boolean): ILibNavItem[] => {
    const { activeMenu, location } = this.props

    const activeMenuPathname = activeMenu || location.pathname

    const activeMenuItems = menuItems.filter(({ route }) => activeMenuPathname.includes(route))

    const activeMenuItemUuid = activeMenuItems.length ? activeMenuItems[0].uuid : ''

    return menuItems.map((item): ILibNavItem => this.getExtendedMenuItem(item, level, activeMenuItemUuid, isDisabled))
  }

  private readonly getExtendedMenuItem = (
    menuItem: INavItem,
    menuLevel: string,
    activeMenuItemUuid: string,
    isDisabled?: boolean
  ): ILibNavItem => {
    const { t } = this.props
    const { isHorizontalNavMode } = this.state
    const { items, title, uuid } = menuItem

    const isActive = activeMenuItemUuid === uuid
    const isTopLevel = menuLevel === LibNavItemLevel.TOP

    const subMenuItemsForDropdown = items && this.getExtendedMenuItems(items, LibNavItemLevel.LOW, isDisabled)

    return {
      ...menuItem,
      customNavLink: <NavMenuItem key={uuid} item={menuItem} isHorizontalNavMode={isHorizontalNavMode} isDisabled={isDisabled}/>,
      isActive,
      isOpen: !isTopLevel || isActive,
      items: subMenuItemsForDropdown,
      level: menuLevel,
      title: t(title)
    }
  }

  private readonly getNavItems = (): void => {
    const {
      documentTypes,
      isExchangeParticipant,
      isFlowFunctionalityEnabled,
      isReadonly,
      onSetMenuItems,
      onSetMenuItemsWithFilters,
      role,
      userFilters
    } = this.props

    const user = {
      role,
      isExchangeParticipant,
      isFlowFunctionalityEnabled,
      isReadonly
    }

    const availableMenuItems = removeUnavailableMenuItems(MenuItems, user, documentTypes)
    onSetMenuItems(availableMenuItems)
    onSetMenuItemsWithFilters(availableMenuItems, userFilters)
  }
}

export default withTranslation('menu')(NavigationWrapper)
