import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import divisionsService from '@services/divisions'

import { getPagination } from '@utils/utils'

import { ResponseCode } from '@const/consts'

import { setPagination } from '@store/modules/pagination/actions'
import { IParams } from '@store/types/commonTypes'
import {
  DivisionsActions,
  DivisionsActionTypes,
  DivisionsThunkAction,
  DivisionsThunkDispatch,
  IActionAddDivision,
  IActionGetDivisions,
  IActionRemoveDivision,
  IActionResetDivisions,
  IActionUpdateDivision,
  IFields
} from './types'
import { ILibDivision as IDivision } from '@infologistics/frontend-libraries'

// actions
const addDivisionAction: ActionCreator<Action> = (data: IDivision): IActionAddDivision => ({
  payload: data,
  type: DivisionsActionTypes.ADD_DIVISION
})

const getDivisionsAction: ActionCreator<Action> = (data: IDivision[]): IActionGetDivisions => ({
  payload: data,
  type: DivisionsActionTypes.GET_DIVISIONS
})

const removeDivisionAction: ActionCreator<Action> = (id: string): IActionRemoveDivision => ({
  payload: id,
  type: DivisionsActionTypes.REMOVE_DIVISION
})

export const resetDivisions: IActionResetDivisions = {
  type: DivisionsActionTypes.RESET_DIVISIONS
}

const updateDivisionAction: ActionCreator<Action> = (data: IDivision): IActionUpdateDivision => ({
  payload: data,
  type: DivisionsActionTypes.UPDATE_DIVISION
})

export const actions: DivisionsActions = {
  addDivision: addDivisionAction,
  getDivisions: getDivisionsAction,
  removeDivision: removeDivisionAction,
  updateDivision: updateDivisionAction
}

// thunks
export const getDivisions: ActionCreator<DivisionsThunkAction> = (
  params?: IParams
) => (dispatch: DivisionsThunkDispatch): Promise<AxiosResponse<IDivision[]>> =>
  divisionsService
    .getDivisions({ params })
    .then((resp) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.getDivisions(resp.data ?? []))

        const pagination = getPagination(resp.headers)
        pagination && dispatch(setPagination(pagination))
      }
      return resp
    })
    .catch((error: any) => Promise.reject(error))

export const addDivision: ActionCreator<DivisionsThunkAction> = (
  data: IFields
) => (dispatch: DivisionsThunkDispatch): Promise<AxiosResponse<IDivision>> =>
  divisionsService.addDivision(data)
    .then((resp) => {
      if (resp.status === ResponseCode.POST) {
        dispatch(actions.addDivision(resp.data))
      }
      return resp
    })
    .catch((error: any) => Promise.reject(error))

export const updateDivision: ActionCreator<DivisionsThunkAction> = (
  subOrgOguid: string,
  data: IFields
) => (dispatch: DivisionsThunkDispatch): Promise<AxiosResponse<IDivision>> =>
  divisionsService.updateDivision(subOrgOguid, data)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.PUT) {
        dispatch(actions.updateDivision(resp.data))
      }
      return resp
    })
    .catch((error: any) => Promise.reject(error))

export const removeDivision: ActionCreator<DivisionsThunkAction> = (
  subOrgOguid: string
) => (dispatch: DivisionsThunkDispatch): Promise<AxiosResponse> =>
  divisionsService.removeDivision(subOrgOguid)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.DEL) {
        dispatch(actions.removeDivision(subOrgOguid))
      }
      return resp
    })
    .catch((error: any) => Promise.reject(error))
