import { RegisterField } from '@const/consts'

import { IRadioField, ITextField } from './types'
import { NewOrganizationError, NewOrganizationLabel } from '@const/translations'

export const radioButtons: IRadioField[] = [
  {
    label: NewOrganizationLabel.archive,
    name: RegisterField.ORG_TYPE,
    value: RegisterField.ARCHIHE
  },
  {
    label: NewOrganizationLabel.flow,
    name: RegisterField.ORG_TYPE,
    value: RegisterField.FLOW
  },
  {
    label: NewOrganizationLabel.exchange,
    name: RegisterField.ORG_TYPE,
    value: RegisterField.EXCHANGE
  },
  {
    label: NewOrganizationLabel.flowExchange,
    name: RegisterField.ORG_TYPE,
    value: RegisterField.FLOW_EXCHANGE
  }
]

export const foreignModeFields: ITextField[] = [
  {
    errorTitle: NewOrganizationError.name,
    label: NewOrganizationLabel.name,
    name: RegisterField.NAME,
    required: true
  },
  {
    errorTitle: NewOrganizationError.name,
    label: NewOrganizationLabel.nameShort,
    name: RegisterField.NAME_SHORT,
    required: true
  },
  {
    errorTitle: NewOrganizationError.ogrn,
    label: NewOrganizationLabel.ogrnList,
    name: RegisterField.OGRN,
    narrow: true,
    required: true
  },
  {
    errorTitle: NewOrganizationError.alias,
    label: NewOrganizationLabel.alias,
    name: RegisterField.ALIAS,
    narrow: true,
    required: true
  }
]

export const simpleModeFields: ITextField[] = [
  {
    errorTitle: NewOrganizationError.name,
    label: NewOrganizationLabel.name,
    name: RegisterField.NAME,
    required: true
  },
  {
    errorTitle: NewOrganizationError.name,
    label: NewOrganizationLabel.nameShort,
    name: RegisterField.NAME_SHORT,
    required: true
  },
  {
    errorTitle: NewOrganizationError.alias,
    label: NewOrganizationLabel.alias,
    name: RegisterField.ALIAS,
    narrow: true,
    required: true
  }
]

export const suggestModeFields: ITextField[] = [
  {
    label: NewOrganizationLabel.contractor,
    name: RegisterField.CONTRACTOR,
    required: true
  },
  {
    errorTitle: NewOrganizationError.alias,
    label: NewOrganizationLabel.alias,
    name: RegisterField.ALIAS,
    narrow: true,
    required: true
  }
]
