import { Actions, DivisionsActionTypes } from './types'
import { ILibDivision as IDivision } from '@infologistics/frontend-libraries'

export const initialState: IDivision[] = []

export default function divisionsReducer(state: IDivision[] = initialState, action: Actions): IDivision[] {
  let id = -1
  switch (action.type) {
    case DivisionsActionTypes.GET_DIVISIONS:
      return [...action.payload]

    case DivisionsActionTypes.ADD_DIVISION:
      return [...state, action.payload]

    case DivisionsActionTypes.UPDATE_DIVISION: {
      id = -1
      const updateDivisions = state.slice()
      state.forEach((division: IDivision, i) => {
        if (division.oguid === action.payload.oguid) {
          id = i
        }
      })
      if (id !== -1) {
        updateDivisions[id] = action.payload
      }
      return [...updateDivisions]
    }

    case DivisionsActionTypes.REMOVE_DIVISION: {
      const filteredDivisions = state.filter((division: IDivision) => division.oguid !== action.payload)
      return [...filteredDivisions]
    }

    case DivisionsActionTypes.RESET_DIVISIONS: {
      return initialState
    }

    default:
      return state
  }
}
