import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import handbooksService from '@services/handbooks'
import { setPagination } from '@store/modules/pagination/actions'

import { getPagination } from '@utils/utils'
import { PER_PAGE_ITEMS_MAX, ResponseCode } from '@const/consts'

import { ILibPagination } from '@infologistics/frontend-libraries'
import { IParams, Nullable } from '@store/types/commonTypes'
import {
  HandbooksActions,
  HandbooksActionTypes,
  HandbooksThunkAction,
  HandbooksThunkDispatch,
  IActionAddHandbookValue,
  IActionAddRelations,
  IActionChangeHandbookValue,
  IActionDeleteHandbook,
  IActionDeleteHandbookRelation,
  IActionDeleteHandbookRelations,
  IActionDeleteHandbookValue,
  IActionGetHandbookNames,
  IActionGetHandbookValues,
  IActionImportValues,
  IActionReceiveRelations,
  IActionReceiveSIngleValueRelations,
  IActionReceiveSIngleValueRelationsTable,
  IActionResetHandbooks,
  IActionResetSingleValueRelations,
  IActionResetSingleValueRelationTable,
  IActionSetSingleValueRelationsTable,
  IActionResetSingleValueRelation,
  IActionUpdateRelations,
  IHandbook,
  IHandbookRelations,
  IRelation,
  IRelations
} from './types'

// actions
const addHandbookValueAction: ActionCreator<Action> = (data: any, name: string): IActionAddHandbookValue => ({
  name,
  payload: data,
  type: HandbooksActionTypes.ADD_HANDBOOK_VALUE
})

const addRelationsAction: ActionCreator<Action> = (data: any): IActionAddRelations => ({
  payload: data,
  type: HandbooksActionTypes.ADD_RELATIONS
})

const changeHandbookValueAction: ActionCreator<Action> = (
  data: any,
  key: string,
  name: string
): IActionChangeHandbookValue => ({
  key,
  name,
  payload: data,
  type: HandbooksActionTypes.CHANGE_HANDBOOK_VALUE
})

const deleteHandbookAction: ActionCreator<Action> = (data: any, name: string): IActionDeleteHandbook => ({
  name,
  payload: data,
  type: HandbooksActionTypes.DELETE_HANDBOOK
})

const deleteHandbookRelationAction: ActionCreator<Action> = (
  dictType: string,
  key: string,
  relatedDictType: string,
  relatedKey: string
): IActionDeleteHandbookRelation => ({
  payload: {
    dictType,
    key,
    relatedDictType,
    relatedKey
  },
  type: HandbooksActionTypes.DELETE_HANDBOOK_RELATION
})

const deleteHandbookRelationsAction: ActionCreator<Action> = (data: any): IActionDeleteHandbookRelations => ({
  payload: data,
  type: HandbooksActionTypes.DELETE_HANDBOOK_RELATIONS
})

const deleteHandbookValueAction: ActionCreator<Action> = (
  data: any,
  key: string,
  name: string
): IActionDeleteHandbookValue => ({
  key,
  name,
  payload: data,
  type: HandbooksActionTypes.DELETE_HANDBOOK_VALUE
})

const getHandbookNamesAction: ActionCreator<Action> = (data: any): IActionGetHandbookNames => ({
  payload: data,
  type: HandbooksActionTypes.GET_HANDBOOK_NAMES
})

const getHandbookValuesAction: ActionCreator<Action> = (
  data: any,
  name: string,
  pagination: ILibPagination
): IActionGetHandbookValues => ({
  name,
  pagination,
  payload: data,
  type: HandbooksActionTypes.GET_HANDBOOK_VALUES
})

const importValuesAction: ActionCreator<Action> = (data: any): IActionImportValues => ({
  payload: data,
  type: HandbooksActionTypes.IMPORT_VALUES
})

const receiveRelationsAction: ActionCreator<Action> = (data: IRelations[], name: string): IActionReceiveRelations => ({
  name,
  payload: data,
  type: HandbooksActionTypes.RECEIVE_RELATIONS
})

const receiveSingleValueRelationsAction: ActionCreator<Action> = (data: IHandbookRelations, name: string): IActionReceiveSIngleValueRelations => ({
  name,
  payload: data,
  type: HandbooksActionTypes.RECEIVE_SINGLE_VALUE_RELATIONS
})

const receiveSingleValueRelationsTableAction: ActionCreator<Action> = (data: IHandbookRelations, name: string, row: number): IActionReceiveSIngleValueRelationsTable => ({
  name,
  payload: data,
  row,
  type: HandbooksActionTypes.RECEIVE_SINGLE_VALUE_RELATIONS_TABLE
})

const resetSingleValueRelationTableAction: ActionCreator<Action> = (data: string, row: number): IActionResetSingleValueRelationTable => ({
  payload: data,
  row,
  type: HandbooksActionTypes.RESET_SINGLE_VALUE_RELATION_TABLE
})

const setSingleValueRelationsTableAction: ActionCreator<Action> = (data: IHandbookRelations[]): IActionSetSingleValueRelationsTable => ({
  payload: data,
  type: HandbooksActionTypes.SET_SINGLE_VALUE_RELATIONS_TABLE
})

export const resetSingleValueRelations: IActionResetSingleValueRelations = {
  type: HandbooksActionTypes.RESET_SINGLE_VALUE_RELATIONS
}

export const resetSingleValueRelation: ActionCreator<Action> = (data: any): IActionResetSingleValueRelation => ({
  payload: data,
  type: HandbooksActionTypes.RESET_SINGLE_VALUE_RELATION
})

export const resetHandbooks: IActionResetHandbooks = {
  type: HandbooksActionTypes.RESET_HANDBOOKS
}

const updateRelationsAction: ActionCreator<Action> = (data: any): IActionUpdateRelations => ({
  payload: data,
  type: HandbooksActionTypes.UPDATE_RELATIONS
})

export const actions: HandbooksActions = {
  addHandbookValue: addHandbookValueAction,
  addRelations: addRelationsAction,
  changeHandbookValue: changeHandbookValueAction,
  deleteHandbook: deleteHandbookAction,
  deleteHandbookRelation: deleteHandbookRelationAction,
  deleteHandbookRelations: deleteHandbookRelationsAction,
  deleteHandbookValue: deleteHandbookValueAction,
  getHandbookNames: getHandbookNamesAction,
  getHandbookValues: getHandbookValuesAction,
  importValues: importValuesAction,
  receiveRelations: receiveRelationsAction,
  receiveSingleValueRelations: receiveSingleValueRelationsAction,
  receiveSingleValueRelationsTable: receiveSingleValueRelationsTableAction,
  resetSingleValueRelationTable: resetSingleValueRelationTableAction,
  setSingleValueRelationsTable: setSingleValueRelationsTableAction,
  updateRelations: updateRelationsAction
}

// thunks
export const getHandbookNames: ActionCreator<HandbooksThunkAction> = () => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .getNames()
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.getHandbookNames(resp.data))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const deleteHandbook: ActionCreator<HandbooksThunkAction> = (
  name: string
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .deleteHandbook(name)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.DEL) {
          dispatch(actions.deleteHandbook(resp.data, name))
        }

        return resp
      })
      .catch((error: AxiosError) => Promise.reject(error))
  }
}

export const addHandbookValue: ActionCreator<HandbooksThunkAction> = (
  data: IHandbook[],
  name: string
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .addValue(name, data)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.addHandbookValue(resp.data ?? [], name))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const deleteHandbookValue: ActionCreator<HandbooksThunkAction> = (
  key: string,
  name: string
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .deleteValue(key, name)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.DEL) {
          dispatch(actions.deleteHandbookValue(resp.data, key, name))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const changeHandbookValue: ActionCreator<HandbooksThunkAction> = (
  key: string,
  name: string,
  data: IHandbook
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .changeValue(key, name, data)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.changeHandbookValue(resp.data, key, name))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const receiveRelations: ActionCreator<HandbooksThunkAction> = (
  name: string
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .receiveRelations(name)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.receiveRelations(resp.data ?? [], name))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const receiveSingleValueRelations: ActionCreator<HandbooksThunkAction> = (
  name: string,
  key: string,
  dependentDictName?: Nullable<string>,
  row?: number
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .receiveSingleValueRelations(name, key)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          const data = dependentDictName ? { [dependentDictName]: resp.data[dependentDictName] ?? [] } : {}
          if (!row) dispatch(actions.receiveSingleValueRelations(data, name))
          else dispatch(actions.receiveSingleValueRelationsTable(data, name, row))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const receiveDictValue: ActionCreator<HandbooksThunkAction> = (
  name: string,
  params: Record<string, Nullable<string>>,
  row?: number
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .getValues(name, { params })
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          const data = { [name]: resp.data }
          if (!row) dispatch(actions.receiveSingleValueRelations(data, name))
          else dispatch(actions.receiveSingleValueRelationsTable(data, name, row))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const deleteHandbookRelations: ActionCreator<HandbooksThunkAction> = (
  name: string
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .deleteRelations(name)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.DEL) {
          dispatch(actions.deleteHandbookRelations(resp.data))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const addRelations: ActionCreator<HandbooksThunkAction> = (
  data: IRelation,
  name: string,
  key: string
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .addRelations(name, key, data)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.addRelations(resp.data))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const updateRelations: ActionCreator<HandbooksThunkAction> = (
  data: IRelation,
  name: string,
  key: string
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .updateRelations(name, key, data)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.updateRelations(resp.data))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const importValues: ActionCreator<HandbooksThunkAction> = (
  data: any,
  name: string
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .importValues(name, data)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.importValues(resp.data))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const getHandbookValues: ActionCreator<HandbooksThunkAction> = (
  name: string,
  params?: IParams
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .getValues(name, { params })
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(actions.getHandbookValues(resp.data ?? [], name))

          const pagination = getPagination(resp.headers)
          pagination && pagination.itemsPerPage <= PER_PAGE_ITEMS_MAX && dispatch(setPagination(pagination))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}

export const deleteHandbookRelation: ActionCreator<HandbooksThunkAction> = (
  dictType: string,
  key: string,
  relatedDictType: string,
  relatedKey: string
) => {
  return (dispatch: HandbooksThunkDispatch): Promise<AxiosResponse> => {
    return handbooksService
      .deleteRelation(dictType, key, relatedDictType, relatedKey)
      .then((resp: AxiosResponse) => {
        if (resp.status === ResponseCode.DEL) {
          dispatch(actions.deleteHandbookRelation(dictType, key, relatedDictType, relatedKey))
        }

        return resp
      })
      .catch((error: any) => Promise.reject(error))
  }
}
