import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { LoaderActionTypes, IActionCloseLoader, IActionShowLoader, ILoaderState } from './types'

// actions
export const showLoaderAction: IActionShowLoader = { type: LoaderActionTypes.SHOW_LOADER }
export type LoaderThunkDispatch = ActionCreator<ThunkAction<void, ILoaderState, void, Action<string>>>
const closeLoaderAction: IActionCloseLoader = { type: LoaderActionTypes.CLOSE_LOADER }

export const actions = {
  showLoader: showLoaderAction,
  closeLoader: closeLoaderAction
}

// thunks
export const closeLoader: LoaderThunkDispatch = () => {
  return (dispatch: ThunkDispatch<ILoaderState, void, Action<string>>): void => {
    setTimeout(() => {
      dispatch(closeLoaderAction)
    }, 300)
  }
}
