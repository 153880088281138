import React, { FC, ReactElement, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { Loader, Layout } from '@infologistics/frontend-libraries'
import { RenderRoutes } from '@common/Routing'

import { modifyRoutes } from '@utils/utils'

import { privateRoutes } from '@app/routes'

import { setLastLocation } from '@store/modules/utils/actions'

import { ILayoutProps as IProps } from './types'
import { NavMode, UserSettingsKey } from '@app/const/consts'
import { AllRouteParams } from '@store/types/commonTypes'

const LayoutWrapper: FC<IProps> = ({
  isRootMode,
  isLoading,
  isShownMenus,
  isShownNavMenu,
  role,
  isExchangeParticipant,
  isFlowFunctionalityEnabled,
  isReadonly,
  userSettings
}: IProps): ReactElement => {
  const dispatch = useDispatch<any>()

  const location = useLocation<AllRouteParams>()

  const widthClass = userSettings?.[UserSettingsKey.NAV_MODE] === NavMode.HORIZONTAL
  ? `fl-layout-full ${isShownNavMenu ? 'fl-layout-full-horizontal-nav' : null}`
  : isShownMenus ? undefined : 'fl-layout-full'

  const user = isRootMode ? null : {
    role,
    isExchangeParticipant,
    isFlowFunctionalityEnabled,
    isReadonly
  }

  useEffect(() => {
    dispatch(setLastLocation(location.pathname))
  }, [location])

  return (
    <Layout externalClass={widthClass}>
      <RenderRoutes routes={modifyRoutes(privateRoutes, user)} />
      <Loader loading={isLoading} />
    </Layout>
  )
}

export default LayoutWrapper
