import { Formik } from 'formik'
import React, { Component, ReactNode } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { ILibModalSizes } from '@infologistics/frontend-libraries'
import ModalWrapper from '@common/ModalWrapper/ModalWrapper'
import NewOrganizationModalForm from './components/NewOrganizationModalForm'

import { displayErrorNotification } from '@utils/utils'
import { getValidationSchema } from './validationSchema'

import { Instance, Modal, NewOrganizationMode } from '@const/consts'

import { closeModal } from '@store/modules/modal/actions'
import { addNewOrganization, requestUserOrganizations } from '@store/modules/user/actions'

import { IApplicationState, INewOrganization } from '@store/types/commonTypes'
import {
  INewOrganizationModalProps as IProps,
  INewOrganizationModalPropsFromState as IPropsFromState,
  INewOrganizationModalPropsFromDispatch as IPropsFromDispatch,
  INewOrganizationModalState as IState,
  INewOrganizationValues
} from './types'

const initialState: IState = {
  mode: NewOrganizationMode.SIMPLE,
  values: {
    alias: '',
    contractor: null,
    isExchangeParticipant: false,
    isFlowFunctionalityEnabled: false,
    isForeign: false,
    name: '',
    nameShort: '',
    ogrn: ''
  },
  isSaving: false
}

class NewOrganizationModal extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      ...initialState
    }
  }

  public render(): ReactNode {
    const { onChangeIsOpenModal, t, instance } = this.props
    const { mode, values, isSaving } = this.state

    const validationSchema = getValidationSchema(mode, instance)

    return (
      <ModalWrapper
        onCloseModal={onChangeIsOpenModal}
        id={Modal.NEW_ORGANIZATION}
        size={ILibModalSizes.MEDIUM}
        closeText={t('common:close')}
      >
        <Formik
          initialValues={values}
          onSubmit={this.handleSubmit}
          validateOnChange
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {(props) => (
            <NewOrganizationModalForm
              mode={mode}
              onChangeIsOpenModal={onChangeIsOpenModal}
              onModeChange={this.handleModeChange}
              isSaving={isSaving}
              {...props}
            />
          )}
        </Formik>
      </ModalWrapper>
    )
  }

  private readonly handleModeChange = (mode: NewOrganizationMode): void => {
    this.setState({
      mode
    })
  }

  private readonly handleSubmit = ({
    alias,
    contractor,
    isFlowFunctionalityEnabled,
    isExchangeParticipant,
    isForeign,
    name,
    nameShort,
    ogrn
  }: INewOrganizationValues): void => {
    const { onAddNewOrganization, onRequestUserOrganizations, instance } = this.props

    const isForeignValue = instance !== Instance.RUSSIAN ? true : isForeign

    const org = {
      alias,
      hid: contractor?.hid ?? null,
      isExchangeParticipant,
      isFlowFunctionalityEnabled,
      isForeign: isForeignValue,
      name: name || null,
      nameShort: nameShort || null,
      ogrn: ogrn || null
    }

    this.setState({
      isSaving: true
    })

    onAddNewOrganization(org)
      .then(onRequestUserOrganizations)
      .then(this.handleCloseModal)
      .catch(displayErrorNotification)
      .finally(() => this.setState({ isSaving: false }))
  }

  private readonly handleCloseModal = (): void => {
    const { onChangeIsOpenModal, onCloseModal } = this.props

    onChangeIsOpenModal()
    onCloseModal()
  }
}

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  instance: state.utils.instance
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onAddNewOrganization: (organization_data: INewOrganization) => dispatch(addNewOrganization(organization_data)),
  onCloseModal: () => dispatch(closeModal),
  onRequestUserOrganizations: () => dispatch(requestUserOrganizations())
})

const NewOrganizationModalClass = connect(mapStateToProps, mapDispatchToProps)(NewOrganizationModal)

export default withTranslation()(NewOrganizationModalClass)
