import { Actions, IUsersState, UsersActionTypes } from './types'

export const initialState: IUsersState = {
  invites: [],
  profiles: [],
  selectedInvites: {}
}

export default function usersReducer(state: IUsersState = initialState, action: Actions): IUsersState {
  switch (action.type) {
    case UsersActionTypes.RESET_USERS: {
      return {
        ...state,
        profiles: initialState.profiles
      }
    }

    case UsersActionTypes.RESET_USER_INVITES: {
      return {
        ...state,
        invites: initialState.invites,
        selectedInvites: initialState.selectedInvites
      }
    }

    case UsersActionTypes.SELECT_INVITE:
      return {
        ...state,
        selectedInvites: {
          ...state.selectedInvites,
          [action.payload]: !state.selectedInvites[action.payload]
        }
      }

    case UsersActionTypes.SELECT_INVITES:
      return {
        ...state,
        selectedInvites: {
          ...action.payload
        }
      }

    case UsersActionTypes.SET_USERS: {
      return {
        ...state,
        profiles: [...action.payload]
      }
    }

    case UsersActionTypes.SET_USER_INVITES: {
      return {
        ...state,
        invites: [...action.payload],
        selectedInvites: {}
      }
    }

    default:
      return state
  }
}
