import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import flowsService from '@services/flows'

import { ResponseCode } from '@const/consts'

import {
  IActionSetApprovalRoute,
  IActionResetApprovalScheme,
  IScheme,
  ApprovalSchemeActions,
  ApprovalSchemeActionTypes,
  ApprovalSchemeThunkAction,
  ApprovalSchemeThunkDispatch
} from './types'

const setApprovalRouteAction: ActionCreator<Action> = (data: IScheme): IActionSetApprovalRoute => ({
  payload: data,
  type: ApprovalSchemeActionTypes.SET_APPROVAL_ROUTE
})

export const resetApprovalScheme: IActionResetApprovalScheme = {
  type: ApprovalSchemeActionTypes.RESET_APPROVAL_SCHEME
}

export const actions: ApprovalSchemeActions = {
  setApprovalRoute: setApprovalRouteAction
}

export const getApprovalScheme: ActionCreator<ApprovalSchemeThunkAction> = (flowEntityOguid: string) => (
  dispatch: ApprovalSchemeThunkDispatch
): Promise<AxiosResponse> =>
  flowsService.getApprovalScheme(flowEntityOguid)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.setApprovalRoute(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const setApprovalScheme: ActionCreator<ApprovalSchemeThunkAction> = (scheme: IScheme) => (
  dispatch: ApprovalSchemeThunkDispatch
): Promise<void> =>
  new Promise<void>((resolve) => {
    dispatch(actions.setApprovalRoute(scheme))
    resolve()
  })
