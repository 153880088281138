import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Navigation from './NavigationWrapper'

import { INavItem } from '@const/navSidebar'

import { actions } from '@store/modules/navigation/actions'
import { IUserFilter } from '@store/modules/userFilters/types'
import { resetError403, resetError404, setAsideWidth } from '@store/modules/utils/actions'
import { IApplicationState } from '@store/types/commonTypes'
import { updateUserSettings } from '@store/modules/user/actions'

import {
  INavigationWrapperPropsFromDispatch as IPropsFromDispatch,
  INavigationWrapperPropsFromState as IPropsFromState
} from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  activeMenu: state.navigation.activeMenu,
  documentTypes: state.metadata.userDocTypes,
  isExchangeParticipant: state.user.isExchangeParticipant,
  isFlowFunctionalityEnabled: state.user.isFlowFunctionalityEnabled,
  isReadonly: state.user.isReadonly,
  menuItems: state.navigation.menuItems,
  menuItemsWithFilters: state.navigation.menuItemsWithFilters,
  orgs: state.user.profile.orgs,
  role: state.user.role,
  userFilters: state.userFilters,
  orgOguid: state.user.activeOrganization.oguid,
  userSettings: state.user.settings,
  isRootMode: state.utils.isRootMode
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onSetAsideWidth: (width: string) => dispatch(setAsideWidth(width)),
  onSetMenuItems: (menuItems: INavItem[]) => dispatch(actions.setMenuItems(menuItems)),
  onSetMenuItemsWithFilters: (menuItems: INavItem[], userFilters: IUserFilter[]) =>
    dispatch(actions.setMenuItemsWithFilters(menuItems, userFilters)),
  resetError403: () => dispatch(resetError403),
  resetError404: () => dispatch(resetError404),
  onUpdateUserSettings: (userSettings: object) => dispatch(updateUserSettings(userSettings))
})

const NavigationConnect = connect(mapStateToProps, mapDispatchToProps)(Navigation)

export default withRouter(NavigationConnect)
