import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { RedirectToStartPage } from '@common/Routing'

import { IApplicationState } from '@store/types/commonTypes'
import { IRedirectToLoginPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  alias: state.user.activeOrganization.alias,
  isRootMode: state.utils.isRootMode,
  oguid: state.user.activeOrganization.oguid
})

export default withRouter(connect(mapStateToProps)(RedirectToStartPage))
