import { AxiosResponse } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import { needResetParams } from '@const/consts'
import urls from '@const/urls'
import { IUserFilter } from '@store/modules/userFilters/types'

class UserFiltersService extends AbstractHttpService {
  private readonly url = urls.userFilters

  async get(): Promise<AxiosResponse<IUserFilter[]>> {
    const url = this.url.list

    return await this._http.get(url)
  }

  async save(filter: IUserFilter): Promise<AxiosResponse<IUserFilter>> {
    const url = this.url.list

    return await this._http.post(url, filter, { params: { ...needResetParams } })
  }

  async update(filter: IUserFilter): Promise<AxiosResponse<IUserFilter>> {
    const { oguid } = filter

    if (!oguid) throw new Error('Отсутствует огуид')

    const url = this.url.single.replace('{{ oguid }}', oguid)

    delete filter.oguid

    return await this._http.put(url, filter, { params: { ...needResetParams } })
  }

  async delete(filterOguid: string): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ oguid }}', filterOguid)

    return await this._http.delete(url, { params: { ...needResetParams } })
  }
}

export default new UserFiltersService()
