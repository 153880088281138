import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import documentsService from '@services/documents'

import { getPagination } from '@utils/utils'
import { ResponseCode } from '@const/consts'

import { setPagination } from '@store/modules/pagination/actions'

import {
  IActionGetReceipts,
  IActionResetReceipts,
  IReceipt,
  ReceiptsActions,
  ReceiptsActionTypes,
  ReceiptsThunkAction,
  ReceiptsThunkDispatch
} from './types'
import { IPagination } from '@infologistics/frontend-libraries/dist/components/Pagination/types'

const getReceiptsAction: ActionCreator<Action> = (data: IReceipt[]): IActionGetReceipts => ({
  payload: data,
  type: ReceiptsActionTypes.GET_RECEIPTS
})

export const resetReceipts: IActionResetReceipts = {
  type: ReceiptsActionTypes.RESET_RECEIPTS
}

export const actions: ReceiptsActions = {
  getReceipts: getReceiptsAction
}

export const getReceipts: ActionCreator<ReceiptsThunkAction> = (
  params: IPagination
) => (dispatch: ReceiptsThunkDispatch): Promise<AxiosResponse> =>
  documentsService.getReceipts(params)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.getReceipts(resp.data ?? []))

        const pagination = getPagination(resp.headers)
        pagination && dispatch(setPagination(pagination))
      }

      return resp
    })
    .catch((error: any) => Promise.reject(error))
