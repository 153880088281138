import { GroupField } from '@const/consts'

import { Actions, GroupActionTypes, IGroupState } from './types'

export const initialState: IGroupState = {
  [GroupField.INITIAL_NAME]: '',
  [GroupField.MEMBERS]: [],
  [GroupField.NAME]: '',
  [GroupField.OGUID]: '',
  [GroupField.SELECTED_MEMBERS]: {}
}

export default function groupReducer(state: IGroupState = initialState, action: Actions): IGroupState {
  switch (action.type) {
    case GroupActionTypes.GET_GROUP:
      return {
        ...state,
        [GroupField.INITIAL_NAME]: action.payload[GroupField.NAME],
        [GroupField.NAME]: action.payload[GroupField.NAME],
        [GroupField.OGUID]: action.payload[GroupField.OGUID]
      }

    case GroupActionTypes.GET_MEMBERS:
      return {
        ...state,
        [GroupField.MEMBERS]: action.payload
      }

    case GroupActionTypes.RESET_GROUP:
      return initialState

    case GroupActionTypes.UPDATE_GROUP_NAME:
      return {
        ...state,
        [GroupField.NAME]: action.payload
      }

    default:
      return state
  }
}
