import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IPaginationParams } from '@common/PaginationWrapper/types'
import { Nullable } from '@app/store/types/commonTypes'

export enum ContractorsActionTypes {
  ADD_CONTRACTOR = 'ADD_CONTRACTOR',
  DELETE_CONTRACTOR = 'DELETE_CONTRACTOR',
  EDIT_CONTRACTOR = 'EDIT_CONTRACTOR',
  RESET_CONTRACTORS = 'RESET_CONTRACTORS',
  GET_CONTRACTOR = 'GET_CONTRACTOR',
  SET_PAGINATION = 'SET_PAGINATION',
  SAVE_FILTER_PARAMS = 'SAVE_FILTER_PARAMS'
}

export interface IContractorsState {
  data: IContractorData[]
  pagination: IPaginationParams
}

export interface IContractorData {
  addrText?: Nullable<string>
  bank: Nullable<string>
  bik: string
  checkAccount: Nullable<string>
  comment: Nullable<string>
  corAccount: Nullable<string>
  externalKey: Nullable<string>
  foreignAddress?: Nullable<string>
  foreignCountry?: Nullable<string>
  inn: Nullable<string>
  isActivated: boolean
  isForeign: boolean
  isManual: boolean
  isValidated: boolean
  key: string
  kpp: Nullable<string>
  liqudationDate: Nullable<number>
  name: string
  ogrn: Nullable<string>
  oguid: string
  nameShort: string
}

export interface IFieldData {
  oid: number
  oguid: string
  value: string
}

export interface IContractor {
  externalKey: Nullable<string>
  name: string
  nameShort: string
  ogrn: string
  foreignCountry: Nullable<string>
  foreignAddress: Nullable<string>
  comment: Nullable<string>
  isValidated?: boolean
}

export interface IForeignContractor {
  comment: Nullable<string>
  externalKey: Nullable<string>
  foreignCountry: Nullable<string>
  foreignAddress: Nullable<string>
  name: string
  nameShort: string
  ogrn: Nullable<string>
}

export interface IManualContractor {
  addrText: Nullable<string>
  comment: Nullable<string>
  externalKey: Nullable<string>
  inn: string
  isValidated: boolean
  kpp: Nullable<string>
  name: string
  nameShort: string
  ogrn: Nullable<string>
}

export interface IEditContractorBody {
  comment: Nullable<string>
  externalKey: Nullable<string>
  isValidated: boolean
}

export interface IActionAddContractor extends Action<string> {
  payload: IContractorData[]
  type: ContractorsActionTypes.ADD_CONTRACTOR
}

export interface IActionDeleteContractor extends Action<string> {
  payload: string
  type: ContractorsActionTypes.DELETE_CONTRACTOR
}

export interface IActionEditContractor extends Action<string> {
  payload: IContractorData
  type: ContractorsActionTypes.EDIT_CONTRACTOR
}

// TODO get rid of ANY in payload
export interface IActionGetContractor extends Action<string> {
  payload: any
  type: ContractorsActionTypes.GET_CONTRACTOR
}

export interface IActionResetContractors extends Action<string> {
  type: ContractorsActionTypes.RESET_CONTRACTORS
}

export interface IActionSetPagination extends Action<string> {
  payload: IPaginationParams
  type: ContractorsActionTypes.SET_PAGINATION
}

// TODO get rid of ANY in payload
export interface IActionSaveFilterParams extends Action<string> {
  payload: any
  type: ContractorsActionTypes.SAVE_FILTER_PARAMS
}

export type Actions =
  | IActionAddContractor
  | IActionDeleteContractor
  | IActionEditContractor
  | IActionGetContractor
  | IActionResetContractors
  | IActionSetPagination
  | IActionSaveFilterParams

type ActionKeys =
  | 'addContractor'
  | 'deleteContractor'
  | 'editContractor'
  | 'getContractor'
  | 'setPagination'
  | 'saveFilterParams'

export type ContractorsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type ContractorsThunkAction = ThunkAction<Promise<AxiosResponse>, IContractorsState, void, Action<string>>

export type ContractorsThunkDispatch = ThunkDispatch<IContractorsState, void, Action<string>>
