import Loadable from 'react-loadable'
import { SpinnerWrapper } from '@containers/Navigation'
import { AllRoles, Path } from '@const/consts'
import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'

const UtdEdit = Loadable({
  loader: () => import('@views/organization/utd/components/UtdEdit'),
  loading: SpinnerWrapper
})

export const routes: IRoute[] = [
  {
    path: Path.UTD_ADD,
    exact: true,
    name: 'UtdEdit',
    component: UtdEdit,
    roles: AllRoles
  }
]
