import { AxiosResponse, AxiosRequestConfig } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import {
  IEditContractorBody,
  IForeignContractor,
  IManualContractor
} from '@store/modules/contractors/types'

class ContractorsService extends AbstractHttpService {
  private readonly url = urls.contractors

  async addContractorByHid(hid: string): Promise<AxiosResponse> {
    const url = this.url.addByHid

    return await this._http.post(url, [hid])
  }

  async addContractorByOguid(oguid: string): Promise<AxiosResponse> {
    const url = this.url.addByOguid

    return await this._http.post(url, [oguid])
  }

  async addForeignContractor(data: IForeignContractor): Promise<AxiosResponse> {
    const url = this.url.addForeign

    return await this._http.post(url, [data])
  }

  async addManualContractor(data: IManualContractor): Promise<AxiosResponse> {
    const url = this.url.addManual

    return await this._http.post(url, data)
  }

  async deleteContractor(contractorOguid: string): Promise<AxiosResponse> {
    const url = this.url.delete.replace('{{ oguid }}', contractorOguid)

    return await this._http.delete(url)
  }

  async editContractor(contractorOguid: string, data: IEditContractorBody): Promise<AxiosResponse> {
    const url = this.url.edit.replace('{{ oguid }}', contractorOguid)

    return await this._http.put(url, data)
  }

  async editForeignContractor(contractorOguid: string, data: IForeignContractor): Promise<AxiosResponse> {
    const url = this.url.editForeign.replace('{{ oguid }}', contractorOguid)

    return await this._http.put(url, data)
  }

  async editManualContractor(contractorOguid: string, data: IManualContractor): Promise<AxiosResponse> {
    const url = this.url.editManual.replace('{{ oguid }}', contractorOguid)

    return await this._http.put(url, data)
  }

  async getContractor(oguid: string, params?: any): Promise<AxiosResponse> {
    const url = this.url.get.replace('{{ oguid }}', oguid)
    const config: AxiosRequestConfig = {
      params
    }

    return await this._http.get(url, config)
  }

  async getContractors(params: any): Promise<AxiosResponse> {
    const url = this.url.list

    const config: AxiosRequestConfig = {
      params
    }

    return await this._http.get(url, config)
  }

  async getSuggestContractors(params: any, withoutOrg?: boolean): Promise<AxiosResponse> {
    const url = `${withoutOrg ? 'orgs/' : ''}${this.url.suggest}`

    const config: AxiosRequestConfig = {
      params
    }

    return await this._http.get(url, config, !withoutOrg)
  }
}

export default new ContractorsService()
