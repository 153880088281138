import { Action, ActionCreator } from 'redux'
import { AxiosResponse } from 'axios'

import { SuccessCode } from '@const/consts'
import userFiltersService from '@services/userFilters'

import {
  ActionKey,
  IActionAddUserFilter,
  IActionDeleteUserFilter,
  IActionSetUserFilters,
  IActionUpdateUserFilter,
  IUserFilter,
  UserFiltersActions,
  UserFiltersActionTypes,
  UserFiltersThunkAction,
  UserFiltersThunkDispatch
} from './types'

export const addUserFilterAction: ActionCreator<Action> = (userFilter: IUserFilter): IActionAddUserFilter => ({
  payload: userFilter,
  type: UserFiltersActionTypes.ADD_USER_FILTER
})

export const deleteUserFilterAction: ActionCreator<Action> = (oguid: string): IActionDeleteUserFilter => ({
  payload: oguid,
  type: UserFiltersActionTypes.DELETE_USER_FILTER
})

export const setUserFiltersAction: ActionCreator<Action> = (userFilters: IUserFilter[]): IActionSetUserFilters => ({
  payload: userFilters,
  type: UserFiltersActionTypes.SET_USER_FILTERS
})

export const updateUserFilterAction: ActionCreator<Action> = (userFilter: IUserFilter): IActionUpdateUserFilter => ({
  payload: userFilter,
  type: UserFiltersActionTypes.UPDATE_USER_FILTER
})

export const actions: UserFiltersActions = {
  [ActionKey.ADD_USER_FILTER]: addUserFilterAction,
  [ActionKey.DELETE_USER_FILTER]: deleteUserFilterAction,
  [ActionKey.SET_USER_FILTERS]: setUserFiltersAction,
  [ActionKey.UPDATE_USER_FILTER]: updateUserFilterAction
}

export const getUserFilters: ActionCreator<UserFiltersThunkAction> = () => {
  return (dispatch: UserFiltersThunkDispatch): Promise<AxiosResponse> => {
    return userFiltersService
      .get()
      .then((resp: AxiosResponse) => {
        if (resp.status === SuccessCode.GET) {
          dispatch(actions[ActionKey.SET_USER_FILTERS](resp.data ?? []))
        }

        return resp
      })
      .catch((err) => Promise.reject(err))
  }
}

export const saveUserFilter: ActionCreator<UserFiltersThunkAction> = (filter: IUserFilter) => {
  return (dispatch: UserFiltersThunkDispatch): Promise<AxiosResponse> => {
    return userFiltersService
      .save(filter)
      .then((resp: AxiosResponse<IUserFilter>) => {
        if (resp.status === 201) {
          dispatch(actions[ActionKey.ADD_USER_FILTER](resp.data))
        }

        return resp
      })
      .catch((err) => Promise.reject(err))
  }
}

export const updateUserFilter: ActionCreator<UserFiltersThunkAction> = (filter: IUserFilter) => {
  return (dispatch: UserFiltersThunkDispatch): Promise<AxiosResponse> => {
    return userFiltersService
      .update(filter)
      .then((resp: AxiosResponse<IUserFilter>) => {
        if (SuccessCode.PUT.includes(resp.status)) {
          dispatch(actions[ActionKey.UPDATE_USER_FILTER](resp.data))
        }

        return resp
      })
      .catch((err) => Promise.reject(err))
  }
}

export const deleteUserFilter: ActionCreator<UserFiltersThunkAction> = (filterOguid: string) => {
  return (dispatch: UserFiltersThunkDispatch): Promise<AxiosResponse> => {
    return userFiltersService
      .delete(filterOguid)
      .then((resp: AxiosResponse) => {
        if (SuccessCode.DELETE.includes(resp.status)) {
          dispatch(actions[ActionKey.DELETE_USER_FILTER](filterOguid))
        }

        return resp
      })
      .catch((err) => Promise.reject(err))
  }
}
