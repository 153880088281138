import { Action } from 'redux'

export enum ModalActionTypes {
  SHOW_MODAL = 'SHOW_MODAL',
  CLOSE_MODAL = 'CLOSE_MODAL'
}

export interface IActionShowModal extends Action<string> {
  type: ModalActionTypes.SHOW_MODAL
  payload: string
}

export interface IActionCloseModal extends Action<string> {
  type: ModalActionTypes.CLOSE_MODAL
}

export type Actions = IActionShowModal | IActionCloseModal

export interface IModalState {
  id: string
}
