import { Action, ActionCreator } from 'redux'
import { AxiosResponse } from 'axios'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { INavItem } from '@const/navSidebar'

import { IApplicationState } from '@store/types/commonTypes'

export enum NavigationActionTypes {
  RESET_NAVIGATION = 'RESET_NAVIGATION',
  RESET_NAVIGATION_AFTER_ORG_CHANGE = 'RESET_NAVIGATION_AFTER_ORG_CHANGE',
  SET_ACTIVE_MENU = 'SET_ACTIVE_MENU',
  SET_BADGES = 'SET_BADGES',
  SET_ALL_BADGES = 'SET_ALL_BADGES',
  SET_BADGES_NOTICES = 'SET_BADGES_NOTICES',
  SET_PREV_MENU = 'SET_PREV_MENU',
  SET_MENU_ITEMS = 'SET_MENU_ITEMS',
  SET_MENU_ITEMS_WITH_FILTERS = 'SET_MENU_ITEMS_WITH_FILTERS',
  SET_TASK_IDX = 'SET_TASK_IDX',
}

export interface IBadges {
  taskDueDatetimeViolationQuantity: number
  taskQuantity: number
  taskUnreadQuantity: number
  todoQuantity: number
}

export interface INoticesBadges {
  needToActionQuantity: number
  toDoQuantity: number
  infoQuantity: number
}

export interface INavigationState {
  activeMenu: string
  allBadges: IBadges
  badges: IBadges
  badgesNotices: INoticesBadges
  menuItems: INavItem[]
  menuItemsWithFilters: INavItem[]
  prevMenu: string
  taskIdx: number
}

export interface IActionResetNavigation extends Action<string> {
  type: NavigationActionTypes.RESET_NAVIGATION
}

export interface IActionResetNavigationOrgChange extends Action<string> {
  type: NavigationActionTypes.RESET_NAVIGATION_AFTER_ORG_CHANGE
}

export interface IActionSetActiveMenu extends Action<string> {
  payload: string
  type: NavigationActionTypes.SET_ACTIVE_MENU
}

export interface IActionSetBadges extends Action<string> {
  payload: IBadges
  type: NavigationActionTypes.SET_BADGES
}

export interface IActionSetAllBadges extends Action<string> {
  payload: IBadges
  type: NavigationActionTypes.SET_ALL_BADGES
}

export interface IActionSetBadgesNotices extends Action<string> {
  payload: INoticesBadges
  type: NavigationActionTypes.SET_BADGES_NOTICES
}

export interface IActionSetMenuItems extends Action<string> {
  payload: INavItem[]
  type: NavigationActionTypes.SET_MENU_ITEMS
}

export interface IActionSetMenuItemsWithFilters extends Action<string> {
  payload: INavItem[]
  type: NavigationActionTypes.SET_MENU_ITEMS_WITH_FILTERS
}

export interface IActionSetPrevMenu extends Action<string> {
  payload: string
  type: NavigationActionTypes.SET_PREV_MENU
}

export interface IActionSetTaskIdx extends Action<string> {
  payload: number
  type: NavigationActionTypes.SET_TASK_IDX
}

export type Actions =
  | IActionResetNavigation
  | IActionResetNavigationOrgChange
  | IActionSetActiveMenu
  | IActionSetAllBadges
  | IActionSetBadges
  | IActionSetBadgesNotices
  | IActionSetMenuItems
  | IActionSetMenuItemsWithFilters
  | IActionSetPrevMenu
  | IActionSetTaskIdx

export enum ActionKey {
  SET_ACTIVE_MENU = 'setActiveMenu',
  SET_BADGES = 'setBadges',
  SET_ALL_BADGES = 'setAllBadges',
  SET_BADGES_NOTICES = 'setBadgesNotices',
  SET_MENU_ITEMS = 'setMenuItems',
  SET_MENU_ITEMS_WITH_FILTERS = 'setMenuItemsWithFilters',
  SET_PREV_MENU = 'setPrevMenu',
  SET_TASK_IDX = 'setTaskIdx',
}

export type NavigationActions = { [key in ActionKey]: ActionCreator<Action<string>> }

export type NavigationThunkAction = ThunkAction<
  Promise<AxiosResponse | AxiosResponse[]>,
  IApplicationState,
  void,
  Action<string>
>

export type NavigationThunkDispatch = ThunkDispatch<INavigationState, void, Action<string>>
