import { Actions, DocumentActionTypes, DocumentMode, IDocumentState } from '@store/modules/document/types'

export const initialState: IDocumentState = {
  mode: DocumentMode.VIEW
}

export default function documentsReducer(state: IDocumentState = initialState, action: Actions): IDocumentState {
  switch (action.type) {
    case DocumentActionTypes.SET_MODE:
      return {
        ...state,
        mode: action.payload
      }

    default:
      return state
  }
}
