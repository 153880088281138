import { IDocumentFields } from '@store/modules/documents/types'
import { IContractorField } from '@store/types/commonTypes'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { AxiosResponse } from 'axios'

export interface IReceiptsState {
  data: IReceipt[]
}

export interface IReceipt {
  receiptType: Receipt
  messageOguid: string
  documentType: string
  documentOguid: string
  direction: Direction
  attachmentOguid: string
  timestamp: number
  proxyOrg: IContractorField
  isProxy: boolean
  fields: IDocumentFields
}

type Receipt =
  | 'RECIPIENT_CONFIRMATION'
  | 'RECIPIENT_CONFIRMATION_OPERATOR'
  | 'OPERATOR_CONFIRMATION'
  | 'CORRECTION_REQUEST'

type Direction = 'INCOMING' | 'OUTGOING' | 'INTERNAL'

export enum ReceiptsActionTypes {
  GET_RECEIPTS = 'GET_RECEIPTS',
  RESET_RECEIPTS = 'RESET_RECEIPTS'
}

export interface IActionGetReceipts extends Action<string> {
  payload: IReceipt[]
  type: ReceiptsActionTypes.GET_RECEIPTS
}

export interface IActionResetReceipts extends Action<string> {
  type: ReceiptsActionTypes.RESET_RECEIPTS
}

export type Actions = IActionGetReceipts | IActionResetReceipts

type ActionKeys = 'getReceipts'

export type ReceiptsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type ReceiptsThunkAction = ThunkAction<Promise<AxiosResponse>, IReceiptsState, void, Action<string>>

export type ReceiptsThunkDispatch = ThunkDispatch<IReceiptsState, void, Action<string>>
