import { v4 as uuidv4 } from 'uuid'

import {
  ILibIcon,
  Theme
} from '@infologistics/frontend-libraries'

import { AllRoles, DefaultSubMenuItem, Role, RouteName, Section } from '@const/consts'
import { Menu } from './translations'
import { IconList } from "@infologistics/frontend-libraries/dist/components/Icon/types"

const iconProps: ILibIcon = {
  externalClass: 'mr-2'
}

export interface INavItemBadge {
  name: string
  theme?: Theme
}

export interface IRoleModel {
  roles: string[]
  isFlowFunctionalityEnabled?: boolean
  isExchangeParticipant?: boolean
}

export interface INavItem extends IRoleModel {
  badge?: INavItemBadge
  icon?: IconList
  iconProps?: ILibIcon
  route: string
  title: string
  uuid: string
  items?: INavItem[]
}

const documents: INavItem[] = [
  {
    route: `${RouteName.DOCUMENTS}/${DefaultSubMenuItem.IN_PROGRESS}`,
    title: Menu.tasks,
    uuid: uuidv4(),
    isFlowFunctionalityEnabled: true,
    roles: AllRoles,
    badge: {
      name: 'taskQuantity',
      theme: 'brand'
    }
  },
  {
    route: `${RouteName.DOCUMENTS}/${DefaultSubMenuItem.ALL}`,
    title: Menu.all,
    uuid: uuidv4(),
    roles: AllRoles
  }
]

const messages: INavItem[] = [
  {
    route: `${RouteName.MESSAGES}/${DefaultSubMenuItem.RECEIPTS}`,
    title: Menu.receipts,
    uuid: uuidv4(),
    roles: AllRoles,
    isExchangeParticipant: true
  },
  {
    route: `${RouteName.MESSAGES}/${DefaultSubMenuItem.INCOMING}`,
    title: Menu.incoming,
    uuid: uuidv4(),
    roles: AllRoles,
    isExchangeParticipant: true
  },
  {
    route: `${RouteName.MESSAGES}/${DefaultSubMenuItem.OUTGOING}`,
    title: Menu.outgoing,
    uuid: uuidv4(),
    roles: AllRoles,
    isExchangeParticipant: true
  }
]

const settings: INavItem[] = [
  {
    route: Section.REQUISITES,
    title: Menu.requisites,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR]
  },
  {
    route: Section.FIELDS,
    title: Menu.fields,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR]
  },
  {
    route: Section.DOCUMENT_TYPES,
    title: Menu.documentTypes,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR]
  },
  {
    route: Section.DICTIONARIES,
    title: Menu.dictionaries,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR]
  },
  {
    route: Section.ROUTES,
    title: Menu.routes,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR],
    isFlowFunctionalityEnabled: true
  },
  {
    route: Section.DIVISIONS,
    title: Menu.divisions,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR]
  },
  {
    route: Section.CONSTRAINTS,
    title: Menu.constraints,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR]
  },
  {
    route: Section.ACCESS_GROUPS,
    title: Menu.accessGroups,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR]
  }
]

const administration: INavItem[] = [
  {
    route: Section.USERS,
    title: Menu.users,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR]
  },
  {
    route: Section.GROUPS,
    title: Menu.groups,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR]
  }
]

const menu: INavItem[] = [
  {
    icon: 'IconFolderSolid',
    iconProps: iconProps,
    route: RouteName.DOCUMENTS,
    title: Menu.documents,
    uuid: uuidv4(),
    roles: AllRoles,
    items: documents
  },
  {
    icon: 'IconEnvelopSolid',
    iconProps: iconProps,
    route: RouteName.MESSAGES,
    title: Menu.exchange,
    uuid: uuidv4(),
    roles: AllRoles,
    isExchangeParticipant: true,
    items: messages
  },
  {
    icon: 'IconBriefcase',
    iconProps: iconProps,
    route: RouteName.CONTRACTORS,
    title: Menu.contractors,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR, Role.ORGANIZATION]
  },
  {
    icon: 'IconCog',
    iconProps: iconProps,
    route: RouteName.SETTINGS,
    title: Menu.settings,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR],
    items: settings
  },
  {
    icon: 'IconUsers',
    iconProps: iconProps,
    route: RouteName.ADMINISTRATION,
    title: Menu.administration,
    uuid: uuidv4(),
    roles: [Role.ADMINISTRATOR],
    items: administration
  }
]

const documentsRootMode: INavItem[] = [
  {
    route: `${RouteName.DOCUMENTS}/${DefaultSubMenuItem.IN_PROGRESS}`,
    title: Menu.tasks,
    uuid: uuidv4(),
    isFlowFunctionalityEnabled: true,
    roles: AllRoles,
    badge: {
      name: 'taskQuantity',
      theme: 'brand'
    }
  }
]

export const menuRootMode: INavItem[] = [
  {
    icon: 'IconFolderSolid',
    iconProps: iconProps,
    route: RouteName.DOCUMENTS,
    title: Menu.documents,
    uuid: uuidv4(),
    roles: AllRoles,
    items: documentsRootMode
  },
  {
    icon: 'IconProjectsOrganization',
    iconProps: iconProps,
    route: RouteName.ORGS,
    title: Menu.orgs,
    uuid: uuidv4(),
    roles: AllRoles
  }
]

// TODO: When role model appears - add it to default export
export default menu

export const NAV_ANIMATION_DELAY = 100

export const ASIDE_WIDTH_SET_DELAY = 100
