import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { ILibDetailedTableColumn, LibTableMode } from '@infologistics/frontend-libraries'
import { IApplicationState, IUserOrganization, Nullable } from '@store/types/commonTypes'
import { IExtendedSigner } from '@store/modules/users/types'
import { ISelectedCertificate } from '@views/user/Profile/modals/CertificateSettings/types'
import { NavMode } from '@const/consts'
import React from 'react'
import { IFederationName } from '@app/services/federations/types'

export enum UserActionTypes {
  SET_ACTIVE_ORGANIZATION = 'SET_ACTIVE_ORGANIZATION',
  SET_PROFILE = 'SET_PROFILE',
  SET_USER_ORGANIZATIONS = 'SET_USER_ORGANIZATIONS',
  SET_USER_EXTENDED_INFO = 'SET_USER_EXTENDED_INFO',
  SET_EXCHANGE_PARTICIPANT = 'SET_EXCHANGE_PARTICIPANT',
  SET_FLOW_FUNCTIONALITY = 'SET_FLOW_FUNCTIONALITY',
  SET_USER_SETTINGS = 'SET_USER_SETTINGS',
  SET_PHONE_AUTH_ID = 'SET_PHONE_AUTH_ID',
  IS_SHOW_PHONE_INPUT = 'IS_SHOW_PHONE_INPUT',
  SET_LOGO_ORG = 'SET_LOGO_ORG',
  SET_SSO_LOGOUT = 'SET_SSO_LOGOUT'
}

export interface IProfile {
  phone: Nullable<string>
  email: Nullable<string>
  login: Nullable<string>
  name: string
  oguid: string
  orgs: IUserOrganization[]
  patronymic: Nullable<string>
  surname: string
  locale: string
  timeZone: string
  isPhoneConfirmed: boolean
}

export type IProfileStateUpdateFunc = React.Dispatch<React.SetStateAction<boolean>>

export interface IUserState {
  phoneAuthId: string
  activeOrganization: IUserOrganization
  extendedSigner: Nullable<IExtendedSigner>
  isReadonly: boolean
  isExchangeParticipant: boolean
  isFlowFunctionalityEnabled: boolean
  profile: IProfile
  role: string
  position: Nullable<string>
  settings: Nullable<IUserSettings>
  isShowPhoneInput: boolean
  logoOrg: Nullable<string>
  federation: Nullable<IFederationName>
  ssoLogout: boolean
}

export interface IUserSettings {
  orgs?: IUserSettingsOrgs
  navMode?: NavMode
  rootMode?: boolean
  asideWidth?: string
  selectedCertificates?: ISelectedCertificate[]
}

interface ITableSettings {
  columns?: ILibDetailedTableColumn[]
  mode?: LibTableMode
}

export interface IUserSettingsOrgs {
  [key: string]: {
    tableSettings?: {
      documents?: {
        [key: string]: ITableSettings
      }
      tasks?: ITableSettings
    },
    isLast?: boolean
  }
}

export interface IUserExtendedInfo {
  extendedSigner: Nullable<IExtendedSigner>
  isExchangeParticipant?: boolean
  isFlowFunctionalityEnabled?: boolean
  isReadonly: boolean
  role: string
  position: Nullable<string>
}

// updating user profile
export interface IUpdatedProfile {
  email: Nullable<string>
  login: Nullable<string>
  name: string
  patronymic: Nullable<string>
  phone: Nullable<string>
  surname: string
  password: Nullable<string>
  locale: string
  timeZone: string
  phoneCheckTranID?: Nullable<string>
}

// TODO: check the types for the organization
export interface IOrganization {
  oguid: string
  inn: Nullable<string>
  kpp: Nullable<string>
  ogrn: Nullable<string>
  name: string
  nameShort: string
  nameFio?: Nullable<string>
  legalType?: Nullable<ILegalType>
  alias: string
  fnsParticipantCode: string
  ifnsCode: Nullable<string>
  isFlowFunctionalityEnabled: boolean
  isExchangeParticipant: boolean
  isForeign: Nullable<boolean>
  isRoaming: Nullable<boolean>
  isAppliedToTaxOffice?: Nullable<boolean>
  operatorCode: Nullable<boolean>
  sociability: Nullable<ISociability>
  liquidationDate: Nullable<number>
  certificateOfRegistryInfo: Nullable<string>
  addrText?: Nullable<string>
  foreignCountry: Nullable<string>
  foreignAddress: Nullable<string>
}

export interface IOrganizationDelete {
  isNoOtherOrgs: boolean
}

export interface IRequisites {
  alias: string
  foreignAddress: Nullable<string>
  foreignCountry: Nullable<string>
  isFlowFunctionalityEnabled: boolean
  nameShort: string
  name: string
  // enum [ all_organizations, contractors_only ], not used yet
  sociability: Nullable<string>
}

export type ILegalType = 'legal' | 'individual'

type ISociability = 'all_organizations' | 'contractors_only'

export interface IToken {
  value: string
  federation: Nullable<string>
}

export interface ITokens {
  accessToken: Nullable<string>
  refreshToken: Nullable<string>
}

export interface ITokensFromAuth {
  status: number
  access: IToken
  file: IToken
  refresh: IToken
  tranId?: string
  expired?: number
}

export interface ITokenToRefresh {
  value: string
  generateNewToken: boolean
}

export interface ITokenTabs {
  [key: string]: boolean
}

export interface IPhoneAuth {
  phone: number
}

export interface IPhoneVerify {
  id: string
}

export interface IPhoneAuthConfirm {
  id: string
  code: string
}

export interface IFactorAuthConfirm {
  tranId: Nullable<string>
  code: string
}

export interface IEmailPasswordAuth {
  email: string
  password: string
}

export interface ILoginReset {
  login: Nullable<string>
  email: Nullable<string>
}

export interface ISignup {
  surname: string
  name: string
  patronymic: Nullable<string>
  login: string
  email: string
  contacts: string
  orgName: string
  agree: boolean
  app: string
  code: string
}

export interface IActionSetProfile extends Action<string> {
  payload: IProfile
  type: UserActionTypes.SET_PROFILE
}

export interface IActionSetUserOrganizations extends Action<string> {
  payload: IUserOrganization[]
  type: UserActionTypes.SET_USER_ORGANIZATIONS
}

export interface IActionSetActiveOrganization extends Action<string> {
  payload: IUserOrganization
  type: UserActionTypes.SET_ACTIVE_ORGANIZATION
}

export interface IActionSetUserExtendedInfo extends Action<string> {
  payload: IUserExtendedInfo
  type: UserActionTypes.SET_USER_EXTENDED_INFO
}

export interface IActionSetExchangeParticipant extends Action<string> {
  payload: boolean
  type: UserActionTypes.SET_EXCHANGE_PARTICIPANT
}

export interface IActionSetFlowFunctionality extends Action<string> {
  payload: boolean
  type: UserActionTypes.SET_FLOW_FUNCTIONALITY
}

export interface IActionSetUserSettings extends Action<string> {
  payload: IUserSettings
  type: UserActionTypes.SET_USER_SETTINGS
}

export interface IActionSetPhoneAuthId extends Action<string> {
  payload: string
  type: UserActionTypes.SET_PHONE_AUTH_ID
}

export interface IActionIsShowPhoneInput extends Action<string> {
  payload: boolean
  type: UserActionTypes.IS_SHOW_PHONE_INPUT
}

export interface IActionSetLogoOrg extends Action<string> {
  payload: string
  type: UserActionTypes.SET_LOGO_ORG
}

export interface IActionSetSsoLogout extends Action<string> {
  payload: boolean
  type: UserActionTypes.SET_SSO_LOGOUT
}

export type Actions =
  | IActionSetProfile
  | IActionSetUserOrganizations
  | IActionSetActiveOrganization
  | IActionSetUserExtendedInfo
  | IActionSetExchangeParticipant
  | IActionSetFlowFunctionality
  | IActionSetUserSettings
  | IActionSetPhoneAuthId
  | IActionIsShowPhoneInput
  | IActionSetLogoOrg
  | IActionSetSsoLogout

type ActionKeys =
  | 'setActiveOrganization'
  | 'setProfile'
  | 'setUserExtendedInfo'
  | 'setUserOrganizations'
  | 'setExchangeParticipant'
  | 'setFlowFunctionality'
  | 'setUserSettings'
  | 'setPhoneAuthId'
  | 'setIsShowPhoneInput'
  | 'setLogoOrg'
  | 'setSsoLogout'

export type UserActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type UserThunkAction = ThunkAction<void, IApplicationState, void, Action<string>>

export type UserThunkDispatch = ThunkDispatch<IUserState, void, Action<string>>

export type SyncThunk = ActionCreator<UserThunkAction>
