import { IDocument, IDocumentSignInfo, IFlowState } from '@store/modules/documents/types'
import { IPreProcessingJobStatus } from '../metadata/types'

export const emptySignInfo: IDocumentSignInfo = {
  signatureCheckStatus: 'NOT_ACCEPTABLE',
  signatureCheckTimestamp: null,
  signatureName: '',
  signatureSurname: '',
  signaturePatronymic: null,
  signatureRegistrationCertificate: '',
  signatureSignerOrganizationName: '',
  certificateDateFrom: null,
  certificateDateTo: null,
  signatureTimestamp: null,
  issueOrganization: '',
  signatureId: ''
}

export const document: IDocument = {
  allowedActions: [],
  comment: null,
  constraintViolations: [],
  details: [],
  documentId: 0,
  documentTimestamp: 0,
  documentAttachmentOguid: '',
  documentVersions: [],
  oguid: '',
  initiator: {
    oguid: '',
    name: '',
    surname: '',
    patronymic: '',
    position: '',
    email: ''
  },
  type: '',
  contractorOrg: null,
  creatorOguid: null,
  subOrg: {
    oguid: '',
    name: ''
  },
  externalId: null,
  fields: {},
  flowCompletedTimestamp: 0,
  flowState: 'NOT_STARTED',
  flowResult: 'SOLVED',
  flowStageType: 'APPROVAL',
  flowStageUserName: null,
  flowStartTimestamp: 0,
  isExternal: true,
  direction: 'INCOMING',
  isWorkflowFinished: false,
  workflowStatuses: [],
  isReadOnly: false,
  readOnlyFields: [],
  history: [],
  preProcessingStatus: IPreProcessingJobStatus.NOT_APPLICABLE,
  preProcessingInfo: null
}

export const flowState: IFlowState = {
    processOguid: '',
    routeOguid: '',
    stages: []
  }
