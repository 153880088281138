import React, { ReactElement } from 'react'

import { withTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'

import { LinkContent } from '@infologistics/frontend-libraries'

import { createPath, getBadgeValue, getOrganizationInfo, transformPathToString } from '@utils/utils'

import { ASIDE_WIDTH_INIT } from '@const/consts'

import { INavMenuItemProps as IProps } from './types'

export class NavMenuItem extends React.Component<IProps> {
  public path = ''

  public componentDidMount(): void {
    this.setRoute()
  }

  public componentDidUpdate(): void {
    this.setRoute()
  }

  public render(): ReactElement {
    const {
      activeMenu,
      alias,
      item,
      location,
      oguid,
      t,
      isDisabled,
      isRootMode
    } = this.props
    const { title, route } = item

    const organization = getOrganizationInfo(alias, oguid)

    const newTo: string = transformPathToString(route)

    const activeMenuSource = activeMenu || location.pathname
    const toPath = isRootMode ? this.path : createPath(organization, this.path)

    return <NavLink
      isActive={() => activeMenuSource.includes(newTo)}
      title={t(title)}
      tabIndex={isDisabled ? -1 : undefined}
      to={toPath}
    >
      {this.renderLinkContent()}
    </NavLink>
  }

  private readonly setRoute = (): void => {
    const { items, route } = this.props.item

    this.path = items?.[0]?.route ?? route
  }

  private readonly renderLinkContent = (): ReactElement => {
    const {
      asideWidth = ASIDE_WIDTH_INIT,
      allBadges,
      badges,
      isRootMode,
      t,
      item: { icon, title, badge },
      isHorizontalNavMode
    } = this.props

    const badgesQuantity = isRootMode ? allBadges : badges
    const badgeProps = badge && {
      theme: badge.theme ?? 'default',
      value: getBadgeValue(badgesQuantity[badge.name])
    }

    return <LinkContent
      title={t(title)}
      titleMaxWidth={`calc(${asideWidth} - 44px)`}
      iconBefore={icon}
      iconBeforeProps={{ classes: 'mr-2' }}
      badgeProps={badgeProps}
      isHorizontalNavMode={isHorizontalNavMode}
    />
  }

}

export default withTranslation()(withRouter(NavMenuItem))
