import React, { FC, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'

import usersService from '@services/users'
import {
  ILibData,
  ILibGroup,
  ILibPresetValue,
  ILibSuggestRecipientProps,
  ILibUser,
  SuggestRecipient
} from '@infologistics/frontend-libraries'
import { displayErrorNotification } from '@utils/utils'
import { presetValues, FilterName } from '@const/consts'

import {
  ISuggestRecipientWrapperParams as IParams,
  ISuggestRecipientWrapperProps as IProps
} from './types'
import { PresetValuesLabels } from '@app/const/translations'

const SuggestRecipientWrapper: FC<IProps> = (props) => {
  const {
    classes,
    defaultGroup = null,
    defaultUser = null,
    defaultPresetValue = null,
    documentOguid,
    errorText,
    hasError,
    inputRef,
    isDisabled,
    isEnabledPresetValues,
    leftIcon,
    onFocus,
    onReset,
    onSelectPresetValue,
    onSelectUser,
    placeholder = ''
  } = props

  const [data, setData] = useState<ILibData>({})
  const [isEmpty, setIsEmpty] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { t } = useTranslation('recipient')

  useEffect(() => {
    if (defaultUser && documentOguid) {
      getData(true)()
    }
  },[])

  const resetState = (): void => {
    setData({})
    setIsEmpty(false)
    setIsLoading(false)
  }

  const getSuggestProps = (): ILibSuggestRecipientProps => {
    const labels = {
      empty: t('recipient:empty'),
      groups: t('recipient:groups'),
      placeholder: placeholder || t('recipient:placeholder'),
      users: t('recipient:users'),
      usersOrGroups: t('recipient:usersOrGroups'),
      searchHint: t('recipient:searchHint')
    }
    const presetValuesArray = Object.keys(presetValues).map(role =>
      ({ name: t(PresetValuesLabels[presetValues[role]]), value: role }))

    const suggestProps: ILibSuggestRecipientProps = {
      classes,
      data,
      defaultGroup,
      defaultUser,
      getData: getData(false),
      isEmpty,
      isDisabled,
      isLoading,
      labels,
      leftIcon: leftIcon,
      onFocus: handleFocus,
      onReset: handleReset,
      onSelectGroup: handleSelectGroup,
      onSelectUser: handleSelectUser,
      onSelectPresetValue: handleSelectPresetValue
    }

    if (documentOguid) {
      suggestProps.getDataWithDocument = getData(true)
    }

    if (errorText) {
      suggestProps.errorText = errorText
    }

    if (hasError) {
      suggestProps.hasError = hasError
    }

    if (inputRef) {
      suggestProps.inputRef = inputRef
    }

    if (defaultPresetValue) {
      suggestProps.defaultPresetValue = defaultPresetValue
    }

    if (isEnabledPresetValues) {
      suggestProps.presetValues = presetValuesArray
    }

    return suggestProps
  }

  const handleSelectGroup = (group: ILibGroup | ILibGroup[]): void => {
    if (Array.isArray(group)) return
    props.onSelectGroup?.(group)
    resetState()
  }

  const handleSelectUser = (user: ILibUser | ILibUser[]): void => {
    if (Array.isArray(user)) return
    onSelectUser?.(user)

    if (!props.documentOguid) {
      resetState()
    }
  }

  const handleSelectPresetValue = (presetValue: ILibPresetValue | ILibPresetValue[]): void => {
    if (Array.isArray(presetValue)) return
    onSelectPresetValue?.(presetValue)

    if (!props.documentOguid) {
      resetState()
    }
  }

  const handleFocus = (): void => {
    onFocus?.()
  }

  const handleReset = (): void => {
    onReset?.()
  }

  const getData = (with_document: boolean) => (value?: string) => {
    const { documentOguid, showMode, searchOrgOguid } = props

    const params: IParams = {
      [FilterName.SHOW_MODE]: showMode
    }

    if (value) {
      params[FilterName.QUERY_LIKE] = value
    }

    if (with_document) {
      params.documentOguid = documentOguid
    }

    setIsLoading(true)

    usersService.getRecipients(params, searchOrgOguid)
      .then((resp) => {
        const { groups = [], users = [] } = resp.data

        setData(resp.data)
        setIsEmpty(!groups.length && !users.length)
        setIsLoading(false)
      })
      .catch(handleCatchError)
  }

  const handleCatchError = (err: AxiosError): void => {
    setIsLoading(false)
    displayErrorNotification(err)
  }

  return (
    <SuggestRecipient {...getSuggestProps()} />
  )
}

export default SuggestRecipientWrapper
