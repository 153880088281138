import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import CreateDocumentLinks from './CreateDocumentLinks'

import { IApplicationState } from '@store/types/commonTypes'
import { ICreateDocumentLinksPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isExchangeParticipant: state.user.isExchangeParticipant,
  isReadonly: state.user.isReadonly,
  role: state.user.role,
  orgs: state.user.profile.orgs
})

export default withRouter(connect(mapStateToProps)(CreateDocumentLinks))
