import { Action } from 'redux'

import { ILibPagination } from '@infologistics/frontend-libraries'

import { Nullable } from '@store/types/commonTypes'

// actions

export enum PaginationActionTypes {
  RESET_PAGINATION = 'RESET_PAGINATION',
  SET_PAGINATION = 'SET_PAGINATION'
}

export type IPaginationState = Nullable<ILibPagination>

export interface IActionResetPagination extends Action<string> {
  type: PaginationActionTypes.RESET_PAGINATION
}

export interface IActionSetPagination extends Action<string> {
  payload: ILibPagination
  type: PaginationActionTypes.SET_PAGINATION
}

export type Actions = IActionResetPagination | IActionSetPagination
