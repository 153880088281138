import { connect } from 'react-redux'

import { Menu } from '@common/Layout'

import { IApplicationState } from '@store/types/commonTypes'
import { IMenuPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isShownMenus: state.utils.isShownMenus,
  isShownNavMenu: state.utils.isShownNavMenu
})

export default connect(mapStateToProps)(Menu)
