import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import { ResponseCode } from '@const/consts'
import metadataService from '@services/metadata'

import { AsyncThunk, SyncThunk } from '@store/types/commonTypes'
import {
  IAccessGroup,
  IActionResetMetadata,
  IActionSetAccessGroups,
  IActionSetDictTypes,
  IActionSetDocumentTypeGroups,
  IActionSetDocumentTypes,
  IActionSetFields,
  IActionSetFlowStageNames,
  IActionSetFlowStageTypes,
  IActionSetHistoryStateCodes,
  IActionSetLocalizations,
  IActionSetUserDocTypes,
  IActionSetWorkflowStatuses,
  IDocumentTypeGroup,
  IDocumentTypesFromServer,
  IField,
  IFlowStageType,
  IHistoryStateCodes,
  IUserDocumentTypesFromServer,
  IWorkflowStatuses,
  MetadataActions,
  MetadataActionTypes,
  MetadataThunkDispatch
} from './types'

// actions
export const resetMetadata: IActionResetMetadata = {
  type: MetadataActionTypes.RESET_METADATA
}

const setAccessGroupsAction: ActionCreator<Action> = (groups: IAccessGroup[]): IActionSetAccessGroups => ({
  payload: groups,
  type: MetadataActionTypes.SET_ACCESS_GROUPS
})

const setDictTypesAction: ActionCreator<Action> = (dictTypes: string[]): IActionSetDictTypes => ({
  payload: dictTypes,
  type: MetadataActionTypes.SET_DICT_TYPES
})

const setDocumentTypesAction: ActionCreator<Action> = (documentTypes: IDocumentTypesFromServer): IActionSetDocumentTypes => ({
  payload: documentTypes,
  type: MetadataActionTypes.SET_DOCUMENT_TYPES
})

const setDocumentTypeGroupsAction: ActionCreator<Action> = (
  documentTypeGroups: IDocumentTypeGroup[]
): IActionSetDocumentTypeGroups => ({
  payload: documentTypeGroups,
  type: MetadataActionTypes.SET_DOCUMENT_TYPE_GROUPS
})

const setFieldsAction: ActionCreator<Action> = (fields: IField[]): IActionSetFields => ({
  payload: fields,
  type: MetadataActionTypes.SET_FIELDS
})

const setFlowStageNamesAction: ActionCreator<Action> = (flowStageNames: string[]): IActionSetFlowStageNames => ({
  payload: flowStageNames,
  type: MetadataActionTypes.SET_FLOW_STAGE_NAMES
})

const setFlowStageTypesAction: ActionCreator<Action> = (
  flowStageTypes: IFlowStageType[]
): IActionSetFlowStageTypes => ({
  payload: flowStageTypes,
  type: MetadataActionTypes.SET_FLOW_STAGE_TYPES
})

const setHistoryStateCodesAction: ActionCreator<Action> = (
  historyStateCodes: IHistoryStateCodes
): IActionSetHistoryStateCodes => ({
  payload: historyStateCodes,
  type: MetadataActionTypes.SET_HISTORY_STATE_CODES
})

const setLocalizationsAction: ActionCreator<Action> = (localizations: string[]): IActionSetLocalizations => ({
  payload: localizations,
  type: MetadataActionTypes.SET_LOCALIZATIONS
})

const setUserDocTypesAction: ActionCreator<Action> = (userDocTypes: IUserDocumentTypesFromServer): IActionSetUserDocTypes => ({
  payload: userDocTypes,
  type: MetadataActionTypes.SET_USER_DOC_TYPES
})

const setWorkflowStatusesAction: ActionCreator<Action> = (statuses: IWorkflowStatuses): IActionSetWorkflowStatuses => ({
  payload: statuses,
  type: MetadataActionTypes.SET_WORKFLOW_STATUSES
})

export const actions: MetadataActions = {
  setAccessGroups: setAccessGroupsAction,
  setDictTypes: setDictTypesAction,
  setDocumentTypes: setDocumentTypesAction,
  setDocumentTypeGroups: setDocumentTypeGroupsAction,
  setFields: setFieldsAction,
  setFlowStageNames: setFlowStageNamesAction,
  setFlowStageTypes: setFlowStageTypesAction,
  setHistoryStateCodes: setHistoryStateCodesAction,
  setLocalizations: setLocalizationsAction,
  setUserDocTypes: setUserDocTypesAction,
  setWorkflowStatuses: setWorkflowStatusesAction
}

// thunks
export const getFields: AsyncThunk = () => (dispatch: MetadataThunkDispatch): Promise<AxiosResponse> =>
  metadataService.getFields().then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(setFieldsAction(resp.data ?? []))
    }

    return resp
  })

export const deleteField: AsyncThunk = (fieldKey: string) => (
  dispatch: MetadataThunkDispatch,
  getState
): Promise<AxiosResponse> =>
  metadataService.deleteField(fieldKey).then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.DEL) {
      const { fields } = getState().metadata
      const filteredFields = fields.filter((field) => field.key !== fieldKey)

      dispatch(setFieldsAction(filteredFields))
    }

    return resp
  })

export const getDocumentTypes: AsyncThunk = () => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getDocumentTypes()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(setDocumentTypesAction(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const deleteDocumentType: AsyncThunk = (documentTypeKey: string) => (
  dispatch: MetadataThunkDispatch,
  getState
): Promise<AxiosResponse> =>
  metadataService.deleteDocumentType(documentTypeKey).then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.DEL) {
      const { documentTypes } = getState().metadata
      delete documentTypes[documentTypeKey]

      dispatch(setDocumentTypesAction(documentTypes))
    }

    return resp
  })

export const getDocumentTypeGroups: AsyncThunk = () => (dispatch: MetadataThunkDispatch): Promise<AxiosResponse> =>
  metadataService.getDocTypeGroups()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(setDocumentTypeGroupsAction(resp.data ?? []))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const getDictTypes: AsyncThunk = () => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getDictTypes()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(setDictTypesAction(resp.data ?? []))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const getWorkflowStatuses: AsyncThunk = () => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getWorkflowStatuses()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(setWorkflowStatusesAction(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const addLocalization: SyncThunk = (language: string) => (dispatch: MetadataThunkDispatch, getState): void => {
  const { localizations } = getState().metadata

  const updatedLocalizations = new Set(localizations)
  updatedLocalizations.add(language)

  dispatch(setLocalizationsAction([...updatedLocalizations]))
}

export const deleteLocalization: SyncThunk = (language: string) => (
  dispatch: MetadataThunkDispatch,
  getState
): void => {
  const { localizations } = getState().metadata
  const filteredLocalizations = localizations.filter((localization) => language !== localization)

  dispatch(setLocalizationsAction(filteredLocalizations))
}

export const resetLocalizations: SyncThunk = () => (dispatch: MetadataThunkDispatch): void => {
  dispatch(setLocalizationsAction([]))
}

export const getFlowStageNames: AsyncThunk = () => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getFlowStageNames().then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(setFlowStageNamesAction(resp.data ?? []))
    }

    return resp
  })

export const getFlowStageTypes: AsyncThunk = () => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getFlowStageTypes().then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(setFlowStageTypesAction(resp.data ?? []))
    }

    return resp
  })

export const getHistoryStateCodes: AsyncThunk = () => (
  dispatch: MetadataThunkDispatch
): Promise<AxiosResponse> =>
  metadataService.getHistoryStateCodes()
    .then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      dispatch(setHistoryStateCodesAction(resp.data))
    }

    return resp
  })

export const getAccessGroups: AsyncThunk = () => (dispatch: MetadataThunkDispatch): Promise<AxiosResponse> =>
  metadataService.getAccessGroups()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(setAccessGroupsAction(resp.data ?? []))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const getUserDocTypes: AsyncThunk = () => (dispatch: MetadataThunkDispatch): Promise<AxiosResponse> =>
  metadataService.getUserDocTypes()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(setUserDocTypesAction(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))
