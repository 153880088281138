import Loadable from 'react-loadable'

import { SpinnerWrapper } from '@containers/Navigation'
import { Path } from '@const/consts'
import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'

const Signin = Loadable({
  loader: () => import('@views/auth/components/Signin'),
  loading: SpinnerWrapper
})

const Register = Loadable({
  loader: () => import('@views/auth/components/Register'),
  loading: SpinnerWrapper
})

const Forgot = Loadable({
  loader: () => import('@views/auth/components/Forgot'),
  loading: SpinnerWrapper
})

const SSO = Loadable({
  loader: () => import('@views/auth/components/SSO'),
  loading: SpinnerWrapper
})


export const routes: IRoute[] = [
  {
    component: Signin,
    exact: true,
    name: 'Signin',
    path: Path.SIGN_IN
  },
  {
    component: Forgot,
    exact: true,
    name: 'Forgot',
    path: Path.FORGOT
  },
  {
    component: Register,
    exact: true,
    name: 'Register',
    path: Path.SIGN_UP
  },
  {
    component: SSO,
    exact: true,
    name: 'SSO',
    path: Path.SSO_AUTH
  }
]
