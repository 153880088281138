import React, { FC, ReactNode, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { ActionButton } from '@infologistics/frontend-libraries'

import { IgnoredClass } from '@const/consts'
import { displayErrorNotification } from '@utils/utils'
import { IApplicationState } from '@store/types/commonTypes'
import { toggleMessages, toggleNotices } from '@store/modules/utils/actions'
import { getBadgesNotices } from '@store/modules/navigation/actions'
import Messages from '@containers/Messages/Messages'
import Notices from '@common/Notices/Notices'

import { ISideWindowButtonProps as IProps } from './types'

import styles from './SideWindowButton.module.css'

const classNames = {
  enter: styles.enter,
  enterActive: styles.enterActive,
  exit: styles.exit,
  exitActive: styles.exitActive
}

const TIMEOUT = 300

const SideWindowButton: FC<IProps> = ({ isMessages = true }) => {
  const { t } = useTranslation(['common', 'user'])

  const dispatch = useDispatch<any>()
  const { areMessagesShown, areNoticesShown } = useSelector((state: IApplicationState) => state.utils)
  const { allBadges, badgesNotices } = useSelector((state: IApplicationState) => state.navigation)

  const { todoQuantity } = allBadges
  const { needToActionQuantity, infoQuantity } = badgesNotices

  const isShown = isMessages ? areMessagesShown : areNoticesShown
  const btnRef = useRef<HTMLDivElement>(null)

  const renderMessages = (): ReactNode => {
    // outsideClickIgnoreClass игнорирует клик по элементу с классом messages_toggle т.е. по ActionButton выше
    return (
      <TransitionGroup component={null}>
        {isShown && (
          <CSSTransition in={areMessagesShown} unmountOnExit={true} timeout={TIMEOUT} classNames={classNames}>
            {isMessages
              ? <Messages outsideClickIgnoreClass={IgnoredClass.MESSAGES} />
              : <Notices sideWindowBtnRef={btnRef} />
            }
          </CSSTransition>
        )}
      </TransitionGroup>
    )
  }

  const getCounter = (): number | undefined => {
    const todos = isMessages ? todoQuantity : (needToActionQuantity + infoQuantity)

    return todos > 0 ? todos : undefined
  }

  const toggleSideWindow = (): void => {
    if (!isMessages) {
      dispatch(toggleNotices(!areNoticesShown))
      dispatch(getBadgesNotices()).catch(displayErrorNotification)
    } else dispatch(toggleMessages(!areMessagesShown))
  }

  return (
    <div className={isMessages ? 'mr-5' : 'mr-4'} ref={btnRef}>
      <ActionButton
        title={isMessages ? t('common:messages') : t('user:notifications')}
        icon={isMessages ? 'IconMessageFill' : 'IconBell'}
        onClick={toggleSideWindow}
        counter={getCounter()}
        counterTheme={!isMessages && needToActionQuantity === 0 ? 'default' : 'danger'}
        externalClass={IgnoredClass.MESSAGES}
      />
      {renderMessages()}
    </div>
  )
}

export default SideWindowButton
