import { Action, ActionCreator } from 'redux'

import { ILibPagination } from '@infologistics/frontend-libraries'

import { IActionResetPagination, IActionSetPagination, PaginationActionTypes } from './types'

export const resetPagination: IActionResetPagination = {
  type: PaginationActionTypes.RESET_PAGINATION
}

export const setPagination: ActionCreator<Action> = (pagination: ILibPagination): IActionSetPagination => ({
  payload: pagination,
  type: PaginationActionTypes.SET_PAGINATION
})
