import { Action, ActionCreator } from 'redux'
import { AxiosResponse } from 'axios'
import { cloneDeep } from 'lodash'

import flowsService from '@services/flows'

import { ResponseCode, temporaryPostResponseCode } from '@const/consts'

import { AsyncThunk, IParams, SyncThunk } from '@store/types/commonTypes'
import { IMessage, IResponse, IResponses } from '@store/modules/tasks/types'
import {
  IActionSetMessages,
  IActionSetResponses,
  MessagesActions,
  MessagesActionTypes,
  MessagesThunkDispatch,
  IActionToggleLoader, IActionSetMessagesPage, ILoadedFromActionButtons
} from './types'
import { ILibUser, Nullable } from '@infologistics/frontend-libraries'

// actions
export const setMessagesAction: ActionCreator<Action> = (messages: IMessage[]): IActionSetMessages => ({
  payload: messages,
  type: MessagesActionTypes.SET_MESSAGES
})

const setMessagesPageAction: ActionCreator<Action> = (page: Nullable<number>): IActionSetMessagesPage => ({
  payload: page,
  type: MessagesActionTypes.SET_MESSAGES_PAGE
})

export const setLoadedFromActionButtonsAction: ActionCreator<Action> = (isLoaded: boolean): ILoadedFromActionButtons => ({
  payload: isLoaded,
  type: MessagesActionTypes.SET_LOADED_FROM_ACTION_BUTTONS
})

const setResponsesAction: ActionCreator<Action> = (responses: IResponses): IActionSetResponses => ({
  payload: responses,
  type: MessagesActionTypes.SET_MESSAGES_RESPONSES
})

const toggleLoaderAction: ActionCreator<Action> = (isLoading: boolean): IActionToggleLoader => ({
  payload: isLoading,
  type: MessagesActionTypes.TOGGLE_MESSAGES_LOADER
})

export const actions: MessagesActions = {
  setMessages: setMessagesAction,
  setResponses: setResponsesAction,
  toggleLoader: toggleLoaderAction,
  setMassagesPage: setMessagesPageAction,
  setLoadedFromActiveButtons: setLoadedFromActionButtonsAction
}

// thunks
export const getMessages: AsyncThunk = (params: IParams = {}, isPushMessages = false) => (
  dispatch: MessagesThunkDispatch,
  getState
): Promise<AxiosResponse> =>
  flowsService.getAllTasks(params).then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      const respData = resp.data ?? []

      const page = getState().messages.page ?? -1
      const messagesData = isPushMessages
        ? [...getState().messages.messages, ...respData]
        : page > 0 ? respData.slice(0, page * 25) : respData

      dispatch(actions.setMessages(messagesData))

      respData.length < 25 && dispatch(actions.setMassagesPage(null))

      const tasksResponses = respData.reduce((acc: { [key: string]: IResponse }, message: IMessage) => {
        const response = {
          isOpen: false,
          recipient: message.task.author,
          text: ''
        }
        return {
          ...acc,
          [message.task.oguid]: response
        }
      }, {})

      const responses = isPushMessages
        ? { ...getState().messages.responses, ...tasksResponses }
        : tasksResponses

      dispatch(actions.setResponses(responses))
    }

    return resp
  })

export const toggleResponse: SyncThunk = (oguid: string) => (dispatch: MessagesThunkDispatch, getState): void => {
  const { responses } = getState().messages

  dispatch(
    setResponsesAction({
      ...responses,
      [oguid]: {
        ...responses[oguid],
        isOpen: !responses[oguid].isOpen,
        text: ''
      }
    })
  )
}

export const updateResponse: SyncThunk = (oguid: string, text: string) => (
  dispatch: MessagesThunkDispatch,
  getState
): void => {
  const { responses } = getState().messages

  dispatch(
    setResponsesAction({
      ...responses,
      [oguid]: {
        ...responses[oguid],
        text
      }
    })
  )
}

export const setRecipient: SyncThunk = (oguid: string, recipient: ILibUser) => (
  dispatch: MessagesThunkDispatch,
  getState
): void => {
  const { responses } = getState().messages

  dispatch(
    setResponsesAction({
      ...responses,
      [oguid]: {
        ...responses[oguid],
        recipient
      }
    })
  )
}

export const markAsRead: AsyncThunk = (taskOguid: string, orgOguid?: string) => (
  dispatch: MessagesThunkDispatch,
  getState
): Promise<AxiosResponse> =>
  flowsService.markAsRead(taskOguid, orgOguid).then((resp: AxiosResponse) => {
    if (resp.status === temporaryPostResponseCode.POST) {
      const messages = cloneDeep(getState().messages.messages)

      const currentMessageIndex = messages.findIndex((messages: IMessage) => messages.task.oguid === taskOguid)

      messages[currentMessageIndex].task.isRead = true

      dispatch(actions.setMessages(messages))
    }

    return resp
  })
