import { Action, ActionCreator } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'

import flowsService from '@services/flows'

import { ResponseCode } from '@const/consts'
import { INavItem } from '@const/navSidebar'
import { IUserFilter } from '@store/modules/userFilters/types'
import { AsyncThunk } from '@store/types/commonTypes'

import {
  ActionKey,
  IBadges,
  IActionResetNavigation,
  IActionSetActiveMenu,
  IActionSetBadges,
  NavigationActions,
  NavigationActionTypes,
  NavigationThunkAction,
  NavigationThunkDispatch,
  IActionSetMenuItems,
  IActionSetMenuItemsWithFilters,
  IActionSetBadgesNotices,
  INoticesBadges,
  IActionSetAllBadges,
  IActionResetNavigationOrgChange,
  IActionSetPrevMenu,
  IActionSetTaskIdx
} from './types'
import { addUserFilterInDocuments } from '@utils/utils'

export const resetNavigation: IActionResetNavigation = {
  type: NavigationActionTypes.RESET_NAVIGATION
}

export const resetNavigationAfterOrgChange: IActionResetNavigationOrgChange = {
  type: NavigationActionTypes.RESET_NAVIGATION_AFTER_ORG_CHANGE
}

export const setActiveMenuAction: ActionCreator<Action> = (activeMenu: string): IActionSetActiveMenu => ({
  payload: activeMenu,
  type: NavigationActionTypes.SET_ACTIVE_MENU
})

const setBadgesAction: ActionCreator<Action> = (badges: IBadges): IActionSetBadges => ({
  payload: badges,
  type: NavigationActionTypes.SET_BADGES
})

const setAllBadgesAction: ActionCreator<Action> = (badges: IBadges): IActionSetAllBadges => ({
  payload: badges,
  type: NavigationActionTypes.SET_ALL_BADGES
})

const setBadgesNoticesAction: ActionCreator<Action> = (badgesNotices: INoticesBadges): IActionSetBadgesNotices => ({
  payload: badgesNotices,
  type: NavigationActionTypes.SET_BADGES_NOTICES
})

const setMenuItemsAction = (menuItems: INavItem[]): IActionSetMenuItems => ({
  payload: menuItems,
  type: NavigationActionTypes.SET_MENU_ITEMS
})

const setMenuItemsWithFiltersAction = (
  menuItems: INavItem[],
  userFilters: IUserFilter[]
): IActionSetMenuItemsWithFilters => ({
  payload: addUserFilterInDocuments(menuItems, userFilters),
  type: NavigationActionTypes.SET_MENU_ITEMS_WITH_FILTERS
})

const setPrevMenuAction = (prevMenu: string): IActionSetPrevMenu => ({
  payload: prevMenu,
  type: NavigationActionTypes.SET_PREV_MENU
})

const setTaskIdxAction = (idx: number): IActionSetTaskIdx => ({
  payload: idx,
  type: NavigationActionTypes.SET_TASK_IDX
})

export const actions: NavigationActions = {
  [ActionKey.SET_ACTIVE_MENU]: setActiveMenuAction,
  [ActionKey.SET_BADGES]: setBadgesAction,
  [ActionKey.SET_ALL_BADGES]: setAllBadgesAction,
  [ActionKey.SET_BADGES_NOTICES]: setBadgesNoticesAction,
  [ActionKey.SET_MENU_ITEMS_WITH_FILTERS]: setMenuItemsWithFiltersAction,
  [ActionKey.SET_MENU_ITEMS]: setMenuItemsAction,
  [ActionKey.SET_PREV_MENU]: setPrevMenuAction,
  [ActionKey.SET_TASK_IDX]: setTaskIdxAction,
}

export const getBadges: ActionCreator<NavigationThunkAction> = (orgOguid?: string) => (dispatch: NavigationThunkDispatch, getState) =>
  flowsService.getBadges(!getState().utils.isRootMode, orgOguid)
    .then((resp: AxiosResponse<IBadges>) => {
      if (resp.status === ResponseCode.GET && !orgOguid) {
        const action = getState().utils.isRootMode
          ? ActionKey.SET_ALL_BADGES
          : ActionKey.SET_BADGES

        dispatch(actions[action](resp.data))
      }

      return resp
    })
    .catch((err: AxiosError) => Promise.reject(err))

export const getAllBadges: ActionCreator<NavigationThunkAction> = () => (dispatch: NavigationThunkDispatch) =>
  flowsService.getAllBadges()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions[ActionKey.SET_ALL_BADGES](resp.data))
      }

      return resp
    })
    .catch((err: AxiosError) => Promise.reject(err))

  export const getBadgesNotices: AsyncThunk = () => (dispatch: NavigationThunkDispatch): Promise<AxiosResponse> =>
  flowsService.getBadgesNotices()
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions[ActionKey.SET_BADGES_NOTICES](resp.data))
      }

      return resp
    })
    .catch((err: AxiosError) => Promise.reject(err))
