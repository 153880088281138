import React, { FC, ReactNode } from 'react'
import { useLocation } from 'react-router'
import { Route, RouteComponentProps } from 'react-router-dom'

import { AllRouteParams } from '@store/types/commonTypes'
import { IRoute as IProps } from '@common/Routing/components/RouteWithSubRoutes/types'

const RouteWithSubRoutes: FC<IProps> = (props) => {
  const { path, exact, routeAdditionalProp } = props

  const { pathname, search} = useLocation<AllRouteParams>()

  const renderHandler = (renderProps: RouteComponentProps): ReactNode => {
    return <props.component {...renderProps} routeAdditionalProp={routeAdditionalProp} />
  }

  return <Route key={`${pathname}${search}`} path={path} exact={exact} render={renderHandler} />
}

export default RouteWithSubRoutes
