import React, { FC, ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import Page404 from '@views/misc/components/Page404'

import { getRoutesList } from '@utils/utils'

import { IRenderRoutesProps as IProps } from './types'

const RenderRoutes: FC<IProps> = ({ routes }): ReactElement => (
  <Switch>
    {getRoutesList(routes)}
    <Route component={Page404} />
  </Switch>
)

export default RenderRoutes
