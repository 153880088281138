import { getFormattedTypesFields } from '@utils/utils'
import {
  Actions,
  MetadataActionTypes,
  IMetadataState,
  IDocumentTypes,
  IUserDocumentTypes,
  IDocumentTypesFromServer,
  IUserDocumentTypesFromServer
} from './types'

export const initialState: IMetadataState = {
  accessGroups: [],
  dictTypes: [],
  documentTypes: {},
  documentTypeGroups: [],
  fields: [],
  flowStageNames: [],
  flowStageTypes: [],
  historyStateCodes: {},
  localizations: [],
  userDocTypes: {},
  workflowStatuses: {}
}

// temporary solution for compatibility
// converting the fields structure from [] to {}
const getFormattedTypes = (payload: IDocumentTypesFromServer): IDocumentTypes => getFormattedTypesFields(payload)
const getFormattedUserTypes = (payload: IUserDocumentTypesFromServer): IUserDocumentTypes => getFormattedTypesFields(payload)

export default (state = initialState, action: Actions): IMetadataState => {
  switch (action.type) {
    case MetadataActionTypes.RESET_METADATA:
      return { ...initialState, flowStageTypes: state.flowStageTypes }

    case MetadataActionTypes.SET_ACCESS_GROUPS:
      return {
        ...state,
        accessGroups: [...action.payload]
      }

    case MetadataActionTypes.SET_FIELDS:
      return {
        ...state,
        fields: [...action.payload]
      }

    case MetadataActionTypes.SET_FLOW_STAGE_NAMES:
      return {
        ...state,
        flowStageNames: [...action.payload]
      }

    case MetadataActionTypes.SET_FLOW_STAGE_TYPES:
      return {
        ...state,
        flowStageTypes: [...action.payload]
      }

    case MetadataActionTypes.SET_HISTORY_STATE_CODES:
      return {
        ...state,
        historyStateCodes: { ...action.payload }
      }

    case MetadataActionTypes.SET_DICT_TYPES:
      return {
        ...state,
        dictTypes: [...action.payload]
      }

    case MetadataActionTypes.SET_DOCUMENT_TYPES:
      return {
        ...state,
        documentTypes: { ...getFormattedTypes(action.payload) }
      }

    case MetadataActionTypes.SET_DOCUMENT_TYPE_GROUPS:
      return {
        ...state,
        documentTypeGroups: [...action.payload]
      }

    case MetadataActionTypes.SET_LOCALIZATIONS:
      return {
        ...state,
        localizations: [...action.payload]
      }

    case MetadataActionTypes.SET_USER_DOC_TYPES:
      return {
        ...state,
        userDocTypes: { ...getFormattedUserTypes(action.payload) }
      }

    case MetadataActionTypes.SET_WORKFLOW_STATUSES:
      return {
        ...state,
        workflowStatuses: { ...action.payload }
      }

    default:
      return state
  }
}
