import { AxiosResponse, AxiosRequestConfig } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IRoute, IGetAssignee, IPostAssignee } from '@store/modules/routes/types'

class RoutesService extends AbstractHttpService {
  private readonly url = urls.routes

  async getRoutes(config: AxiosRequestConfig): Promise<AxiosResponse<Array<IRoute<IGetAssignee>>>> {
    const url = this.url.list

    return await this._http.get(url, config)
  }

  async getRoute(routeOguid: string): Promise<AxiosResponse<IRoute<IGetAssignee>>> {
    const url = this.url.single.replace('{{ oguid }}', routeOguid)

    return await this._http.get(url)
  }

  async saveRoute(route: IRoute<IPostAssignee>): Promise<AxiosResponse<IRoute<IGetAssignee>>> {
    const url = this.url.list

    return await this._http.post(url, route)
  }

  async updateRoute(routeOguid: string, route: IRoute<IPostAssignee>): Promise<AxiosResponse<IRoute<IGetAssignee>>> {
    const url = this.url.single.replace('{{ oguid }}', routeOguid)

    return await this._http.put(url, route)
  }

  async deleteRoute(routeOguid: string): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ oguid }}', routeOguid)

    return await this._http.delete(url)
  }
}

export default new RoutesService()
