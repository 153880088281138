import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from "redux-thunk"

import { IGroup } from '@store/modules/group/types'
import { AxiosResponse } from "axios"
import { IApplicationState } from "@store/types/commonTypes"

export interface IGroupsState {
  data: IGroup[]
}

export enum GroupsActionTypes {
  GET_GROUPS = 'GET_GROUPS',
  RESET_GROUPS = 'RESET_GROUPS'
}

export interface IActionGetGroups extends Action<string> {
  payload: IGroup[]
  type: GroupsActionTypes.GET_GROUPS
}

export interface IActionResetGroups extends Action<string> {
  type: GroupsActionTypes.RESET_GROUPS
}

export type Actions = IActionGetGroups | IActionResetGroups

enum ActionKeys {
  GET_GROUPS = 'getGroups'
}

export type GroupsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type GroupsThunkAction = ThunkAction<Promise<AxiosResponse>, IGroup[], void, Action<string>>

export type GroupsThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>
