import { Actions, IPaginationState, PaginationActionTypes } from './types'

export default function paginationReducer(state: IPaginationState = null, action: Actions): IPaginationState {
  switch (action.type) {
    case PaginationActionTypes.RESET_PAGINATION:
      return null
    case PaginationActionTypes.SET_PAGINATION:
      return { ...action.payload }
    default:
      return state
  }
}
