import cn from 'classnames'
import { isEqual } from 'lodash'
import React, { ChangeEvent, Component, ReactElement, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import { format } from '@fnando/cnpj'
import {
  Button,
  Ellipsis,
  IconSquarePlus,
  ILibHorizontalPosition,
  Input,
  Popover
} from '@infologistics/frontend-libraries'

import { DebounceDelay, Instance, Modal, Role, Section } from '@const/consts'
import { OrganizationList } from '@const/translations'

import { createUrl, debounce, displayErrorNotification, getInstancePostfix,  getOgrnTitle, toggleRootModeInUserSettings } from '@utils/utils'

import NewOrganizationModal from './modals/NewOrganizationModal'

import { IUserOrganization } from '@store/types/commonTypes'
import { IOrganizationsListProps as IProps, IOrganizationsListState as IState } from './types'

import styles from './OrganizationList.module.css'

const classNames = {
  creation: cn(styles.row, styles.creation, 'py-1'),
  list: cn(styles.list, 'overflow-y scrollbar-content'),
  name: cn(styles.name, 'd-flex align-items-center font-default text-brand pointer'),
  title: cn(styles.title, 'd-flex flex-row align-items-center'),
  search: cn(styles.search, 'mx-4 my-2'),
  no_orgs: 'my-1 p-0 mb-0 text-muted text-center',
  container: styles.container
}

export class OrganizationsList extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      isOpenModal: false,
      organizations: props.organizations,
      search: '',
      isTemplatePopoverOpen: false
    }
  }

  public componentDidUpdate(): void {
    const { organizations: propsOrgs } = this.props
    const { organizations: stateOrgs } = this.state

    if (!isEqual(propsOrgs, stateOrgs)) {
      this.filterOrganizationsWithDebounce()
    }
  }

  public render(): ReactElement {
    const { t } = this.props
    const { isOpenModal, search, isTemplatePopoverOpen } = this.state

    const getSearchInputPlaceholderText = (): string => {
      return t(OrganizationList['search' + getInstancePostfix()])
    }

    return (
      <div className={styles.content}>
        <Popover
          buttonComponent={this.renderBtnPopover()}
          containerClasses={classNames.container}
          withoutArrow
          horizontalPosition={ILibHorizontalPosition.LEFT}
          parentManagement={{
            isOpenFromParent: isTemplatePopoverOpen,
            callbackForClose: this.toggleClose
          }}
        >
          <Input
            classes={classNames.search}
            value={search}
            name='search'
            onChange={this.handleSearchInputChange}
            placeholder={getSearchInputPlaceholderText()}
          />
          {this.renderSearchResults()}
          {this.getNewOrganizationRow()}
        </Popover>
        {isOpenModal && <NewOrganizationModal onChangeIsOpenModal={this.handleCloseModal} />}
      </div>
    )
  }

  private readonly renderBtnPopover = (): ReactElement => {
    const { activeOrganization, isRootMode, organizations } = this.props
    const { isTemplatePopoverOpen } = this.state
    const isMultiOrg = organizations.length > 1

    return (
      <div className='d-flex'>
        {isMultiOrg && (
          <>
            <Button
              theme='text'
              classes={cn(classNames.name, 'ml-4')}
              onClick={this.handleToggleRootMode}
            >
              {'...'}
            </Button>
            <span className='mx-1 text-muted'>/</span>
          </>
        )
      }
        {!isRootMode && (
          <Button
            title={activeOrganization.name}
            theme='text'
            classes={cn(classNames.name, !isMultiOrg && 'ml-4')}
            onClick={this.togglePopover}
            rightIcon={isMultiOrg ? 'IconAngle' : undefined}
            rightIconProps={{
              size: 'xs',
              color: 'brand-500',
              rotation: isTemplatePopoverOpen ? '180' : '0',
              externalClass: 'mt-1'
            }}
          >
            <Ellipsis>{activeOrganization.nameShort}</Ellipsis>
          </Button>
        )}
      </div>
    )
  }

  private readonly togglePopover = (): void => {
    this.setState((prevState: IState) => ({ isTemplatePopoverOpen: !prevState.isTemplatePopoverOpen }))
  }

  private readonly toggleClose = (isOpenFromPopover: boolean): void => {
    if (!isOpenFromPopover) {
      this.setState({ isTemplatePopoverOpen: false })
    }
  }

  private readonly handleToggleRootMode = (): void => {
    const { onGetBadges, isRootMode, history } = this.props

    if (!isRootMode) {
      onGetBadges().catch(displayErrorNotification)
      toggleRootModeInUserSettings(true)

      history.push(Section.DOCUMENTS_IN_PROGRESS)
    }
  }

  public getOrganizationsList(): ReactElement[] {
    const { t, instance } = this.props
    const { organizations } = this.state

    return organizations.map((organization: IUserOrganization) => {
      const { inn, kpp, name, nameShort, oguid, ogrn } = organization

      const isRussia = instance === Instance.RUSSIAN
      const isBrasil = instance === Instance.BRASIL
      const formatedOgrn = isBrasil && ogrn ? format(ogrn) : ogrn

      const innOrOgrnValue = isRussia ? inn : formatedOgrn
      const kppValue = isRussia ? kpp : null

      const noInnAndKpp = !innOrOgrnValue && !kppValue
      const ellipsisClasses = noInnAndKpp
        ? styles.title_width_noKppAndInn
        : styles.title_width

      const innOrOgrnLabel = instance === Instance.RUSSIAN
        ? t('common:inn')
        : t(getOgrnTitle())

      return (
        <tr key={oguid} className={styles.row} onClick={this.handleOrganizationClick(organization)}>
          <td
            title={name}
            colSpan={noInnAndKpp ? 3 : 1}
            className={styles.title}
          >
            <Ellipsis externalClass={ellipsisClasses}>
              {nameShort}
            </Ellipsis>
          </td>
          {
            !noInnAndKpp &&
            <>
          <td className={styles.itn}>
                {
                  innOrOgrnValue &&
                  <>
                    <small className='text-muted'>
                      {innOrOgrnLabel}&nbsp;
                    </small>
                    {innOrOgrnValue}
                  </>
                }
          </td>
          <td className={styles.cio}>
                {
                  (isRussia && kpp) &&
                  <>
                    <small className='text-muted'>
                      {t('common:kpp')}&nbsp;
                    </small>
            {kpp}
                  </>
                }
          </td>
            </>
          }
        </tr>
      )
    })
  }

  private readonly renderSearchResults = (): ReactElement => {
    const { t } = this.props
    const { organizations } = this.state

    return organizations.length ? (
      <div className={classNames.list}>
        <table>
          <tbody>{this.getOrganizationsList()}</tbody>
        </table>
      </div>
    ) : (
      <p className={classNames.no_orgs}>{t('organizationList:noOrgs')}</p>
    )
  }

  private readonly getNewOrganizationRow = (): ReactNode => {
    const { role, t } = this.props

    const isAccessAllowed = role === Role.ADMINISTRATOR

    return (
      isAccessAllowed && (
        <div className={classNames.creation} onClick={this.handleShowModal}>
          <span className={classNames.title}>
            <IconSquarePlus size='sm' externalClass='mr-2' />
            {t('organizationList:newOrganization')}
          </span>
        </div>
      )
    )
  }

  private readonly handleShowModal = (): void => {
    const { onShowModal } = this.props

    this.setState({
      isOpenModal: true
    })
    onShowModal(Modal.NEW_ORGANIZATION)
    this.togglePopover()
  }

  private readonly handleCloseModal = (): void => {
    this.setState({
      isOpenModal: false
    })
  }

  private readonly handleOrganizationClick = (organization: IUserOrganization) => {
    return () => {
      const {
        activeOrganization,
        onChangeOrganization,
        resetStore,
        setLoaded
      } = this.props

      if (activeOrganization.oguid !== organization.oguid) {
        resetStore()
        onChangeOrganization(organization)

        const organizationIdentifier = organization.alias || organization.oguid

        this.historyReplace(organizationIdentifier, Section.DOCUMENTS_ALL)

        setLoaded(false)
      }
    }
  }

  private readonly handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
    this.setState({ search: value })
    this.filterOrganizationsWithDebounce()
  }

  private readonly filterOrganizations = (): void => {
    const { organizations, instance } = this.props

    this.setState((prevState: IState) => {
      const search = prevState.search.toLowerCase()

      const filteredOrganizations = organizations.filter(({ name, nameShort, inn, kpp, ogrn }) => {
        const innOrOgrn = instance === Instance.RUSSIAN ? inn?.includes(search) : ogrn?.includes(search)

        return (
          name?.toLowerCase().includes(search) ||
          nameShort?.toLowerCase().includes(search) ||
          kpp?.includes(search) ||
          innOrOgrn
        )
      })

      return {
        ...prevState,
        organizations: filteredOrganizations
      }
    })
  }

  private readonly filterOrganizationsWithDebounce = debounce(this.filterOrganizations, DebounceDelay.ORGANIZATIONS)

  private readonly historyReplace = (org: string, path: string): void =>
    this.props.history.push(createUrl(org, path))
}

export default withTranslation(['organizationList', 'common', 'auth', 'contractor'])(withRouter(OrganizationsList))
