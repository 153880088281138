import { Action, ActionCreator } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'

import groupsService from '@services/groups'

import { ResponseCode } from '@const/consts'

import { IParams } from '@store/types/commonTypes'
import { setPagination } from '@store/modules/pagination/actions'
import { IGroup } from '@store/modules/group/types'
import {
  GroupsActions,
  GroupsActionTypes,
  GroupsThunkAction,
  GroupsThunkDispatch,
  IActionGetGroups,
  IActionResetGroups
} from "./types"

import { getPagination } from '@utils/utils'

export const getGroupsAction: ActionCreator<Action> = (groups: IGroup[]): IActionGetGroups => ({
  payload: groups,
  type: GroupsActionTypes.GET_GROUPS
})

export const resetGroups: IActionResetGroups = {
  type: GroupsActionTypes.RESET_GROUPS
}

export const actions: GroupsActions = {
  getGroups: getGroupsAction
}

// thunk

export const getGroups: ActionCreator<GroupsThunkAction> = (
  params?: IParams
) => (dispatch: GroupsThunkDispatch): Promise<AxiosResponse<IGroup[]>> =>
  groupsService.getGroups({ params })
    .then((resp) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.getGroups(resp.data ?? []))

        const pagination = getPagination(resp.headers)
        pagination && dispatch(setPagination(pagination))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))
