import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { AssignMode } from '@const/consts'
import {
  IAssignedToSubOrg,
  IAssignedToUser,
  IGroup,
  Nullable
} from '@store/types/commonTypes'
import { IConstraintToSet } from '@store/modules/constraints/types'
import { IStageAssigneeOption } from '@common/Stages/components/Stage/types'

export interface ISubOrg {
  name: string
  oguid: string
}

export interface IContractor {
  inn?: string
  kpp?: string
  nameShort: string
  ogrn?: string
  oguid: string
}

export interface IRoute<AssigneeType> {
  createdTimestamp?: number
  name: string
  objectTypes: Nullable<string[]>
  oguid?: string
  stages: Array<IStage<AssigneeType>>
  startConditions: Nullable<ICondition[]>
  ownerUserOguid?: Nullable<string>
  ownerGroupOguid?: Nullable<string>
  ownerCalculatedUser: Nullable<IRouteCalculatedUser>
  ownerManagerOfSubOrgOguid?: Nullable<string>
  ownerManagerOfSubOrg?: Nullable<IAssignedToSubOrg>
  ownerUser?: Nullable<IAssignedToUser>
  ownerGroup?: Nullable<IGroup>
  routeOwnerRole?: Nullable<string>
}

export type IRouteCalculatedUser = 'INITIATOR' | 'AUTHOR'

export interface ICondition {
  code: string
  operator: string
  value?: any
  id?: string
}

export type StageType = 'APPROVAL' | 'ACQUAINTANCE' | 'SIGNING'

export interface IStage<AssigneeType> {
  assignees: AssigneeType[]
  id?: string
  userName: Nullable<string>
  type: string
  constraints: Nullable<Array<string | IConstraintToSet>>
  isMilestone?: boolean
  hint?: Nullable<string>
  hoursToProcess?: Nullable<number>
}

interface IAssignee {
  assignMode: Nullable<AssignMode>
  startConditions: Nullable<ICondition[]>
}

export interface IGetAssignee extends IAssignee {
  assignedToCalculatedUser: Nullable<string>
  assignedToGroup: Nullable<IGroup>
  assignedToManagerOfSubOrg: Nullable<IAssignedToSubOrg>
  assignedToUser: Nullable<IAssignedToUser>
  hoursToAutoAction: Nullable<number>
  autoReassignToGroup: Nullable<IStageAssigneeOption>
  autoReassignToManagerOfSubOrg: Nullable<IAssignedToSubOrg>
  autoReassignToOwner: Nullable<boolean>
  autoReassignToUser: Nullable<IAssignedToUser>
  autoReassignedFromUser: Nullable<IAssignedToUser>
  autoReassignedFromGroup: Nullable<IStageAssigneeOption>
  autoReassignedFromManagerOfSubOrg: Nullable<IAssignedToSubOrg>
}

export interface IPostAssignee extends IAssignee {
  assignedToCalculatedUser: Nullable<string>
  assignedToGroupOguid: Nullable<string>
  assignedToUserOguid: Nullable<string>
}

export enum RoutesActionTypes {
  DELETE_ROUTE = 'DELETE_ROUTE',
  RESET_ROUTES = 'RESET_ROUTES',
  SET_ROUTES = 'SET_ROUTES'
}

export interface IRoutesState {
  data: Array<IRoute<IGetAssignee>>
}

export interface IActionDeleteRoute extends Action<string> {
  payload: string
  type: RoutesActionTypes.DELETE_ROUTE
}

export interface IActionResetRoutes extends Action<string> {
  type: RoutesActionTypes.RESET_ROUTES
}

export interface IActionSetRoutes extends Action<string> {
  payload: Array<IRoute<IGetAssignee>>
  type: RoutesActionTypes.SET_ROUTES
}

export type Actions = IActionDeleteRoute | IActionResetRoutes | IActionSetRoutes

type ActionKeys = 'deleteRoute' | 'setRoutes'

export type RoutesActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type RoutesThunkAction = ThunkAction<Promise<AxiosResponse>, IRoutesState, void, Action<string>>

export type RoutesThunkDispatch = ThunkDispatch<IRoutesState, void, Action<string>>
