import { Instance } from '@const/consts'

const domain = 'https://docuforce.infologistics.'
const domainBR = 'https://docuforce.ilsbrasil.'

export const dropdownItems = [
  {
    name: Instance.GLOBAL,
    translation: domain + Instance.GLOBAL
  },
  {
    name: Instance.BRASIL,
    translation: domainBR + Instance.BRASIL
  },
  {
    name: Instance.RUSSIAN,
    translation: domain + Instance.RUSSIAN
  },
  {
    name: Instance.SOUTH_AFRICA,
    translation: domain + Instance.SOUTH_AFRICA
  }
]
