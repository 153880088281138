import UniversalCookies, { Cookie, CookieSetOptions } from 'universal-cookie'

const COOKIE_PREFIX = 'docuforce_'

interface ICookies {
  cookies: UniversalCookies
}

class Cookies implements ICookies {
  public cookies = new UniversalCookies()

  public static getCookieName(name: string): string {
    return COOKIE_PREFIX + name
  }

  public get(name: string): any {
    return this.cookies.get(Cookies.getCookieName(name))
  }

  public remove(name: string, options?: CookieSetOptions): void {
    this.cookies.remove(Cookies.getCookieName(name), options)
  }

  public set(name: string, value: Cookie, options?: CookieSetOptions): void {
    this.cookies.set(Cookies.getCookieName(name), value, options)
  }
}

export default new Cookies()
