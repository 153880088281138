import { Action, ActionCreator } from 'redux'
import { AxiosResponse } from 'axios'

import { ResponseCode, noticesPerPageDefault } from '@const/consts'
import flowsService from '@services/flows'
import { AsyncThunk, IParams, Nullable } from '@store/types/commonTypes'
import { INotice } from '@common/Notices/Notice/types'
import {
  IActionSetNotices,
  NoticesActions,
  NoticesActionTypes,
  NoticesThunkDispatch,
  IActionToggleLoader, IActionSetNoticesPage, ILoadedFromActionButtons
} from './types'

// actions
export const setNoticesAction: ActionCreator<Action> = (notices: INotice[]): IActionSetNotices => ({
  payload: notices,
  type: NoticesActionTypes.SET_NOTICES
})

const setNoticesPageAction: ActionCreator<Action> = (page: Nullable<number>): IActionSetNoticesPage => ({
  payload: page,
  type: NoticesActionTypes.SET_NOTICES_PAGE
})

export const setLoadedFromActionButtonsAction: ActionCreator<Action> = (isLoaded: boolean): ILoadedFromActionButtons => ({
  payload: isLoaded,
  type: NoticesActionTypes.SET_LOADED_FROM_ACTION_BUTTONS
})

const toggleLoaderAction: ActionCreator<Action> = (isLoading: boolean): IActionToggleLoader => ({
  payload: isLoading,
  type: NoticesActionTypes.TOGGLE_NOTICES_LOADER
})

export const actions: NoticesActions = {
  setNotices: setNoticesAction,
  toggleLoader: toggleLoaderAction,
  setNoticesPage: setNoticesPageAction,
  setLoadedFromActiveButtons: setLoadedFromActionButtonsAction
}

// thunks
export const getNotices: AsyncThunk = (params: IParams = {}, isPushMessages = false) => (
  dispatch: NoticesThunkDispatch,
  getState
): Promise<AxiosResponse> =>
  flowsService.getNotices(params).then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      const page = getState().notices.page ?? -1
      const respData = resp.data ?? []

      const noticesData = isPushMessages
        ? [...getState().notices.notices, ...respData]
        : page > 0 ? respData.slice(0, page * noticesPerPageDefault) : respData

      dispatch(actions.setNotices(noticesData))
      respData.length < noticesPerPageDefault && dispatch(actions.setNoticesPage(null))
    }

    return resp.data
  })
