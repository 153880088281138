import { Actions, ConstraintsActionTypes, IConstraintToGet } from './types'

export const initialState: IConstraintToGet[] = []

export default function constraintsReducer(
  state: IConstraintToGet[] = initialState,
  action: Actions
): IConstraintToGet[] {
  switch (action.type) {
    case ConstraintsActionTypes.RESET_CONSTRAINTS: {
      return initialState
    }

    case ConstraintsActionTypes.SET_CONSTRAINTS: {
      return action.payload
    }

    default:
      return state
  }
}
