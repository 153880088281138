import { Actions, ITasksState, TasksActionTypes } from './types'

export const initialState: ITasksState = {
  messages: [],
  responses: {},
  selectedDocuments: {}
}

export default function tasksReducer(state = initialState, action: Actions): ITasksState {
  switch (action.type) {
    case TasksActionTypes.SET_SELECTED_DOCUMENTS:
      return {
        ...state,
        selectedDocuments: { ...action.payload }
      }

    case TasksActionTypes.SET_TASKS_MESSAGES:
      return {
        ...state,
        messages: [...action.payload]
      }

    case TasksActionTypes.SET_RESPONSES:
      return {
        ...state,
        responses: { ...action.payload }
      }

    case TasksActionTypes.RESET_TASKS:
      return { ...initialState }

    case TasksActionTypes.RESET_SELECTED_DOCUMENTS:
      return {
        ...state,
        selectedDocuments: {}
      }

    default:
      return state
  }
}
