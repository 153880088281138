import { Actions, GroupsActionTypes, IGroupsState } from './types'

export const initialState: IGroupsState = { data: [] }

export default function groupsReducer(state: IGroupsState = initialState, action: Actions): IGroupsState {
  switch (action.type) {
    case GroupsActionTypes.GET_GROUPS:
      return {
        ...state,
        data: [...action.payload]
      }

    case GroupsActionTypes.RESET_GROUPS:
      return initialState

    default:
      return state
  }
}
