import { IApplicationState } from '@store/types/commonTypes'
import { appReducer } from '@store/configureStore'

import { USER_LOGOUT } from './types'

// additional wrapper over rootReducer for the convenience of resetting state to initial state during logout
export const rootReducer = (state: undefined | IApplicationState, action: any): IApplicationState => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
