import { Actions, RoutesActionTypes, IRoutesState } from './types'

export const initialState: IRoutesState = {
  data: []
}

export default function routesReducer(state: IRoutesState = initialState, action: Actions): IRoutesState {
  switch (action.type) {
    case RoutesActionTypes.RESET_ROUTES:
      return { ...initialState }

    case RoutesActionTypes.SET_ROUTES:
      return { ...state, data: [...action.payload] }

    case RoutesActionTypes.DELETE_ROUTE: {
      const filteredRoutes = state.data.filter((route: any) => route.oguid !== action.payload)

      return { ...state, data: [...filteredRoutes] }
    }
    default:
      return state
  }
}
