import { IPaginationParams } from '@common/PaginationWrapper/types'
import { Actions, ContractorsActionTypes, IContractorsState } from './types'

export const initialState: IContractorsState = {
  data: [],
  pagination: {
    itemsPerPage: 10,
    pageIndex: 1,
    total: 10,
    totalPages: 1
  }
}

export default function contractorsReducer(
  state: IContractorsState = initialState,
  action: Actions
): IContractorsState {
  switch (action.type) {
    case ContractorsActionTypes.RESET_CONTRACTORS:
      return initialState

    case ContractorsActionTypes.GET_CONTRACTOR:
      return { ...state, data: [...action.payload] }

    case ContractorsActionTypes.SET_PAGINATION:
      return { ...state, pagination: action.payload }

    case ContractorsActionTypes.ADD_CONTRACTOR: {
      const increaseTotalAmount: IPaginationParams = {
        ...state.pagination,
        total: state.pagination.total + 1
      }

      return { pagination: increaseTotalAmount, data: [...state.data, ...action.payload] }
    }
    case ContractorsActionTypes.SAVE_FILTER_PARAMS:
      return {
        ...state
        // TODO params field is missing in the interface
        // we need to either add it
        // or delete this action type
        // params: action.payload
      }

    case ContractorsActionTypes.DELETE_CONTRACTOR: {
      const decreaseTotalAmount: IPaginationParams = {
        ...state.pagination,
        total: state.pagination.total - 1
      }
      const filterContractors = state.data.filter((contractor) => contractor.oguid !== action.payload)

      return { pagination: decreaseTotalAmount, data: [...filterContractors] }
    }

    case ContractorsActionTypes.EDIT_CONTRACTOR: {
      const editedData = state.data.map((item) => {
        if (item.oguid !== action.payload.oguid) {
          return item
        }

        return {
          ...item,
          ...action.payload
        }
      })

      return { ...state, data: [...editedData] }
    }

    default:
      return state
  }
}
