import React, { FC, ReactElement, ReactNode } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'

import {
  IconChecklistLight,
  IconInfo,
  getFormattedDateAndTime,
  getFormattedDate,
  ActionButtons,
  ILibAction,
  Button
} from '@infologistics/frontend-libraries'

import { NoticeEventType, RouteName } from '@const/consts'
import { createUrl, displayErrorNotification, displaySuccessNotification, getNameWithInitials } from '@utils/utils'
import { Notices as NoticesTranslate } from '@const/translations'
import { actions } from '@store/modules/notices/actions'
import { changeOrganization, resetStoreAfterOrganizationChange } from '@store/modules/user/actions'
import { getDictTypes } from '@store/modules/metadata/actions'
import { getDivisions } from '@store/modules/divisions/actions'
import { setLoaded } from '@store/modules/startup/actions'
import { IApplicationState, Nullable } from '@store/types/commonTypes'
import flowsService from '@services/flows'
import { INoticeDocument, INoticeProps as IProps } from './types'

import styles from './Notice.module.css'

const Notice: FC<IProps> = ({
  isInfo,
  notice,
  updateNoticesList
}) => {
  const { t } = useTranslation(['notification'])
  const history = useHistory()
  const { activeOrganization } = useSelector((state: IApplicationState) => state.user)
  const dispatch = useDispatch<any>()

  const { oguid, notifyMessage, eventType } = notice
  const { eventDateTime, eventOrg, documents, eventProperties } = notifyMessage

  const getNoticeActionTitle = (): Nullable<string> => {
    const titles = {
      [NoticeEventType.NEW_TASK]: documents[0].requiredAction,
      [NoticeEventType.NEW_TODO]: t(NoticesTranslate.actions.newToDo),
      [NoticeEventType.INCOMING_MESSAGE]: t(NoticesTranslate.actions.newIncomingMessage),
      [NoticeEventType.INCOMING_PATCH]: t(NoticesTranslate.actions.newNotification),
      [NoticeEventType.FLOW_CHANGE]: t(NoticesTranslate.actions.newNotification),
      [NoticeEventType.FLOW_FINISHED]: t(NoticesTranslate.actions.flowFinished)
    }

    return titles[eventType] ?? null
  }

  const getNoticeActionDescription = (): Nullable<string> => {
    if (!eventProperties) return null

    const fullName = getNameWithInitials(eventProperties.author)

    switch (eventType) {
      case NoticeEventType.INCOMING_PATCH:
      case NoticeEventType.FLOW_CHANGE:
      case NoticeEventType.FLOW_FINISHED: {
        return `${fullName} ${eventProperties.historyStateCaption}`
      }
      case NoticeEventType.NEW_TODO: {
        return `${fullName} ${t(NoticesTranslate.actions.newSendToDo)}`
      }
      default:
        return null
    }
  }

  const renderActionsButtons = (): ReactElement => {
    const elements: ILibAction[] = [
      {
        icon: 'IconTrash',
        callback: removeNotice
      }
    ]

    return <ActionButtons elements={elements} externalClass={styles.actions}/>
  }

  const removeNotice = (): void => {
    dispatch(actions.toggleLoader(true))

    flowsService.markNoticeIsRead(notice.oguid)
      .then(() => {
        displaySuccessNotification({ title: t(NoticesTranslate.deleted) })
        updateNoticesList()
      })
      .catch((err) => {
        dispatch(actions.toggleLoader(false))
        displayErrorNotification(err)
      })
  }

  const renderTitle = (): ReactElement => (
    <div className='d-flex justify-content-between align-items-center mb-2'>
      <p className='d-flex align-items-center'>
        {isInfo ? <IconInfo/> : <IconChecklistLight/>}
        <span className='ml-1'>{getNoticeActionTitle()}</span>
      </p>
      {eventDateTime && <p className='font-xs text-muted'>{getFormattedDateAndTime(eventDateTime)}</p>}
    </div>
  )

  const renderDocumentTitle = (document: INoticeDocument): ReactNode => (
    document?.title.length > 0 && (
      <Button theme='link' linkStyle='none' classes={cn(styles.document_info, 'font-xs')} onClick={() => changeOrg(document)}>
        {document.title}
        {document.documentDate && ` ${t('common:from')} ${getFormattedDate(eventDateTime)}`}
      </Button>
    )
  )

  const changeOrg = (document: INoticeDocument): void => {
    const docPath = `${RouteName.DOCUMENTS}/${document.documentId}`
    const organizationIdentifier = eventOrg.alias || eventOrg.oguid

    if (eventOrg.oguid === activeOrganization.oguid) {
      history.replace(createUrl(activeOrganization.alias, docPath))
      return
    }

    dispatch(resetStoreAfterOrganizationChange())
    dispatch(changeOrganization(eventOrg))
    dispatch(getDictTypes())
    dispatch(getDivisions())

    history.replace(createUrl(organizationIdentifier, docPath))

    dispatch(setLoaded(false))
  }

  const renderDocumentInfo = (document: INoticeDocument): ReactNode => (
    <>
    {document?.sum && <p className={cn(styles.document_info, 'mt-1')}>{`${t('common:sum')}: ${document.sum}`}</p>}
    {document?.contractor?.nameShort && <p className={cn(styles.document_info, 'mb-1')}>{document.contractor.nameShort}</p>}
    </>
  )

  const renderActionDescription = (): ReactNode => (
    eventProperties
      ? <p className={cn(styles.document_info, 'mb-1')}>{getNoticeActionDescription()}</p>
      : null
  )

  const renderOrg = (): ReactNode => (
    eventOrg.nameShort
      ? <p className='font-xs text-muted m-0'>{eventOrg.nameShort}</p>
      : null
  )

  const renderDocument = (document: INoticeDocument): ReactElement => {
    return (
      <div key={oguid}>
        {renderTitle()}
        {renderDocumentTitle(document)}
        {renderDocumentInfo(document)}
        {renderActionDescription()}
        {renderOrg()}
        {renderActionsButtons()}
      </div>
    )
  }

  return (
    <div key={oguid} className={cn(styles.notice, 'relative')}>
      {notice.notifyMessage.documents.map((document) => renderDocument(document))}
    </div>
  )
}

export default Notice
