import { connect } from 'react-redux'

import NavMenuItem from './NavMenuItem'

import { IApplicationState } from '@store/types/commonTypes'
import { INavMenuItemPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  activeMenu: state.navigation.activeMenu,
  alias: state.user.activeOrganization.alias,
  allBadges: state.navigation.allBadges,
  badges: state.navigation.badges,
  oguid: state.user.activeOrganization.oguid,
  asideWidth: state.user.settings?.asideWidth,
  isRootMode: state.utils.isRootMode
})

export default connect(mapStateToProps)(NavMenuItem)
