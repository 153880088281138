import Loadable from 'react-loadable'
import { SpinnerWrapper } from '@containers/Navigation'
import { AllRoles, DefaultSubMenuItem, Path, RouteName } from '@const/consts'
import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'

const Document = Loadable({
  loader: () => import('@views/organization/documents/components/Document'),
  loading: SpinnerWrapper
})

const Documents = Loadable({
  loader: () => import('@views/organization/documents/components/Documents'),
  loading: SpinnerWrapper
})

const Tasks = Loadable({
  loader: () => import('@views/organization/documents/components/Tasks'),
  loading: SpinnerWrapper
})

const DocumentEditInternal = Loadable({
  loader: () => import('@views/organization/documents/components/DocumentEdit/DocumentEditInternal'),
  loading: SpinnerWrapper
})

export const routes: IRoute[] = [
  {
    path: Path.DOCUMENTS_ALL,
    exact: true,
    name: 'Documents',
    component: Documents,
    routeAdditionalProp: DefaultSubMenuItem.ALL,
    roles: AllRoles
  },
  {
    path: Path.DOCUMENTS_IN_PROGRESS,
    exact: true,
    name: 'Not completed tasks',
    component: Tasks,
    routeAdditionalProp: DefaultSubMenuItem.IN_PROGRESS,
    isFlowFunctionalityEnabled: true,
    roles: AllRoles,
    useRootMode: true
  },
  {
    path: Path.FILTERS,
    exact: true,
    name: 'Documents',
    component: Documents,
    routeAdditionalProp: RouteName.FILTERS,
    roles: AllRoles
  },
  {
    path: Path.DOCUMENTS_ADD,
    exact: true,
    name: 'DocumentAdd',
    component: DocumentEditInternal,
    roles: AllRoles,
    isReadonly: false
  },
  {
    path: Path.DOCUMENTS_EDIT,
    exact: true,
    name: 'Document',
    component: Document,
    roles: AllRoles
  }
]
