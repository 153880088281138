import { Actions, HandbooksActionTypes, IHandbook, IHandbooksState } from './types'

export const initialState: IHandbooksState = {
  handbookNames: [],
  handbooks: {},
  relations: {},
  singleValueRelations: {},
  singleValueRelationsTable: []
}

export default function handbooksReducer(state = initialState, action: Actions): IHandbooksState {
  switch (action.type) {
    case HandbooksActionTypes.RESET_HANDBOOKS:
      return { ...initialState }

    case HandbooksActionTypes.RESET_SINGLE_VALUE_RELATIONS:
      return {
        ...state,
        singleValueRelations: {},
        singleValueRelationsTable: []
      }

    case HandbooksActionTypes.RESET_SINGLE_VALUE_RELATION_TABLE: {
      const newSingleValueRelationsTable = [...state.singleValueRelationsTable]
      newSingleValueRelationsTable[action.row] = { ...state.singleValueRelationsTable?.[action.row], [action.payload]: [] }
      return {
        ...state,
        singleValueRelationsTable: [...newSingleValueRelationsTable]
      }
    }

    case HandbooksActionTypes.RESET_SINGLE_VALUE_RELATION: {
      return {
        ...state,
        singleValueRelations: {
          ...state.singleValueRelations,
          [action.payload]: []
        }
      }
    }

    case HandbooksActionTypes.GET_HANDBOOK_NAMES:
      return {
        ...state,
        handbookNames: action.payload
      }

    case HandbooksActionTypes.DELETE_HANDBOOK: {
      const newHandbooks = { ...state.handbooks }
      delete newHandbooks[action.name]
      const newHandbooksList = state.handbookNames.filter((handbookName: string) => handbookName !== action.name)
      return {
        ...state,
        handbookNames: newHandbooksList,
        handbooks: newHandbooks
      }
    }

    case HandbooksActionTypes.ADD_HANDBOOK_VALUE: {
      const isHandbookExisted = state.handbookNames.find((handbook: string) => handbook === action.name)

      if (isHandbookExisted) {
        const handbooksCopy = { ...state.handbooks }
        handbooksCopy[action.name] = [...state.handbooks[action.name], ...action.payload]

        return {
          ...state,
          handbooks: handbooksCopy
        }
      } else {
        return {
          ...state,
          handbookNames: [...state.handbookNames, action.name],
          handbooks: {
            ...state.handbooks,
            [action.name]: [...action.payload]
          }
        }
      }
    }

    case HandbooksActionTypes.DELETE_HANDBOOK_VALUE:
      return { ...state }

    case HandbooksActionTypes.CHANGE_HANDBOOK_VALUE: {
      const changedHandbookArray = [...state.handbooks[action.name]]
      const changedHandbookIndexIndex = changedHandbookArray.findIndex((item: IHandbook) => item.key === action.key)
      changedHandbookArray[changedHandbookIndexIndex] = { ...action.payload }

      return {
        ...state,
        handbooks: {
          ...state.handbooks,
          [action.name]: changedHandbookArray
        }
      }
    }

    case HandbooksActionTypes.RECEIVE_RELATIONS:
      return {
        ...state,
        relations: {
          ...state.relations,
          [action.name]: [...action.payload]
        }
      }

    case HandbooksActionTypes.RECEIVE_SINGLE_VALUE_RELATIONS:
      return {
        ...state,
        singleValueRelations: {
          ...state.singleValueRelations,
          ...action.payload
        }
      }

    case HandbooksActionTypes.RECEIVE_SINGLE_VALUE_RELATIONS_TABLE: {
      const newSingleValueRelationsTable = [...state.singleValueRelationsTable]
      newSingleValueRelationsTable[action.row] = { ...state.singleValueRelationsTable?.[action.row], ...action.payload }
      return {
        ...state,
        singleValueRelationsTable: [...newSingleValueRelationsTable]
      }
    }

    case HandbooksActionTypes.SET_SINGLE_VALUE_RELATIONS_TABLE:
      return {
        ...state,
        singleValueRelationsTable: action.payload,
      }

    case HandbooksActionTypes.DELETE_HANDBOOK_RELATION: {
      const { dictType, key, relatedDictType, relatedKey } = action.payload

      const dictionaries = state.relations[dictType]
      if (!dictionaries) return state

      const dictionary = dictionaries.find((item) => item.key === key)

      if (!dictionary) return state
      const { relations } = dictionary
      if (!relations) return state

      return {
        ...state,
        relations: {
          ...state.relations,
          [dictType]: dictionaries.map((item) => {
            if (item.key !== key) return item

            return {
              ...item,
              relations: {
                ...relations,
                [relatedDictType]: relations[relatedDictType].filter((item) => item.key !== relatedKey)
              }
            }
          })
        }
      }
    }

    case HandbooksActionTypes.DELETE_HANDBOOK_RELATIONS:
      return { ...state }

    case HandbooksActionTypes.ADD_RELATIONS:
      return { ...state }

    case HandbooksActionTypes.UPDATE_RELATIONS:
      return { ...state }

    case HandbooksActionTypes.IMPORT_VALUES:
      return { ...state }

    case HandbooksActionTypes.GET_HANDBOOK_VALUES:
      return {
        ...state,
        handbooks: {
          ...state.handbooks,
          [action.name]: [...action.payload]
        }
      }

    default:
      return state
  }
}
