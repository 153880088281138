import Loadable from 'react-loadable'
import { SpinnerWrapper } from '@containers/Navigation'
import { Path, Role, RouteName } from '@const/consts'
import { IRoute } from '@common/Routing/components/RouteWithSubRoutes/types'

const Groups = Loadable({
  loader: () => import('@views/administration/components/Groups/Groups'),
  loading: SpinnerWrapper
})

const Group = Loadable({
  loader: () => import('@views/administration/components/Groups/components/Group'),
  loading: SpinnerWrapper
})

const Divisions = Loadable({
  loader: () => import('@views/administration/components/Divisions'),
  loading: SpinnerWrapper
})

const Users = Loadable({
  loader: () => import('@views/administration/components/Users'),
  loading: SpinnerWrapper
})

const User = Loadable({
  loader: () => import('@views/administration/components/User/User'),
  loading: SpinnerWrapper
})

const Invites = Loadable({
  loader: () => import('@views/administration/components/Users/components/Invites'),
  loading: SpinnerWrapper
})

const UserInvite = Loadable({
  loader: () => import('@views/administration/components/User/Invite'),
  loading: SpinnerWrapper
})

const Routes = Loadable({
  loader: () => import('@views/administration/components/Routes'),
  loading: SpinnerWrapper
})

const Route = Loadable({
  loader: () => import('@views/administration/components/Route'),
  loading: SpinnerWrapper
})

const Handbooks = Loadable({
  loader: () => import('@views/administration/components/Handbooks/Handbooks'),
  loading: SpinnerWrapper
})

const DocumentType = Loadable({
  loader: () => import('@views/administration/components/metadata/DocumentTypes/components/DocumentType'),
  loading: SpinnerWrapper
})

const DocumentTypes = Loadable({
  loader: () => import('@views/administration/components/metadata/DocumentTypes/components/DocumentTypes'),
  loading: SpinnerWrapper
})

const Field = Loadable({
  loader: () => import('@views/administration/components/metadata/Fields/components/Field'),
  loading: SpinnerWrapper
})

const Fields = Loadable({
  loader: () => import('@views/administration/components/metadata/Fields/components/Fields'),
  loading: SpinnerWrapper
})

const Requisites = Loadable({
  loader: () => import('@views/administration/components/Requisites'),
  loading: SpinnerWrapper
})

const Constraints = Loadable({
  loader: () => import('@views/administration/components/Constraints'),
  loading: SpinnerWrapper
})

const Constraint = Loadable({
  loader: () => import('@views/administration/components/Constraints/components/Constraint'),
  loading: SpinnerWrapper
})

const AccessGroups = Loadable({
  loader: () => import('@views/administration/components/AccessGroups'),
  loading: SpinnerWrapper
})

export const routes: IRoute[] = [
  {
    path: Path.USERS_ALL,
    exact: true,
    name: 'Users',
    component: Users,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.USERS_INVITES_ALL,
    exact: true,
    name: 'Invites',
    component: Invites,
    routeAdditionalProp: RouteName.INVITES,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.USERS_INVITES_ADD,
    exact: true,
    name: 'UserInvite',
    component: UserInvite,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.USERS_INVITES_EDIT,
    exact: true,
    name: 'UserInvite',
    component: UserInvite,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.USERS_ADD,
    exact: true,
    name: 'User',
    component: User,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.USERS_EDIT,
    exact: true,
    name: 'User',
    component: User,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.DIVISIONS_ALL,
    exact: true,
    name: 'Divisions',
    component: Divisions,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.GROUPS_ALL,
    exact: true,
    name: 'Groups',
    component: Groups,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.GROUPS_ADD,
    exact: true,
    name: 'GroupsAdd',
    component: Group,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.GROUPS_EDIT,
    exact: true,
    name: 'GroupId',
    component: Group,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.ROUTES_ALL,
    exact: true,
    name: 'Routes',
    component: Routes,
    roles: [Role.ADMINISTRATOR],
    isFlowFunctionalityEnabled: true
  },
  {
    path: Path.ROUTES_ADD,
    exact: true,
    name: 'RouteAdd',
    component: Route,
    roles: [Role.ADMINISTRATOR],
    isFlowFunctionalityEnabled: true
  },
  {
    path: Path.ROUTES_EDIT,
    exact: true,
    name: 'Route',
    component: Route,
    roles: [Role.ADMINISTRATOR],
    isFlowFunctionalityEnabled: true
  },
  {
    path: Path.HANDBOOKS_ALL,
    exact: true,
    name: 'Handbooks',
    component: Handbooks,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.FIELDS_ALL,
    exact: true,
    name: 'Fields',
    component: Fields,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.FIELDS_ADD,
    exact: true,
    name: 'FieldAdd',
    component: Field,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.FIELDS_EDIT,
    exact: true,
    name: 'FieldId',
    component: Field,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.DOCUMENT_TYPES_ALL,
    exact: true,
    name: 'DocumentTypes',
    component: DocumentTypes,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.DOCUMENT_TYPES_ADD,
    exact: true,
    name: 'DocumentTypeAdd',
    component: DocumentType,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.DOCUMENT_TYPES_EDIT,
    exact: true,
    name: 'DocumentTypeId',
    component: DocumentType,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.REQUISITES,
    exact: true,
    name: 'Requisites',
    component: Requisites,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.CONSTRAINTS_ALL,
    exact: true,
    name: 'Constraints',
    component: Constraints,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.CONSTRAINTS_ADD,
    exact: true,
    name: 'ConstraintsAdd',
    component: Constraint,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.CONSTRAINTS_EDIT,
    exact: true,
    name: 'ConstraintsEdit',
    component: Constraint,
    roles: [Role.ADMINISTRATOR]
  },
  {
    path: Path.ACCESS_GROUPS_ALL,
    exact: true,
    name: 'AccessGroups',
    component: AccessGroups,
    roles: [Role.ADMINISTRATOR]
  }
]
