import { AxiosResponse, AxiosRequestConfig } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IAddGroup, IGroup, IMember } from '@store/modules/group/types'

class GroupsService extends AbstractHttpService {
  private readonly url = urls.groups

  async getGroups(config?: AxiosRequestConfig): Promise<AxiosResponse<IGroup[]>> {
    const url = this.url.list

    return await this._http.get(url, config)
  }

  async getGroup(oguid: string, config?: AxiosRequestConfig): Promise<AxiosResponse<IGroup>> {
    const url = this.url.single.replace('{{ oguid }}', oguid)

    return await this._http.get(url, config)
  }

  async getGroupMembers(groupOguid: string, config?: AxiosRequestConfig): Promise<AxiosResponse<IMember[]>> {
    const url = this.url.members.add.replace('{{ oguid }}', groupOguid)

    return await this._http.get(url, config)
  }

  async addMember(groupOguid: string, memberOguids: string[]): Promise<AxiosResponse<IMember[]>> {
    const url = this.url.members.add.replace('{{ oguid }}', groupOguid)

    return await this._http.post(url, memberOguids)
  }

  async removeMember(groupOguid: string, memberOguid: string): Promise<AxiosResponse> {
    const url = this.url.members.remove.replace('{{ oguid }}', groupOguid).replace('{{ memberOguid }}', memberOguid)

    return await this._http.delete(url)
  }

  async addGroup(groupName: IAddGroup): Promise<AxiosResponse<IGroup>> {
    const url = this.url.list

    return await this._http.post(url, groupName)
  }

  async updateGroup(groupOguid: string, groupData: IAddGroup): Promise<AxiosResponse<IGroup>> {
    const url = this.url.single.replace('{{ oguid }}', groupOguid)

    return await this._http.put(url, groupData)
  }

  async removeGroup(groupOguid: string): Promise<AxiosResponse> {
    const url = this.url.single.replace('{{ oguid }}', groupOguid)

    return await this._http.delete(url)
  }
}

export default new GroupsService()
