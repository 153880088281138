import React, { ReactElement, Suspense } from 'react'
import { Provider } from 'react-redux'

import { LayoutRouter } from '@containers'
import store from '@store/configureStore'
import Startup from '@views/startup/components/Startup'
import ButtonInstance from '@common/ButtonInstance'
import { SpinnerWrapper } from '@containers/Navigation'

import { isDevelopment } from '@const/consts'
import '@infologistics/frontend-libraries/dist/styles/styles.css'
import '@common/CryptoPro/cadesplugin_api'

export default class App extends React.Component {
  public render(): ReactElement {
    return (
      <Provider store={store}>
        <Suspense fallback={<SpinnerWrapper />}>
          <Startup>
            <LayoutRouter />
          </Startup>

          {isDevelopment && <ButtonInstance />}
        </Suspense>
      </Provider>
    )
  }
}
