import { Actions, IUserFilter, UserFiltersActionTypes } from './types'

export const initialState: IUserFilter[] = []

export default function navigationReducer(state = initialState, action: Actions): IUserFilter[] {
  switch (action.type) {
    case UserFiltersActionTypes.SET_USER_FILTERS:
      return [...action.payload]

    case UserFiltersActionTypes.ADD_USER_FILTER:
      return [...state, action.payload]

    case UserFiltersActionTypes.DELETE_USER_FILTER:
      return state.filter((filter) => filter.oguid !== action.payload)

    case UserFiltersActionTypes.UPDATE_USER_FILTER:
      return state.map((filter) => (filter.oguid === action.payload.oguid ? action.payload : filter))

    default:
      return state
  }
}
