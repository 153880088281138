import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { ILibPagination } from '@infologistics/frontend-libraries'
import { Nullable } from '@store/types/commonTypes'

export enum HandbooksActionTypes {
  ADD_HANDBOOK_VALUE = 'ADD_HANDBOOK_VALUE',
  ADD_RELATIONS = 'ADD_RELATIONS',
  CHANGE_HANDBOOK_VALUE = 'CHANGE_HANDBOOK_VALUE',
  DELETE_HANDBOOK = 'DELETE_HANDBOOK',
  DELETE_HANDBOOK_RELATION = 'DELETE_HANDBOOK_RELATION',
  DELETE_HANDBOOK_RELATIONS = 'DELETE_HANDBOOK_RELATIONS',
  DELETE_HANDBOOK_VALUE = 'DELETE_HANDBOOK_VALUE',
  GET_HANDBOOK_NAMES = 'GET_HANDBOOK_NAMES',
  GET_HANDBOOK_VALUES = 'GET_HANDBOOK_VALUES',
  IMPORT_VALUES = 'IMPORT_VALUES',
  RECEIVE_RELATIONS = 'RECEIVE_RELATIONS',
  RECEIVE_SINGLE_VALUE_RELATIONS = 'RECEIVE_SINGLE_VALUE_RELATIONS',
  RECEIVE_SINGLE_VALUE_RELATIONS_TABLE = 'RECEIVE_SINGLE_VALUE_RELATIONS_TABLE',
  RESET_SINGLE_VALUE_RELATIONS = 'RESET_SINGLE_VALUE_RELATIONS',
  RESET_SINGLE_VALUE_RELATION_TABLE = 'RESET_SINGLE_VALUE_RELATION_TABLE',
  SET_SINGLE_VALUE_RELATIONS_TABLE = 'SET_SINGLE_VALUE_RELATIONS_TABLE',
  RESET_SINGLE_VALUE_RELATION = 'RESET_SINGLE_VALUE_RELATION',
  RESET_HANDBOOKS = 'RESET_HANDBOOKS',
  UPDATE_RELATIONS = 'UPDATE_RELATIONS'
}

export interface IHandbook {
  key: string
  value: string
}

export interface IRelation {
  [key: string]: string[]
}

export interface IHandbookRelations {
  [key: string]: IHandbook[]
}

export interface IRelations {
  key: string
  value: string
  relations: Nullable<IHandbookRelations>
}

export interface IHandbooksState {
  handbookNames: string[]
  handbooks: {
    [key: string]: IHandbook[]
  }
  relations: {
    [key: string]: IRelations[]
  }
  singleValueRelations: IHandbookRelations
  singleValueRelationsTable: IHandbookRelations[]
}

// TODO get rid of ANY in payload
export interface IActionGetHandbookNames extends Action<string> {
  payload: any
  type: HandbooksActionTypes.GET_HANDBOOK_NAMES
}

// TODO get rid of ANY in payload
export interface IActionGetHandbookValues extends Action<string> {
  name: string
  pagination: ILibPagination
  payload: any
  type: HandbooksActionTypes.GET_HANDBOOK_VALUES
}

// TODO get rid of ANY in payload
export interface IActionDeleteHandbook extends Action<string> {
  name: string
  payload: any
  type: HandbooksActionTypes.DELETE_HANDBOOK
}

// TODO get rid of ANY in payload
export interface IActionAddHandbookValue extends Action<string> {
  name: string
  payload: any
  type: HandbooksActionTypes.ADD_HANDBOOK_VALUE
}

// TODO get rid of ANY in payload
export interface IActionDeleteHandbookValue extends Action<string> {
  key: string
  name: string
  payload: any
  type: HandbooksActionTypes.DELETE_HANDBOOK_VALUE
}

// TODO get rid of ANY in payload
export interface IActionChangeHandbookValue extends Action<string> {
  key: string
  name: string
  payload: any
  type: HandbooksActionTypes.CHANGE_HANDBOOK_VALUE
}

export interface IActionAddRelations extends Action<string> {
  payload: IRelation
  type: HandbooksActionTypes.ADD_RELATIONS
}

export interface IActionReceiveRelations extends Action<string> {
  name: string
  payload: IRelations[]
  type: HandbooksActionTypes.RECEIVE_RELATIONS
}

export interface IActionReceiveSIngleValueRelations extends Action<string> {
  name: string
  payload: IHandbookRelations
  type: HandbooksActionTypes.RECEIVE_SINGLE_VALUE_RELATIONS
}

export interface IActionReceiveSIngleValueRelationsTable extends Action<string> {
  name: string
  payload: IHandbookRelations
  row: number
  type: HandbooksActionTypes.RECEIVE_SINGLE_VALUE_RELATIONS_TABLE
}

export interface IActionResetSingleValueRelations extends Action<string> {
  type: HandbooksActionTypes.RESET_SINGLE_VALUE_RELATIONS
}

export interface IActionResetSingleValueRelationTable extends Action<string> {
  payload: string
  row: number
  type: HandbooksActionTypes.RESET_SINGLE_VALUE_RELATION_TABLE
}

export interface IActionSetSingleValueRelationsTable extends Action<string> {
  payload: IHandbookRelations[]
  type: HandbooksActionTypes.SET_SINGLE_VALUE_RELATIONS_TABLE
}

export interface IActionResetSingleValueRelation extends Action<string> {
  payload: string
  type: HandbooksActionTypes.RESET_SINGLE_VALUE_RELATION
}

export interface IActionResetHandbooks extends Action<string> {
  type: HandbooksActionTypes.RESET_HANDBOOKS
}

export interface IDeleteRelationPayload {
  dictType: string
  key: string
  relatedDictType: string
  relatedKey: string
}

export interface IActionDeleteHandbookRelation extends Action<string> {
  payload: IDeleteRelationPayload
  type: HandbooksActionTypes.DELETE_HANDBOOK_RELATION
}

// TODO get rid of ANY in payload
export interface IActionDeleteHandbookRelations extends Action<string> {
  payload: any
  type: HandbooksActionTypes.DELETE_HANDBOOK_RELATIONS
}

export interface IActionUpdateRelations extends Action<string> {
  payload: IRelation
  type: HandbooksActionTypes.UPDATE_RELATIONS
}

// TODO get rid of ANY in payload
export interface IActionImportValues extends Action<string> {
  payload: any
  type: HandbooksActionTypes.IMPORT_VALUES
}

export type Actions =
  | IActionAddHandbookValue
  | IActionAddRelations
  | IActionChangeHandbookValue
  | IActionDeleteHandbook
  | IActionDeleteHandbookRelation
  | IActionDeleteHandbookRelations
  | IActionDeleteHandbookValue
  | IActionGetHandbookNames
  | IActionGetHandbookValues
  | IActionImportValues
  | IActionReceiveRelations
  | IActionResetSingleValueRelations
  | IActionResetSingleValueRelationTable
  | IActionSetSingleValueRelationsTable
  | IActionResetSingleValueRelation
  | IActionResetHandbooks
  | IActionUpdateRelations
  | IActionReceiveSIngleValueRelations
  | IActionReceiveSIngleValueRelationsTable

type ActionKeys =
  | 'addHandbookValue'
  | 'addRelations'
  | 'changeHandbookValue'
  | 'deleteHandbook'
  | 'deleteHandbookRelation'
  | 'deleteHandbookRelations'
  | 'deleteHandbookValue'
  | 'getHandbookNames'
  | 'getHandbookValues'
  | 'importValues'
  | 'receiveRelations'
  | 'updateRelations'
  | 'receiveSingleValueRelations'
  | 'receiveSingleValueRelationsTable'
  | 'resetSingleValueRelationTable'
  | 'setSingleValueRelationsTable'

export type HandbooksActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type HandbooksThunkAction = ThunkAction<void, IHandbooksState, void, Action<string>>

export type HandbooksThunkDispatch = ThunkDispatch<IHandbooksState, void, Action<string>>
