import {document, emptySignInfo, flowState} from './consts'
import { Actions, DocumentsActionTypes, IDocumentsState } from './types'

export const initialState: IDocumentsState = {
  attachments: [],
  currentDocument: document,
  currentDocumentFileFields: {},
  currentDocumentFiles: {
    data: [],
    isAvailable: true,
    total: 0
  },
  currentDocumentSignInfo: emptySignInfo,
  documents: [],
  draftDocuments: {
    current: {},
    idList: []
  },
  draftMessages: {
    current: {},
    idList: []
  },
  messages: [],
  selectedDocuments: {},
  relations: {
    relatedDocuments: [],
    packageDocuments: []
  },
  flowState: {
    processOguid: '',
    routeOguid: '',
    stages: []
  },
  todos: []
}

export default function documentsReducer(state: IDocumentsState = initialState, action: Actions): IDocumentsState {
  switch (action.type) {
    case DocumentsActionTypes.SET_ATTACHMENTS:
      return {
        ...state,
        attachments: [...action.payload]
      }

    case DocumentsActionTypes.CLEAR_CURRENT_DOCUMENT_FILES:
      return {
        ...state,
        currentDocumentFiles: { ...initialState.currentDocumentFiles }
      }

    case DocumentsActionTypes.CLEAR_CURRENT_DRAFT_DOCUMENTS:
      return {
        ...state,
        draftDocuments: { ...initialState.draftDocuments }
      }

    case DocumentsActionTypes.CLEAR_CURRENT_DRAFT_MESSAGES:
      return {
        ...state,
        draftMessages: { ...initialState.draftMessages }
      }

    case DocumentsActionTypes.REMOVE_CURRENT_DRAFT_DOCUMENT:
      return {
        ...state,
        draftDocuments: {
          ...action.payload
        }
      }

    case DocumentsActionTypes.REMOVE_CURRENT_DRAFT_MESSAGE:
      return {
        ...state,
        draftMessages: {
          ...action.payload
        }
      }

    case DocumentsActionTypes.RESET_DOCUMENTS:
      return {
        ...initialState
      }

    case DocumentsActionTypes.SET_DOCUMENTS:
      return {
        ...state,
        documents: [...action.payload],
        selectedDocuments: {}
      }

    case DocumentsActionTypes.SET_DOCUMENTS_MESSAGES:
      return {
        ...state,
        messages: [...action.payload],
        selectedDocuments: {}
      }

    case DocumentsActionTypes.SELECT_DOCUMENT:
      return {
        ...state,
        selectedDocuments: {
          ...state.selectedDocuments,
          [action.payload]: !state.selectedDocuments[action.payload]
        }
      }

    case DocumentsActionTypes.SELECT_DOCUMENTS:
      return {
        ...state,
        selectedDocuments: {
          ...action.payload
        }
      }

    case DocumentsActionTypes.SET_CURRENT_DOCUMENT:
      return {
        ...state,
        currentDocument: action.payload
      }

    case DocumentsActionTypes.RESET_CURRENT_DOCUMENT:
      return {
        ...state,
        currentDocument: document
      }

    case DocumentsActionTypes.SET_CURRENT_DOCUMENT_FILE_FIELDS:
      return {
        ...state,
        currentDocumentFileFields: action.payload
      }

    case DocumentsActionTypes.SET_CURRENT_DOCUMENT_FILES:
      return {
        ...state,
        currentDocumentFiles: action.payload
      }

    case DocumentsActionTypes.SET_CURRENT_DOCUMENT_SIGN_INFO:
      return {
        ...state,
        currentDocumentSignInfo: action.payload
      }

    case DocumentsActionTypes.SET_CURRENT_DRAFT_DOCUMENT:
      return {
        ...state,
        draftDocuments: {
          ...action.payload
        }
      }

    case DocumentsActionTypes.SET_CURRENT_DRAFT_MESSAGE:
      return {
        ...state,
        draftMessages: {
          ...action.payload
        }
      }

    case DocumentsActionTypes.UPDATE_CURRENT_DRAFT_DOCUMENT:
      return {
        ...state,
        draftDocuments: {
          ...action.payload
        }
      }

    case DocumentsActionTypes.UPDATE_CURRENT_DRAFT_MESSAGE:
      return {
        ...state,
        draftMessages: {
          ...action.payload
        }
      }

    case DocumentsActionTypes.SET_DOCUMENT_RELATIONS:
      return {
        ...state,
        relations: {
          ...action.payload
        }
      }

    case DocumentsActionTypes.SET_FLOW_STATE: {
      const { stages } = action.payload
      // remove forEach if the 'tasks' structure will be renamed to 'assignees'
      // in GET orgs/${orgOguid}/flows/entities/{flowEntityOguid}/flowState
      // in the same way as in GET/POST orgs/${orgOguid}/flows/entities/{flowEntityOguid}/flowStart
      stages?.forEach((stage) => {
        stage.assignees = [...stage.tasks]
        stage.tasks = []
      })

      return {
        ...state,
        flowState: { ...action.payload, stages }
      }
    }

    case DocumentsActionTypes.RESET_FLOW_STATE:
      return {
        ...state,
        flowState: flowState
      }

    case DocumentsActionTypes.SET_TODOS:
      return {
        ...state,
        todos: [...action.payload]
      }

    case DocumentsActionTypes.RESET_SELECTED_DOCUMENT:
      return {
        ...state,
        selectedDocuments: {}
      }

    case DocumentsActionTypes.SET_RELOAD_CURRENT_DOCUMENT_FILE:
      return {
        ...state,
        currentDocument: { ...state.currentDocument, isReloadFile: action.payload}
      }

    default:
      return state
  }
}
