import { connect } from 'react-redux'

import Messages from '@common/Messages/Messages/Messages'

import { actions, getMessages } from '@store/modules/messages/actions'
import { toggleMessages } from '@store/modules/utils/actions'

import { IApplicationState, IParams, Nullable } from '@store/types/commonTypes'
import {
  IMessagesPropsFromDispatch as IPropsFromDispatch,
  IMessagesPropsFromState as IPropsFromState
} from '@containers/Messages/Messages/types'
import { getAllBadges } from '@app/store/modules/navigation/actions'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isLoading: state.messages.isLoading,
  messages: state.messages.messages,
  orgOguid: state.user.activeOrganization.oguid,
  isLoadingFromActionButtons: state.messages.isLoadingFromActionButtons
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  onGetBadges: () => dispatch(getAllBadges()),
  onClearMessages: () => dispatch(actions.setMessages([])),
  onGetMessages: (params: IParams) => dispatch(getMessages(params)),
  onToggleMessages: (areMessagesShown: boolean) => dispatch(toggleMessages(areMessagesShown)),
  onToggleLoader: (isLoading: boolean) => dispatch(actions.toggleLoader(isLoading)),
  onSetMessagesPage: (page: Nullable<number>) => dispatch(actions.setMassagesPage(page))
})

export default connect(mapStateToProps, mapDispatchToProps)(Messages)
