import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/browser'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import './i18n'

import { sentryAllowUrls, sentryIgnoreErrors } from '@const/consts'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    integrations: [new BrowserTracing()],
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    allowUrls: sentryAllowUrls,
    ignoreErrors: sentryIgnoreErrors,
    tracesSampleRate: 1.0
  })
}

ReactDOM.render(<App />, document.getElementById('app-root'))
