import { Actions, IMessagesState, MessagesActionTypes } from './types'

export const initialState: IMessagesState = {
  messages: [],
  responses: {},
  isLoading: false,
  isLoadingFromActionButtons: false,
  page: 1
}

export default function messagesReducer(state = initialState, action: Actions): IMessagesState {
  switch (action.type) {
    case MessagesActionTypes.SET_MESSAGES:
      return {
        ...state,
        messages: [...action.payload]
      }

    case MessagesActionTypes.SET_MESSAGES_PAGE:
      return {
        ...state,
        page: action.payload
      }

    case MessagesActionTypes.SET_LOADED_FROM_ACTION_BUTTONS:
      return {
        ...state,
        isLoadingFromActionButtons: action.payload
      }

    case MessagesActionTypes.SET_MESSAGES_RESPONSES:
      return {
        ...state,
        responses: { ...action.payload }
      }

    case MessagesActionTypes.TOGGLE_MESSAGES_LOADER:
      return {
        ...state,
        isLoading: action.payload
      }

    default:
      return state
  }
}
