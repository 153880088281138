import React, { FC, ReactNode, useEffect, useRef, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import { debounce } from 'lodash'

import { Button, Spinner } from '@infologistics/frontend-libraries'

import { NoticeCategory, SCROLL_CHECK_BOTTOM_DELAY } from '@const/consts'
import { displayErrorNotification, displaySuccessNotification, getNoticesParams } from '@utils/utils'
import { Notices as NoticesTranslate } from '@const/translations'
import { getBadgesNotices } from '@store/modules/navigation/actions'
import { actions, getNotices, setNoticesAction } from '@store/modules/notices/actions'
import flowsService from '@services/flows'
import Notice from '../Notice'
import { IApplicationState } from '@store/types/commonTypes'
import { INoticesTabProps as IProps } from './types'

import styles from './NoticesTab.module.css'

const NoticesTab: FC<IProps> = ({ isInfo = false }) => {
  const { t } = useTranslation(['tasks'])
  const { isLoading, notices, page } = useSelector((state: IApplicationState) => state.notices)

  const dispatch = useDispatch<any>()
  const refContainer = useRef<HTMLDivElement>(null)
  const isLoadingMore = page && page > 1

  useLayoutEffect(() => {
    dispatch(actions.setNoticesPage(1))
    dispatch(setNoticesAction([]))
    getMessages(1)
  }, [isInfo])

  useEffect(() => {
    if (isLoadingMore) {
      page && getMessages(page, true)
    }
  }, [page])

  const getMessages = (pageNum: number, isPushMessages = false): void => {
    const params = getNoticesParams(getCategory(), pageNum)

    dispatch(actions.toggleLoader(true))

    Promise.all([dispatch(getNotices(params, isPushMessages)), dispatch(getBadgesNotices())])
      .finally(() => dispatch(actions.toggleLoader(false)))
      .catch(displayErrorNotification)
  }

  const renderLoader = (): ReactNode => (
    <div className={cn(styles.loader, 'absolute')}>
      <Spinner />
    </div>
  )

  const renderTab = (): ReactNode => (
    notices.length
      ? <>
          { isLoading && renderLoader() }
          { notices.map(notice => (
              <Notice
                key={notice.oguid}
                notice={notice}
                isInfo={isInfo}
                updateNoticesList={updateNoticesList}
              />
          ))}
        </>
      : <p className='p-4 font-default'>{t('tasks:noMessages')}</p>
  )

  const handleScroll = (): void => {
    const clientHeightContainer = refContainer.current?.clientHeight ?? 0
    const scrollHeightContainer = refContainer.current?.scrollHeight ?? 0
    const scrollTopContainer = refContainer.current?.scrollTop ?? 0
    const scrollBottomSpace =
      (scrollHeightContainer - clientHeightContainer) - scrollTopContainer

    if (scrollBottomSpace < 5 && page && !isLoading) {
      dispatch(actions.setNoticesPage(page + 1))
      dispatch(actions.toggleLoader(true))
    }
  }

  const updateNoticesList = (): void => {
    const params = getNoticesParams(getCategory())

    dispatch(getNotices(params))
      .then(() => dispatch(actions.toggleLoader(false)))
      .catch(displayErrorNotification)

    dispatch(getBadgesNotices())
      .catch(displayErrorNotification)
  }

  const clearAllCategoryNotices = (): void => {
    dispatch(actions.toggleLoader(true))

    flowsService.markNoticeCategoryIsRead(getCategory())
      .then(() => {
        displaySuccessNotification({ title: t(NoticesTranslate.categoryDeleted) })
        updateNoticesList()
      })
      .catch((err) => {
        dispatch(actions.toggleLoader(false))
        displayErrorNotification(err)
      })
  }

  const getCategory = (): NoticeCategory => (
    isInfo ? NoticeCategory.INFO : NoticeCategory.NEED_TO_ACTION
  )

  return (
    <>
      <div
        ref={refContainer}
        className={cn(styles.scroll, 'overflow-y scrollbar-sidebar')}
        onScroll={debounce(handleScroll, SCROLL_CHECK_BOTTOM_DELAY)}
      >
        { (isLoading && page === 1) ? renderLoader() : renderTab() }
      </div>
      {notices.length > 0 && (
        <div className={styles.clear_block}>
          <Button classes={styles.clear_btn} onClick={clearAllCategoryNotices}>{t(NoticesTranslate.clear)}</Button>
        </div>
      )}
    </>
  )
}

export default NoticesTab
