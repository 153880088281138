import { AxiosResponse } from 'axios'

import AbstractHttpService from '@services/abstractHttpService'

import urls from '@const/urls'
import {
  IAccessGroup,
  IAccessGroupForServer,
  IDocumentTypeForServer,
  IDocumentTypeFromServer,
  IDocumentTypeGroup,
  IDocumentTypesFromServer,
  IFlowStageType,
  IHistoryStateCodes,
  ISingleAccessGroup,
  IUserDocumentTypesFromServer,
  IWorkflowStatuses
} from '@store/modules/metadata/types'
import { UserRole } from '@const/consts'
import { IGroup, Nullable } from '@store/types/commonTypes'
import { IField, IUpdatedField } from '@views/administration/components/metadata/Fields/components/Field/types'

class MetadataService extends AbstractHttpService {
  private readonly url = urls.metadata

  // `${BASE_URL}orgs/${orgOguid}/documentTypes`
  async addDocumentType(data: IDocumentTypeForServer): Promise<AxiosResponse<IDocumentTypeForServer>> {
    const url = this.url.documentTypes.list

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/documentTypes`
  async getDocumentType(documentTypeKey: string): Promise<AxiosResponse<IDocumentTypeFromServer>> {
    const url = this.url.documentTypes.single.replace('{{ documentTypeKey }}', documentTypeKey)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documentTypes`
  async updateDocumentType(documentTypeKey: string, data: IDocumentTypeForServer): Promise<AxiosResponse<IDocumentTypeForServer>> {
    const url = this.url.documentTypes.single.replace('{{ documentTypeKey }}', documentTypeKey)

    return await this._http.put(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/documentTypes`
  async deleteDocumentType(documentTypeKey: string): Promise<AxiosResponse> {
    const url = this.url.documentTypes.single.replace('{{ documentTypeKey }}', documentTypeKey)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documentTypes/{{ documentTypeKey }}/template`
  async addDocTypeTemplate(documentTypeKey: string, file: FormData): Promise<AxiosResponse> {
    const url = this.url.documentTypes.template.replace('{{ documentTypeKey }}', documentTypeKey)

    return await this._http.post(url, file)
  }

  // `${BASE_URL}orgs/${orgOguid}/documentTypes/{{ documentTypeKey }}/template`
  async deleteDocTypeTemplate(documentTypeKey: string): Promise<AxiosResponse> {
    const url = this.url.documentTypes.template.replace('{{ documentTypeKey }}', documentTypeKey)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documentTypes/{{ documentTypeKey }}/template`
  async getDocTypeTemplate(documentTypeKey: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.documentTypes.template.replace('{{ documentTypeKey }}', documentTypeKey)

    return await this._http.get(url, { responseType: 'blob' })
  }

  // `${BASE_URL}orgs/${orgOguid}/documentGroup`
  async addDocTypeGroup(name: string): Promise<AxiosResponse<string>> {
    const url = this.url.docTypeGroups.list
    const body = { name }

    return await this._http.post(url, body)
  }

  // `${BASE_URL}orgs/${orgOguid}/documentGroup/{{ oguid }}`
  async deleteDocTypeGroup(oguid: string): Promise<AxiosResponse> {
    const url = this.url.docTypeGroups.single.replace('{{ oguid }}', oguid)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documentGroup/{{ oguid }}`
  async editDocTypeGroup({ name, oguid }: IDocumentTypeGroup): Promise<AxiosResponse<string>> {
    const url = this.url.docTypeGroups.single.replace('{{ oguid }}', oguid)
    const body = { name }

    return await this._http.put(url, body)
  }

  // `${BASE_URL}orgs/${orgOguid}/documentGroup`
  async getDocTypeGroups(): Promise<AxiosResponse<IGroup[]>> {
    const url = this.url.docTypeGroups.list

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/types/accessGroups`
  async getAccessGroups(): Promise<AxiosResponse<IAccessGroup[]>> {
    const url = this.url.accessGroups.list

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/types/accessGroups`
  async addAccessGroup(data: IAccessGroupForServer): Promise<AxiosResponse<ISingleAccessGroup>> {
    const url = this.url.accessGroups.list

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/types/accessGroups/{{ oguid }}`
  async deleteAccessGroup(oguid: string): Promise<AxiosResponse> {
    const url = this.url.accessGroups.single.replace('{{ oguid }}', oguid)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/types/accessGroups/{{ oguid }}`
  async editAccessGroup(data: IAccessGroupForServer, oguid: string): Promise<AxiosResponse<ISingleAccessGroup>> {
    const url = this.url.accessGroups.single.replace('{{ oguid }}', oguid)

    return await this._http.put(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/types/accessGroups/{{ oguid }}`
  async getAccessGroup(oguid: string): Promise<AxiosResponse<ISingleAccessGroup>> {
    const url = this.url.accessGroups.single.replace('{{ oguid }}', oguid)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/types/role/{{ userRole }}/accessGroup/{{ oguid }}`
  async editRoleAccessGroup(role: UserRole, oguid: string): Promise<AxiosResponse> {
    const url = this.url.accessGroups.roleGroup.edit
      .replace('{{ userRole }}', role)
      .replace('{{ oguid }}', oguid)

    return await this._http.put(url, null)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/types/role/{{ userRole }}/accessGroup`
  async getRoleAccessGroup(role: UserRole): Promise<AxiosResponse<Nullable<ISingleAccessGroup>>> {
    const url = this.url.accessGroups.roleGroup.get.replace('{{ userRole }}', role)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/userTypes`
  // temporary solution
  // type IUserDocumentTypesFromServer is added for compatibility
  // since the fields structure on the backend is [], but throughout the entire project it is still {}
  async getUserDocTypes(): Promise<AxiosResponse<IUserDocumentTypesFromServer>> {
    const url = this.url.userDocTypes

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/fields`
  async getFields(): Promise<AxiosResponse<IField[]>> {
    const url = this.url.fields.list

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/fields`
  async addField(data: IField): Promise<AxiosResponse<IField>> {
    const url = this.url.fields.list

    return await this._http.post(url, data)
  }

  // `${BASE_URL}orgs/${orgOguid}/fields/{{ fieldKey }}`
  async deleteField(fieldKey: string): Promise<AxiosResponse> {
    const url = this.url.fields.single.replace('{{ fieldKey }}', fieldKey)

    return await this._http.delete(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/fields/{{ fieldKey }}`
  async updateField(fieldKey: string, data: IUpdatedField): Promise<AxiosResponse<IField>> {
    const url = this.url.fields.single.replace('{{ fieldKey }}', fieldKey)

    return await this._http.put(url, data)
  }

  async sortFieldsOrder(sortedFields: string[]): Promise<AxiosResponse> {
    const url = this.url.fields.sort

    return await this._http.post(url, sortedFields)
  }

  // `${BASE_URL}orgs/${orgOguid}/routes/flowStageNames`
  async getFlowStageNames(): Promise<AxiosResponse<Nullable<string[]>>> {
    const url = this.url.routes.names

    return await this._http.get(url)
  }

  // `${BASE_URL}routes/flowStageTypes`
  async getFlowStageTypes(): Promise<AxiosResponse<IFlowStageType[]>> {
    const url = this.url.routes.types

    return await this._http.get(url, {}, false)
  }

  // `${BASE_URL}orgs/${orgOguid}/dicts`
  async getDictTypes(): Promise<AxiosResponse<string[]>> {
    const url = this.url.dicts

    return await this._http.get(url)
  }


  // `${BASE_URL}orgs/${orgOguid}/documents/types`
  // temporary solution
  // type IUserDocumentTypesFromServer is added for compatibility
  // since the fields structure on the backend is [], but throughout the entire project it is still {}
  async getDocumentTypes(): Promise<AxiosResponse<IDocumentTypesFromServer>> {
    const url = this.url.documents.types

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/workflowStatuses`
  async getWorkflowStatuses(): Promise<AxiosResponse<IWorkflowStatuses>> {
    const url = this.url.documents.work

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/documents/historyStateCodes`
  async getHistoryStateCodes(): Promise<AxiosResponse<IHistoryStateCodes>> {
    const url = this.url.documents.history

    return await this._http.get(url)
  }
}

export default new MetadataService()
