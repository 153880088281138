import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { SuccessCode } from '@const/consts'

import { closeModal } from '@store/modules/modal/actions'
import { IAxiosErrorData } from '@store/types/commonTypes';

import { displayErrorNotification, displaySuccessNotification } from '@utils/utils'

type IUseLoading<T> = [boolean, (params: IHandlerFetchRequestProps<T>) => void]

interface IHandlerFetchRequestProps<T> {
  data: T
  callback?: (resp: AxiosResponse) => void
  errorCallback?: (error: AxiosError<IAxiosErrorData>) => void
}
export const useLoading = <T, P>(fetchRequest: (data: T) => Promise<AxiosResponse<P>>, notification?: { title?: string, content?: string }, isCloseModal = true): IUseLoading<T> => {
  const [loading, setLoading] = useState<boolean>(false)

  const dispatch = useDispatch()

  const handlerFetchRequest = (params: IHandlerFetchRequestProps<T>): void => {
    const { callback, errorCallback, data } = params

    handlerSetLoading(true)
    fetchRequest(data)
      .then((resp: AxiosResponse) => {
        const method = resp.config.method?.toUpperCase()
        if (method) {
          const isCheckCode = Array.isArray(SuccessCode[method])
            ? SuccessCode[method].includes(resp.status)
            : SuccessCode[method] === resp.status

          if (isCheckCode) {
            if (notification) displaySuccessNotification({ title: notification.title, content: notification.content })

            handlerSetLoading(false, isCloseModal)
            callback?.(resp)
          }
        }
      })
      .catch((error: AxiosError<IAxiosErrorData>) => {
        displayErrorNotification(error)
        errorCallback?.(error)
        handlerSetLoading(false)
      })
  }

  const handlerSetLoading = (newValue: boolean, isCloseModal?: boolean): void => {
    if (isCloseModal) dispatch(closeModal)

    const timeout = newValue ? 0 : 500

    setTimeout(() => setLoading(newValue), timeout)
  }

  return [
    loading,
    handlerFetchRequest
  ]
}
