import { GroupField } from '@const/consts'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from "redux-thunk"

import { IApplicationState } from '@store/types/commonTypes'
import { AxiosResponse } from "axios"

export enum GroupActionTypes {
  GET_GROUP = 'GET_GROUP',
  GET_MEMBERS = 'GET_MEMBERS',
  RESET_GROUP = 'RESET_GROUP',
  UPDATE_GROUP_NAME = 'UPDATE_GROUP_NAME'
}

export interface IGroup {
  [GroupField.NAME]: string
  [GroupField.OGUID]: string
  [GroupField.USERS_COUNT]: number
}

export interface IAddGroup {
  [GroupField.NAME]: string
}

export interface IMember {
  email: string
  name: string
  oguid: string
  patronymic: string
  position: string
  status: string
  surname: string
}

export interface ISelectedMembers {
  [key: string]: boolean
}

export interface IGroupState {
  [GroupField.INITIAL_NAME]: string
  [GroupField.MEMBERS]: IMember[]
  [GroupField.NAME]: string
  [GroupField.OGUID]: string
  [GroupField.SELECTED_MEMBERS]: ISelectedMembers
}

export interface IActionResetGroup extends Action<string> {
  type: GroupActionTypes.RESET_GROUP
}

export interface IActionGetGroup extends Action<string> {
  payload: IGroup
  type: GroupActionTypes.GET_GROUP
}

export interface IActionGetMembers extends Action<string> {
  payload: IMember[]
  type: GroupActionTypes.GET_MEMBERS
}

export interface IActionUpdateGroupName extends Action<string> {
  payload: string
  type: GroupActionTypes.UPDATE_GROUP_NAME
}

export type Actions = IActionGetGroup | IActionGetMembers | IActionResetGroup | IActionUpdateGroupName

enum ActionKeys {
  GET_GROUP = 'getGroup',
  GET_MEMBERS = 'getMembers',
  UPDATE_GROUP_NAME = 'updateGroupName'
}

export type GroupActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type GroupThunkAction = ThunkAction<Promise<AxiosResponse>, IMember[], void, Action<string>>

export type GroupThunkDispatch = ThunkDispatch<IApplicationState, void, Action<string>>
