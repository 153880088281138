import React, { FC } from 'react'

import { NavigationWrapper } from '@containers/Navigation'

import { IMenuProps as IProps } from '@containers/Layout/components/Menu/types'
import HeaderWrapper from '../HeaderWrapper'

const Menu: FC<IProps> = ({ isShownMenus, isShownNavMenu }) =>
  isShownMenus ? (
    <>
      <HeaderWrapper />
      {isShownNavMenu ? <NavigationWrapper /> : null}
    </>
  ) : null

export default Menu
