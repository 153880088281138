import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { cloneDeep } from 'lodash'

import { getPagination } from '@utils/utils'

import flowsService from '@services/flows'

import { ResponseCode, temporaryPostResponseCode } from '@const/consts'

import { setPagination } from '@store/modules/pagination/actions'

import { AsyncThunk, IParams, SyncThunk } from '@store/types/commonTypes'
import { ISelectedDocuments } from '@store/modules/documents/types'
import {
  IActionSetSelectedDocuments,
  IActionSetMessages,
  IActionSetResponses,
  IMessage,
  IResponse,
  IResponses,
  TasksActions,
  TasksActionTypes,
  TasksThunkDispatch,
  IActionResetSelectedDocuments,
  IActionResetTasks
} from './types'
import { ILibUser } from '@infologistics/frontend-libraries'

// actions
const setSelectedDocumentsAction: ActionCreator<Action> = (
  selectedDocuments: ISelectedDocuments
): IActionSetSelectedDocuments => ({
  payload: selectedDocuments,
  type: TasksActionTypes.SET_SELECTED_DOCUMENTS
})

const setMessagesAction: ActionCreator<Action> = (messages: IMessage[]): IActionSetMessages => ({
  payload: messages,
  type: TasksActionTypes.SET_TASKS_MESSAGES
})

const setResponsesAction: ActionCreator<Action> = (responses: IResponses): IActionSetResponses => ({
  payload: responses,
  type: TasksActionTypes.SET_RESPONSES
})

export const actions: TasksActions = {
  setSelectedDocuments: setSelectedDocumentsAction,
  setMessages: setMessagesAction,
  setResponses: setResponsesAction
}

// thunks

export const getTasks: AsyncThunk = (params: IParams = {}, isUpdatePagination = true) => (
  dispatch: TasksThunkDispatch, getState
): Promise<AxiosResponse> =>
  flowsService.getTasks(params, !getState().utils.isRootMode)
    .then((resp: AxiosResponse) => {
    if (resp.status === ResponseCode.GET) {
      const respData = resp.data ?? []

      dispatch(setMessagesAction(respData))
      dispatch(setSelectedDocumentsAction({}))

      const responses = respData.reduce((acc: { [key: string]: IResponse }, message: IMessage) => {
        const response = {
          isOpen: false,
          recipient: message.task.author,
          text: ''
        }
        return {
          ...acc,
          [message.task.oguid]: response
        }
      }, {})

      dispatch(setResponsesAction(responses))

      if (isUpdatePagination) {
        const pagination = getPagination(resp.headers)
        pagination && dispatch(setPagination(pagination))
      }
    }

    return resp
  })

export const selectDocument: SyncThunk = (oguid: string) => (dispatch: TasksThunkDispatch, getState): void => {
  const { selectedDocuments } = getState().tasks
  dispatch(
    setSelectedDocumentsAction({
      ...selectedDocuments,
      [oguid]: !selectedDocuments[oguid]
    })
  )
}

export const resetSelectedDocuments: IActionResetSelectedDocuments = {
  type: TasksActionTypes.RESET_SELECTED_DOCUMENTS
}

export const resetTasks: IActionResetTasks = {
  type: TasksActionTypes.RESET_TASKS
}

export const toggleResponse: SyncThunk = (oguid: string) => (dispatch: TasksThunkDispatch, getState): void => {
  const { responses } = getState().tasks
  dispatch(
    setResponsesAction({
      ...responses,
      [oguid]: {
        ...responses[oguid],
        isOpen: !responses[oguid].isOpen,
        text: ''
      }
    })
  )
}

export const updateResponse: SyncThunk = (oguid: string, text: string) => (
  dispatch: TasksThunkDispatch,
  getState
): void => {
  const { responses } = getState().tasks
  dispatch(
    setResponsesAction({
      ...responses,
      [oguid]: {
        ...responses[oguid],
        text
      }
    })
  )
}

export const setRecipient: SyncThunk = (oguid: string, recipient: ILibUser) => (
  dispatch: TasksThunkDispatch,
  getState
): void => {
  const { responses } = getState().tasks
  dispatch(
    setResponsesAction({
      ...responses,
      [oguid]: {
        ...responses[oguid],
        recipient
      }
    })
  )
}

export const markAsRead: AsyncThunk = (taskOguid: string) => (
  dispatch: TasksThunkDispatch,
  getState
): Promise<AxiosResponse> =>
  flowsService.markAsRead(taskOguid).then((resp: AxiosResponse) => {
    // TODO: find out what's with this status. If backend changes it to 200, replace it.
    if (resp.status === temporaryPostResponseCode.POST) {
      const tasks = cloneDeep(getState().tasks.messages)
      const currentTaskIndex = tasks.findIndex((task: IMessage) => task.task.oguid === taskOguid)

      tasks[currentTaskIndex].task.isRead = true
      dispatch(actions.setMessages(tasks))
    }

    return resp
  })
