import { Actions, INoticesState, NoticesActionTypes } from './types'

export const initialState: INoticesState = {
  notices: [],
  isLoading: false,
  isLoadingFromActionButtons: false,
  page: 1
}

export default function noticesReducer(state = initialState, action: Actions): INoticesState {
  switch (action.type) {
    case NoticesActionTypes.SET_NOTICES:
      return {
        ...state,
        notices: [...action.payload]
      }

    case NoticesActionTypes.SET_NOTICES_PAGE:
      return {
        ...state,
        page: action.payload
      }

    case NoticesActionTypes.SET_LOADED_FROM_ACTION_BUTTONS:
      return {
        ...state,
        isLoadingFromActionButtons: action.payload
      }

    case NoticesActionTypes.TOGGLE_NOTICES_LOADER:
      return {
        ...state,
        isLoading: action.payload
      }

    default:
      return state
  }
}
