import { Action } from 'redux'
import { IAllowedAction } from '@store/modules/documents/types'

export interface IDocumentOperationChangeField {
  sending?: boolean
  isSent?: boolean
  isSuccess?: boolean
  errorAction?: string
  isCanceled?: boolean
  isExternal?: boolean
  messageAction?: string
}

export interface IDocumentOperation extends IDocumentOperationChangeField {
  name: string
  status: string
  allowedActions?: IAllowedAction[]
  documentInfo?: string
}

export interface IDocumentsOperation {
  [key: string]: IDocumentOperation
}

export interface IUpdatedDocumentOperation {
  id: string
  document: IDocumentOperation
}

export enum DocumentOperationActionTypes {
  ADD_DOCUMENTS = 'ADD_DOCUMENTS',
  UPDATE_DOCUMENT = 'UPDATE_DOCUMENT',
  RESET_DOCUMENTS_OPERATIONS = 'RESET_DOCUMENTS_OPERATIONS'
}

export interface IActionAddDocumentsOperation extends Action<string> {
  type: DocumentOperationActionTypes.ADD_DOCUMENTS
  payload: IDocumentsOperation
}

export interface IActionUpdateDocumentOperation extends Action<string> {
  type: DocumentOperationActionTypes.UPDATE_DOCUMENT
  payload: IUpdatedDocumentOperation
}

export interface IActionResetDocumentsOperation extends Action<string> {
  type: DocumentOperationActionTypes.RESET_DOCUMENTS_OPERATIONS
}

export type Actions = IActionAddDocumentsOperation | IActionUpdateDocumentOperation | IActionResetDocumentsOperation

export type IDocumentsOperationState = IDocumentsOperation
