import * as Yup from 'yup'
import { NewOrganizationLabel, Warning } from '@const/translations'
import { AliasLength, CNPJ_LENGTH, Instance, NewOrganizationMode, OrgNameLength, OrgNameShortLength, OrgOgrnLength } from '@const/consts'
import { aliasRegexp, orgOgrnRegexp, orgNameRegexp } from '@const/validation'

const organizationInfoSimple = {
  alias: Yup.string()
    .trim()
    .matches(aliasRegexp, Warning.regexp)
    .min(AliasLength.MIN, Warning.min[AliasLength.MIN])
    .max(AliasLength.MAX, Warning.max[AliasLength.MAX])
    .required(Warning.required),
  name: Yup.string()
    .trim()
    .matches(orgNameRegexp, Warning.regexp)
    .min(OrgNameLength.MIN, Warning.min[OrgNameLength.MIN])
    .max(OrgNameLength.MAX, Warning.max[OrgNameLength.MAX])
    .required(Warning.required)
    .nullable(),
  nameShort: Yup.string()
    .trim()
    .min(OrgNameShortLength.MIN, Warning.min[OrgNameShortLength.MIN])
    .max(OrgNameShortLength.MAX, Warning.max[OrgNameShortLength.MAX])
    .matches(orgNameRegexp, Warning.regexp)
    .required(Warning.required)
    .nullable()
}

const organizationInfoForeign = {
  alias: Yup.string()
    .trim()
    .min(AliasLength.MIN, Warning.min[AliasLength.MIN])
    .max(AliasLength.MAX, Warning.max[AliasLength.MAX])
    .matches(aliasRegexp, Warning.regexp)
    .required(Warning.required),
  name: Yup.string()
    .trim()
    .min(OrgNameLength.MIN, Warning.min[OrgNameLength.MIN])
    .max(OrgNameLength.MAX, Warning.max[OrgNameLength.MAX])
    .matches(orgNameRegexp, Warning.regexp)
    .required(Warning.required)
    .nullable(),
  nameShort: Yup.string()
    .trim()
    .min(OrgNameShortLength.MIN, Warning.min[OrgNameShortLength.MIN])
    .max(OrgNameShortLength.MAX, Warning.max[OrgNameShortLength.MAX])
    .matches(orgNameRegexp, Warning.regexp)
    .required(Warning.required)
    .nullable(),
  ogrn: Yup.string()
    .trim()
    .min(OrgOgrnLength.MIN, Warning.min[OrgOgrnLength.MIN])
    .max(OrgOgrnLength.MAX, Warning.max[OrgOgrnLength.MAX])
    .matches(orgOgrnRegexp, Warning.regexp)
    .required(Warning.required)
    .nullable()
}

const organizationInfoForeignBrasil = {
  ...organizationInfoForeign,
  ogrn: Yup.string()
    .nullable()
    .trim()
    .required(Warning.required)
    .matches(orgOgrnRegexp, Warning.regexp)
    .length(CNPJ_LENGTH, NewOrganizationLabel.cnpjError)
}

const organizationInfoSuggest = {
  alias: Yup.string()
    .trim()
    .min(AliasLength.MIN, Warning.min[AliasLength.MIN])
    .max(AliasLength.MAX, Warning.max[AliasLength.MAX])
    .matches(aliasRegexp, Warning.regexp)
    .required(Warning.required),
  contractor: Yup.object()
    .required(Warning.required)
    .nullable()
}

export const getValidationSchema = (mode: NewOrganizationMode, instance: string): Yup.ObjectSchema => {
  const isBrasil = instance === Instance.BRASIL
  const foreingSchema = isBrasil ? organizationInfoForeignBrasil : organizationInfoForeign

  switch (mode) {
    case NewOrganizationMode.FOREIGN:
      return Yup.object().shape(foreingSchema)

    case NewOrganizationMode.SIMPLE:
      return Yup.object().shape(organizationInfoSimple)

    case NewOrganizationMode.SUGGEST:
      return Yup.object().shape(organizationInfoSuggest)
  }
}
