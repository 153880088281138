import { connect } from 'react-redux'

import { LayoutWrapper } from '@common/Layout'

import { IApplicationState } from '@store/types/commonTypes'
import { ILayoutWrapperPropsFromState as IPropsFromState } from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  isRootMode: state.utils.isRootMode,
  isExchangeParticipant: state.user.isExchangeParticipant,
  isFlowFunctionalityEnabled: state.user.isFlowFunctionalityEnabled,
  isLoading: state.loader.isLoading,
  isReadonly: state.user.isReadonly,
  isShownMenus: state.utils.isShownMenus,
  isShownNavMenu: state.utils.isShownNavMenu,
  role: state.user.role,
  orgOguid: state.user.activeOrganization.oguid,
  userSettings: state.user.settings
})

export default connect(mapStateToProps)(LayoutWrapper)
