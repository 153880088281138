import { AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IApplicationState } from '@store/types/commonTypes'

export enum ConstraintsActionTypes {
  RESET_CONSTRAINTS = 'RESET_CONSTRAINTS',
  SET_CONSTRAINTS = 'SET_CONSTRAINTS'
}

export enum ConstraintType {
  CHECK_NOT_NULL = 'CHECK_NOT_NULL',
  CHECK_UNIQUE = 'CHECK_UNIQUE',
  ALLOWED_IN_ROUTE = 'ALLOWED_IN_ROUTE'
}

interface IConstraint {
  code: string
  errorMessage: string
  name: string
  type: ConstraintType
}

interface IConstraintDocumentType {
  key: string
  title: string
}

interface IConstraintField {
  key: string
  label: string
}

export interface IConstraintToGet extends IConstraint {
  documentTypes: IConstraintDocumentType[]
  fields: IConstraintField[]
  oguid: string
}

export interface IConstraintToSet extends IConstraint {
  documentTypeKeys: string[]
  fieldKeys: string[]
}

export interface IActionResetConstraints extends Action<string> {
  type: ConstraintsActionTypes.RESET_CONSTRAINTS
}

export interface IActionSetConstraints extends Action<string> {
  payload: IConstraintToGet[]
  type: ConstraintsActionTypes.SET_CONSTRAINTS
}

export type Actions = IActionResetConstraints | IActionSetConstraints

type ActionKeys = 'resetConstraints' | 'setConstraints'

export type ConstraintsActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type ConstraintsThunkAction = ThunkAction<Promise<AxiosResponse>, IApplicationState, void, Action<string>>

export type ConstraintsThunkDispatch = ThunkDispatch<IConstraintToGet[], void, Action<string>>
