import { connect } from 'react-redux'

import { OrganizationsList } from '@common/Layout'

import { getDivisions } from '@store/modules/divisions/actions'
import { getDictTypes } from '@store/modules/metadata/actions'
import { showModal } from '@store/modules/modal/actions'
import { changeOrganization, resetStoreAfterOrganizationChange, getProfile } from '@store/modules/user/actions'
import { setLoaded } from '@store/modules/startup/actions'
import { getAllBadges } from '@store/modules/navigation/actions'
import { IApplicationState, IUserOrganization } from '@store/types/commonTypes'
import {
  IOrganizationsListPropsFromDispatch as IPropsFromDispatch,
  IOrganizationsListPropsFromState as IPropsFromState
} from './types'

const mapStateToProps = (state: IApplicationState): IPropsFromState => ({
  activeOrganization: state.user.activeOrganization,
  organizations: state.user.profile.orgs,
  role: state.user.role,
  instance: state.utils.instance,
  isRootMode: state.utils.isRootMode
})

const mapDispatchToProps = (dispatch: any): IPropsFromDispatch => ({
  getDictTypes: () => dispatch(getDictTypes()),
  onGetDivisions: () => dispatch(getDivisions()),
  onGetProfile: () => dispatch(getProfile()),
  onGetBadges: () => dispatch(getAllBadges()),
  onChangeOrganization: (organization: IUserOrganization) => dispatch(changeOrganization(organization)),
  onShowModal: (modalId: string) => dispatch(showModal(modalId)),
  resetStore: () => dispatch(resetStoreAfterOrganizationChange()),
  setLoaded: (isLoaded: boolean) => dispatch(setLoaded(isLoaded))
})

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationsList)
