import { AxiosError, AxiosResponse } from 'axios'
import { Action, ActionCreator } from 'redux'

import { getPagination } from '@utils/utils'

import { ResponseCode } from '@const/consts'

import contractorsService from '@services/contractors'

import { setPagination } from '@store/modules/pagination/actions'
import { IParams } from '@store/types/commonTypes'
import { IPaginationParams } from '@common/PaginationWrapper/types'
import {
  ContractorsActions,
  ContractorsActionTypes,
  ContractorsThunkAction,
  ContractorsThunkDispatch,
  IActionAddContractor,
  IActionDeleteContractor,
  IActionEditContractor,
  IActionGetContractor,
  IActionResetContractors,
  IActionSaveFilterParams,
  IActionSetPagination,
  IContractorData,
  IEditContractorBody,
  IForeignContractor,
  IManualContractor
} from './types'

// actions
const addContractorAction: ActionCreator<Action> = (data: IContractorData[]): IActionAddContractor => ({
  payload: data,
  type: ContractorsActionTypes.ADD_CONTRACTOR
})

const deleteContractorAction: ActionCreator<Action> = (contractorOguid: string): IActionDeleteContractor => ({
  payload: contractorOguid,
  type: ContractorsActionTypes.DELETE_CONTRACTOR
})

const editContractorAction: ActionCreator<Action> = (data: IContractorData): IActionEditContractor => ({
  payload: data,
  type: ContractorsActionTypes.EDIT_CONTRACTOR
})

const getContractorAction: ActionCreator<Action> = (data: any): IActionGetContractor => ({
  payload: data,
  type: ContractorsActionTypes.GET_CONTRACTOR
})

export const resetContractors: IActionResetContractors = {
  type: ContractorsActionTypes.RESET_CONTRACTORS
}

const saveFilterParamsAction: ActionCreator<Action> = (params: any): IActionSaveFilterParams => ({
  payload: params,
  type: ContractorsActionTypes.SAVE_FILTER_PARAMS
})

const setPaginationAction: ActionCreator<Action> = (pagination: IPaginationParams): IActionSetPagination => ({
  payload: pagination,
  type: ContractorsActionTypes.SET_PAGINATION
})

export const actions: ContractorsActions = {
  addContractor: addContractorAction,
  deleteContractor: deleteContractorAction,
  editContractor: editContractorAction,
  getContractor: getContractorAction,
  saveFilterParams: saveFilterParamsAction,
  setPagination: setPaginationAction
}

// thunks
export const getContractors: ActionCreator<ContractorsThunkAction> = (params: IParams = {}) => (
  dispatch: ContractorsThunkDispatch
): Promise<AxiosResponse> =>
  contractorsService.getContractors(params)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(getContractorAction(resp.data ?? []))

        const pagination = getPagination(resp.headers)
        pagination && dispatch(setPagination(pagination))

        if (Object.keys(params).length) {
          dispatch(saveFilterParamsAction(params))
        }
      }

      return resp
    })
    .catch((error: any) => Promise.reject(error))

export const addForeignContractor: ActionCreator<ContractorsThunkAction> = (contractor: IForeignContractor) => (
  dispatch: ContractorsThunkDispatch
): Promise<AxiosResponse> =>
  contractorsService.addForeignContractor(contractor)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.POST) {
        dispatch(addContractorAction(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const addLocalContractor: ActionCreator<ContractorsThunkAction> = (contractor: IManualContractor) => (
  dispatch: ContractorsThunkDispatch
): Promise<AxiosResponse> =>
  contractorsService.addManualContractor(contractor)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.POST) {
        dispatch(addContractorAction([resp.data]))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const addContractorByHid: ActionCreator<ContractorsThunkAction> = (hid: string) => (
  dispatch: ContractorsThunkDispatch
): Promise<AxiosResponse> =>
  contractorsService.addContractorByHid(hid)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.POST) {
        dispatch(addContractorAction(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const addContractorByOguid: ActionCreator<ContractorsThunkAction> = (oguid: string) => (
  dispatch: ContractorsThunkDispatch
): Promise<AxiosResponse> =>
  contractorsService.addContractorByOguid(oguid)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.POST) {
        dispatch(addContractorAction(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const deleteContractor: ActionCreator<ContractorsThunkAction> = (contractorKey: string) => (
  dispatch: ContractorsThunkDispatch
): Promise<AxiosResponse> =>
  contractorsService.deleteContractor(contractorKey)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.DEL) {
        dispatch(deleteContractorAction(contractorKey))
      }

      return resp
    })
    .catch((error: any) => Promise.reject(error))

export const editContractor: ActionCreator<ContractorsThunkAction> = (
  contractorOguid: string,
  data: IEditContractorBody
) => (dispatch: ContractorsThunkDispatch): Promise<AxiosResponse> =>
  contractorsService.editContractor(contractorOguid, data)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.editContractor(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const editForeignContractor: ActionCreator<ContractorsThunkAction> = (
  contractorOguid: string,
  data: IForeignContractor
) => (dispatch: ContractorsThunkDispatch): Promise<AxiosResponse> =>
  contractorsService.editForeignContractor(contractorOguid, data)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.editContractor(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))

export const editManualContractor: ActionCreator<ContractorsThunkAction> = (
  contractorOguid: string,
  data: IManualContractor
) => (dispatch: ContractorsThunkDispatch): Promise<AxiosResponse> =>
  contractorsService.editManualContractor(contractorOguid, data)
    .then((resp: AxiosResponse) => {
      if (resp.status === ResponseCode.GET) {
        dispatch(actions.editContractor(resp.data))
      }

      return resp
    })
    .catch((error: AxiosError) => Promise.reject(error))
