import { Actions, IModalState, ModalActionTypes } from './types'

export const initialState: IModalState = {
  id: ''
}

export default function modalReducer(state = { ...initialState }, action: Actions): IModalState {
  switch (action.type) {
    case ModalActionTypes.SHOW_MODAL: {
      return { ...initialState, id: action.payload }
    }
    case ModalActionTypes.CLOSE_MODAL: {
      return { ...initialState }
    }
    default:
      return state
  }
}
