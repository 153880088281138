import cn from 'classnames'
import onClickOutside from 'react-onclickoutside'
import React, { Component, ReactNode } from 'react'
import { withTranslation } from 'react-i18next'

import { Button, IconTimes, IconRefresh, Tabs, ILibTab } from '@infologistics/frontend-libraries'
import MessagesTab from '@containers/Messages/MessagesTab'

import { displayErrorNotification } from '@utils/utils'

import { MessageTab, ShowMode } from '@const/consts'

import { IParams } from '@store/types/commonTypes'
import { AllMessagesProps as IProps, IMessagesState as IState } from '@containers/Messages/Messages/types'

import styles from './Messages.module.css'

const tabsContent = {
  [MessageTab.OPEN]: <MessagesTab isCompleted={false} />,
  [MessageTab.CLOSED]: <MessagesTab isCompleted={true} />
}

class Messages extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = { activeTab: MessageTab.OPEN }
  }

  public componentWillUnmount(): void {
    const { onClearMessages } = this.props

    onClearMessages()
  }

  public render(): ReactNode {
    const { messages, t, isLoading } = this.props

    return (
      <div className={cn(styles.messages, 'absolute')}>
        <p className={cn(styles.messages_header, 'py-4 text-center mb-0 relative')}>
          {t('tasks:messages', { count: messages.length })}
          <Button theme='text' externalClass={cn(styles.refresh, 'absolute')} onClick={this.handleRefresh} isDisabled={isLoading}>
            <IconRefresh color='light' />
          </Button>
          <Button theme='text' externalClass={cn(styles.close, 'absolute')} onClick={this.handleClose}>
            <IconTimes color='light' />
          </Button>
        </p>
        <Tabs tabs={this.getTabs()} content={tabsContent} activeTab={this.state.activeTab} theme='flat' />
      </div>
    )
  }

  // handleClickOutside нужен для работы onClickOutside
  public handleClickOutside = (): void => {
    const { onToggleMessages } = this.props

    onToggleMessages(false)
  }

  private readonly handleTabToggle = (tab: MessageTab) => () => () => {
    const { activeTab } = this.state

    if (tab === activeTab) return

    this.setState({ activeTab: tab })

    return this.props.onClearMessages()
  }

  private readonly getTabs = (): ILibTab[] => {
    const { isLoading, isLoadingFromActionButtons } = this.props

    return [
      {
        name: MessageTab.OPEN,
        translation: this.props.t('tasks:open'),
        onClick: this.handleTabToggle(MessageTab.OPEN),
        isDisabled: isLoading || isLoadingFromActionButtons
      },
      {
        name: MessageTab.CLOSED,
        translation: this.props.t('tasks:closed'),
        onClick: this.handleTabToggle(MessageTab.CLOSED),
        isDisabled: isLoading || isLoadingFromActionButtons
      }
    ]
  }

  private readonly handleClose = (): void => {
    this.props.onToggleMessages(false)
  }

  private readonly handleRefresh = (): void => {
    const { onGetMessages, onGetBadges, onToggleLoader, onSetMessagesPage } = this.props

    const params = this.getParams()

    onSetMessagesPage(1)
    onToggleLoader(true)

    Promise.all([onGetMessages(params), onGetBadges()])
      .finally(() => onToggleLoader(false))
      .catch(displayErrorNotification)
  }

  private readonly getParams = (): IParams => {
    const { activeTab } = this.state

    return {
      page: 1,
      showMode: ShowMode.TODOS_ONLY,
      isCompleted: activeTab === MessageTab.CLOSED
    }
  }
}

export default withTranslation()(onClickOutside(Messages))
