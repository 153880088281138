import { AxiosResponse, AxiosRequestConfig } from 'axios'

import AbstractHttpService from '../abstractHttpService'

import urls from '@const/urls'
import { IHandbook, IHandbookRelations, IRelation, IRelations } from '@store/modules/handbooks/types'

class HandbooksService extends AbstractHttpService {
  private readonly url = urls.handbooks

  async getNames(): Promise<AxiosResponse<string[]>> {
    const url = this.url.list.names

    return await this._http.get(url)
  }

  async addValue(name: string, data: IHandbook[]): Promise<AxiosResponse<IHandbook>> {
    const url = this.url.list.values.replace('{{ dictType }}', name)

    return await this._http.post(url, data)
  }

  async deleteHandbook(name: string): Promise<AxiosResponse> {
    const url = this.url.single.remove.replace('{{ dictType }}', name)

    return await this._http.delete(url)
  }

  async deleteValue(key: string, name: string): Promise<AxiosResponse> {
    const url = this.url.single.value.replace('{{ dictType }}', name).replace('{{ key }}', key)

    return await this._http.delete(url)
  }

  async changeValue(key: string, name: string, data: IHandbook): Promise<AxiosResponse<IHandbook>> {
    const url = this.url.single.value.replace('{{ dictType }}', name).replace('{{ key }}', key)

    return await this._http.put(url, data)
  }

  async getValue(name: string, key: string, params?: any): Promise<AxiosResponse<IHandbook>> {
    const url = this.url.single.value.replace('{{ dictType }}', name).replace('{{ key }}', key)
    const config: AxiosRequestConfig = { params }

    return await this._http.get(url, config)
  }

  async receiveRelations(name: string): Promise<AxiosResponse<IRelations[]>> {
    const url = this.url.list.relations.replace('{{ dictType }}', name)

    return await this._http.get(url)
  }

  async deleteRelations(name: string): Promise<AxiosResponse> {
    const url = this.url.list.relations.replace('{{ dictType }}', name)

    return await this._http.delete(url)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsgreaterdicts/operation/postDictSingleValueRelationsV1
  async addRelations(name: string, key: string, data: IRelation): Promise<AxiosResponse<IHandbookRelations[]>> {
    const url = this.url.single.relations.replace('{{ dictType }}', name).replace('{{ key }}', key)

    return await this._http.post(url, data)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsgreaterdicts/operation/getDictSingleValueRelationsV1
  async receiveSingleValueRelations(name: string, key: string): Promise<AxiosResponse<IHandbookRelations[]>> {
    const url = this.url.single.relations.replace('{{ dictType }}', name).replace('{{ key }}', key)

    return await this._http.get(url)
  }

  // https://im-api-df-backend-dev.dev.info-logistics.eu/specification/redoc.html#tag/orgsgreaterdicts/operation/putDictSingleValueRelationsV1
  async updateRelations(name: string, key: string, data: IRelation): Promise<AxiosResponse<IHandbookRelations[]>> {
    const url = this.url.single.relations.replace('{{ dictType }}', name).replace('{{ key }}', key)

    return await this._http.put(url, data)
  }

  async getValues(
    name: string,
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<IHandbook[]>> {
    const url = this.url.list.values.replace('{{ dictType }}', name)

    return await this._http.get(url, config)
  }

  async deleteRelation(
    dictType: string,
    key: string,
    relatedDictType: string,
    relatedKey: string
  ): Promise<AxiosResponse> {
    const url = this.url.single.related
      .replace('{{ dictType }}', dictType)
      .replace('{{ key }}', key)
      .replace('{{ relatedDictType }}', relatedDictType)
      .replace('{{ relatedKey }}', relatedKey)

    return await this._http.delete(url)
  }

  async exportRelations(dictType: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.related.replace('{{ dictType }}', dictType)

    return await this._http.get(url, { responseType: 'blob' })
  }

  async exportValues(dictType: string): Promise<AxiosResponse<Blob>> {
    const url = this.url.file.values.replace('{{ dictType }}', dictType)

    return await this._http.get(url, { responseType: 'blob' })
  }

  async importValues(name: string, data: any): Promise<AxiosResponse<number>> {
    const url = this.url.file.values.replace('{{ dictType }}', name)

    return await this._http.post(url, data)
  }

  async importLinks(name: string, data: FormData): Promise<AxiosResponse<number>> {
    const url = this.url.file.related.replace('{{ dictType }}', name)

    return await this._http.post(url, data)
  }
}

export default new HandbooksService()
