import cn from 'classnames'
import React, { ChangeEvent, KeyboardEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Ellipsis,
  FormLabel,
  IconAttach,
  IconTimes,
  ILibUser,
  Textarea
} from '@infologistics/frontend-libraries'
import SuggestRecipient from '@common/Suggest/SuggestRecipientWrapper'

import { Key, MessagesField, RecipientShowMode } from '@const/consts'

import attachmentsService from '@services/attachments'

import { IAnswerFormState, IAnswerProps as IProps } from './types'
import { displayErrorNotification, hasError } from '@utils/utils'

import styles from './AnswerForm.module.css'
import { FormikErrors } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import { IApplicationState } from '@store/types/commonTypes'
import { actions as loaderActions, closeLoader } from '@store/modules/loader/actions'
import { setRecipient, toggleResponse } from '@store/modules/messages/actions'

const AnswerForm: FC<IProps> = (props) => {
  const {
    errors,
    handleSubmit,
    orgOguid,
    setFieldValue,
    touched,
    values: { file, oguid, author, documentOguid, description }
  } = props

  const { t } = useTranslation()

  const response = useSelector((state: IApplicationState) => state.messages.responses[oguid])

  const dispatch = useDispatch<any>()

  const isOpen = response?.isOpen || false

  const handleRecipientChange = (oguid: string) => (recipient: ILibUser) => dispatch(setRecipient(oguid, recipient))

  const handleTextareaChange = (e: ChangeEvent<HTMLTextAreaElement>): Promise<void | FormikErrors<IAnswerFormState>> =>
    setFieldValue('description', e.target.value.trimLeft())

  const handleCancelClick = (oguid: string) => () => dispatch(toggleResponse(oguid))

  const handleKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>): void => {
    if ((e.key === Key.ENTER && e.ctrlKey) || (e.key === Key.ENTER && e.metaKey)) handleSubmit()
  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const { files } = e.target

    if (files?.[0]) {
      const { name } = files[0]
      dispatch(loaderActions.showLoader)

      const data = new FormData()
      data.append('file', files[0])

      try {
        const resp = await attachmentsService.uploadToShelf(data, orgOguid)

        setFieldValue('file', {
          name,
          oguid: resp.data
        })

        dispatch(closeLoader())
      } catch (err) {
        dispatch(closeLoader())
        displayErrorNotification(err)
      }
    }
  }

  const handleFileRemove = (): void => {
    setFieldValue('file', null)
  }

  return (
    isOpen
      ? <form className={cn(styles.message_response, 'p-4')} noValidate={true} onSubmit={handleSubmit}>
          <div className='mb-4 relative'>
            <Textarea
              name={`response-${oguid}`}
              onChange={handleTextareaChange}
              onKeyDown={handleKeyDown}
              resize={false}
              rows={4}
              value={description}
              textareaClasses={styles.response}
              placeholder={t('tasks:messageText')}
              hasError={hasError(MessagesField.DESCRIPTION, errors, touched)}
              errorText={t('document:fillTodo')}
            />
          </div>

          <div className='mb-2 relative'>
            <FormLabel className='visually-hidden'>{t('recipient:placeholder')}</FormLabel>
            <SuggestRecipient
              defaultUser={author}
              documentOguid={documentOguid}
              searchOrgOguid={orgOguid}
              onSelectUser={handleRecipientChange(oguid)}
              showMode={RecipientShowMode.USERS}
            />
          </div>

          <div className='d-flex align-items-center mb-2'>
            <IconAttach classes='mr-1' size='xs' />
            <FormLabel
              className={cn(
                'fl-button fl-button-link fl-button-link_dashed fl-button-extra-small',
                file && cn(styles.file_name, 'pr-5 mr-n5 ml-n2')
              )}
            >
              <Ellipsis
                externalClass={cn('fl-button_text', file && 'ml-2')}
              >
                { file?.name ?? t('common:attachFile') }
              </Ellipsis>
              <input type='file' className='d-none' onChange={handleFileUpload} />
            </FormLabel>
            {file && (
              <Button classes='ml-1' onClick={handleFileRemove} theme='text'>
                <IconTimes size='xs' />
              </Button>
            )}
          </div>

          <div className='d-flex align-items-center'>
            <Button theme='primary' size='extra-small' type='submit' externalClass='mr-4 px-2 py-1'>
              {t('tasks:buttons.send')}
            </Button>
            <Button externalClass='px-2 py-1' size='extra-small' onClick={handleCancelClick(oguid)}>
              {t('tasks:buttons.cancel')}
            </Button>

            <span className='ml-auto text-muted font-xs'>{t('tasks:hint')}</span>
          </div>
        </form>
      : null
  )
}

export default AnswerForm
