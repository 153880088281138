import { AxiosResponse } from 'axios'
import AbstractHttpService from '@services/abstractHttpService'
import { IDraftRouteDocumentData } from '@store/modules/documents/types'
import { IApprovedScheme, IScheme } from '@store/modules/approvalScheme/types'
import urls from '@const/urls'
import { IParams } from '@store/types/commonTypes'
import { IApproveContent, IMessage, IMessageBody } from '@store/modules/tasks/types'
import { ICloseMessageBody } from '@views/personal/components/Tasks/Messages/types'
import { IBadges } from '@store/modules/navigation/types'
import { IMarkIsRead } from '@store/modules/metadata/types'
import { INotice } from '@common/Notices/Notice/types'
import { IMessagesQuantity } from '@store/modules/messages/types'

class FlowsService extends AbstractHttpService {
  private readonly url = urls.flows

  // `${BASE_URL}orgs/${orgOguid}/flows/entities/flowStart`
  async getDraftDocumentScheme(scheme: IDraftRouteDocumentData): Promise<AxiosResponse<IScheme>> {
    const url = this.url.getDraftDocumentScheme

    return await this._http.post(url, scheme)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/entities/{{ flowEntityOguid }}/flowStart`
  async getApprovalScheme(flowEntityOguid: string): Promise<AxiosResponse<IScheme>> {
    const url = this.url.getScheme.replace('{{ flowEntityOguid }}', flowEntityOguid)

    return await this._http.get(url)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/entities/{{ flowEntityOguid }}/flowStart`
  async startApprovalScheme(flowEntityOguid: string, scheme: IApprovedScheme | undefined): Promise<AxiosResponse<IScheme>> {
    const url = this.url.getScheme.replace('{{ flowEntityOguid }}', flowEntityOguid)

    return await this._http.post(url, scheme)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/tasks`
  async getTasks(params: IParams, isAddOrgOguid?: boolean): Promise<AxiosResponse<IMessage[]>> {
    const url = isAddOrgOguid ? this.url.getTasks : this.url.getTasksAllOrgs
    const config = { params }

    return await this._http.get(url, config, isAddOrgOguid)
  }

  // `${BASE_URL}/user/notifications`
  async getNotices(params: IParams): Promise<AxiosResponse<INotice[]>> {
    const url = this.url.getNotices
    const config = { params }

    return await this._http.get(url, config, false)
  }

  // `${BASE_URL}/orgs/${orgOguid}/flows/tasks/{{ taskOguid }}/complete`
  async complete(oguid: string, content: IApproveContent | ICloseMessageBody, orgOguid?: string): Promise<AxiosResponse<IBadges>> {
    const url = this.url.complete.replace('{{ taskOguid }}', oguid)

    return await this._http.post(url, content, {}, !orgOguid, orgOguid)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/entities/${documentOguid}/tasks`
  async sendMessage(documentOguid: string, content: IMessageBody, orgOguid?: string): Promise<AxiosResponse<IMessage>> {
    const url = this.url.sendMessage.replace('{{ documentOguid }}', documentOguid)

    return await this._http.post(url, content, {}, !orgOguid, orgOguid)
  }

  // `${BASE_URL}orgs/${orgOguid}/flows/tasks/${taskOguid}/markAsRead`
  async markAsRead(taskOguid: string, orgOguid?: string): Promise<AxiosResponse> {
    const url = this.url.markAsRead.replace('{{ taskOguid }}', taskOguid)

    return await this._http.post(url, taskOguid, {}, !orgOguid, orgOguid)
  }

  // ${BASE_URL}/orgs/${orgOguid}/flows/tasks/markIsRead
  async markIsRead(marks: IMarkIsRead, taskOrgOguid?: string): Promise<AxiosResponse> {
    const url = this.url.markIsRead

    return await this._http.post(url, marks, {}, !taskOrgOguid, taskOrgOguid)
  }

  async getBadges(isAddActiveOrgOguid: boolean, orgOguid?: string): Promise<AxiosResponse<IBadges>> {
    const urlActiveOrg = isAddActiveOrgOguid ? this.url.tasksQuantities : this.url.allTasksQuantities
    const url = orgOguid ? this.url.tasksQuantities : urlActiveOrg

    return await this._http.get(url, {}, orgOguid ? false : isAddActiveOrgOguid, orgOguid)
  }

  async getMessagesQuantity(orgOguid: string): Promise<AxiosResponse<IMessagesQuantity>> {
    const url = this.url.messagesQuantities.replace('{{ orgOguid }}', orgOguid)

    return await this._http.get(url, {}, false)
  }

  // ${BASE_URL}/user/notifications/count
  async getBadgesNotices(): Promise<AxiosResponse<IBadges>> {
    const url = this.url.noticesQuantities

    return await this._http.get(url, {}, false)
  }

  // ${BASE_URL}/user/notifications/${notificationOguid}/isRead
  async markNoticeIsRead(noticeOguid: string): Promise<AxiosResponse> {
    const url = this.url.markNoticeIsRead.replace('{{ notificationOguid }}', noticeOguid)

    return await this._http.post(url, null, {}, false)
  }

  // ${BASE_URL}/user/notifications/category/${categoryType}/isRead
  async markNoticeCategoryIsRead(categoryType: string): Promise<AxiosResponse> {
    const url = this.url.markNoticeCategoryIsRead.replace('{{ categoryType }}', categoryType)

    return await this._http.post(url, null, {}, false)
  }

  // ${BASE_URL}/orgs/flows/tasksQuantities
  async getAllBadges(): Promise<AxiosResponse<IBadges>> {
    const url = this.url.allTasksQuantities

    return await this._http.get(url, {}, false)
  }

  // `${BASE_URL}/orgs/flows/tasks`
  async getAllTasks(params: IParams): Promise<AxiosResponse<IMessage[]>> {
    const url = this.url.getAllTasks
    const config = { params }

    return await this._http.get(url, config, false)
  }
}

export default new FlowsService()
