import { Action, ActionCreator } from 'redux'
import { AxiosResponse } from 'axios'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import { IApplicationState } from '@store/types/commonTypes'

export interface IUserFilter {
  isPersonal: boolean
  oguid?: string
  params: string
  route: string
  title: string
}

export enum UserFiltersActionTypes {
  ADD_USER_FILTER = 'ADD_USER_FILTER',
  DELETE_USER_FILTER = 'DELETE_USER_FILTER',
  SET_USER_FILTERS = 'SET_USER_FILTERS',
  UPDATE_USER_FILTER = 'UPDATE_USER_FILTER'
}

export interface IActionAddUserFilter extends Action<string> {
  payload: IUserFilter
  type: UserFiltersActionTypes.ADD_USER_FILTER
}

export interface IActionDeleteUserFilter extends Action<string> {
  payload: string
  type: UserFiltersActionTypes.DELETE_USER_FILTER
}

export interface IActionSetUserFilters extends Action<string> {
  payload: IUserFilter[]
  type: UserFiltersActionTypes.SET_USER_FILTERS
}

export interface IActionUpdateUserFilter extends Action<string> {
  payload: IUserFilter
  type: UserFiltersActionTypes.UPDATE_USER_FILTER
}

export type Actions = IActionAddUserFilter | IActionDeleteUserFilter | IActionSetUserFilters | IActionUpdateUserFilter

export enum ActionKey {
  ADD_USER_FILTER = 'addUserFilter',
  DELETE_USER_FILTER = 'deleteUserFilter',
  SET_USER_FILTERS = 'setUserFilters',
  UPDATE_USER_FILTER = 'updateUserFilter'
}

export type UserFiltersActions = { [key in ActionKey]: ActionCreator<Action<string>> }

export type UserFiltersThunkAction = ThunkAction<
  Promise<AxiosResponse | AxiosResponse[]>,
  IApplicationState,
  void,
  Action<string>
>

export type UserFiltersThunkDispatch = ThunkDispatch<IUserFilter[], void, Action<string>>
