import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ILibSuggestContractor as IContractor, SuggestContractor } from '@infologistics/frontend-libraries'

import { displayErrorNotification, getInstancePostfix, getOgrnTitle } from '@utils/utils'

import { FilterName } from '@const/consts'
import { ContractorSuggest } from '@const/translations'
import ContractorsService from '@services/contractors'

import { ISuggestContractorFromAllProps as IProps } from './types'
import { AxiosResponse } from 'axios'

const SuggestContractorFromAll: FC<IProps> = (props) => {
  const {
    classes,
    defaultValue,
    errorText,
    hasError,
    id,
    hint,
    params = {},
    placeholder,
    onSelect,
    onReset,
    withoutOrg
  } = props

  const [contractors, setContractors] = useState<IContractor[]>([])

  const { t } = useTranslation()

  const labels = {
    empty: t(ContractorSuggest.empty),
    hint: hint ?? t<string>(ContractorSuggest.hint),
    inn: t('common:inn'),
    kpp: t('common:kpp'),
    ogrn: t(getOgrnTitle()),
    placeholder: placeholder ?? t<string>(ContractorSuggest[`placeholder${getInstancePostfix()}`])
  }

  const handleSelect = (contractor: IContractor): void => {
    setContractors([])
    onSelect(contractor)
  }

  const handleReset = (): void => {
    onReset?.()
  }

  const getContractors = (value: string): void => {
    const allParams = {
      ...params,
      [FilterName.QUERY]: value
    }

    ContractorsService.getSuggestContractors(allParams, withoutOrg)
      .then(({ data }: AxiosResponse) => {
        setContractors(data)
      })
      .catch(displayErrorNotification)
  }

  return (
    <SuggestContractor
      classes={classes}
      data={contractors}
      defaultValue={defaultValue}
      errorText={errorText}
      hasError={hasError}
      id={id}
      getData={getContractors}
      labels={labels}
      onClick={handleSelect}
      onReset={handleReset}
    />
  )
}

export default SuggestContractorFromAll
