import { Action, ActionCreator } from 'redux'
import { AxiosError, AxiosResponse } from 'axios'

import { PER_PAGE_ITEMS_MAX, ResponseCode } from '@const/consts'
import { getPagination } from '@utils/utils'

import usersService from '@services/users'
import { setPagination } from '@store/modules/pagination/actions'

import { IParams } from '@store/types/commonTypes'
import {
  IActionResetUserInvites,
  IActionResetUsers,
  IActionSelectInvite,
  IActionSelectInvites,
  IActionSetUserInvites,
  IActionSetUsers,
  IUser,
  IUserInvite,
  UsersActions,
  UsersActionTypes,
  UsersThunkAction,
  UsersThunkDispatch
} from './types'

export const resetUsers: IActionResetUsers = {
  type: UsersActionTypes.RESET_USERS
}

export const resetUserInvites: IActionResetUserInvites = {
  type: UsersActionTypes.RESET_USER_INVITES
}

const selectInviteAction: ActionCreator<Action> = (oguid: string): IActionSelectInvite => {
  return {
    payload: oguid,
    type: UsersActionTypes.SELECT_INVITE
  }
}

const selectInvitesAction: ActionCreator<Action> = (invites: Record<string, boolean>): IActionSelectInvites => {
  return {
    payload: invites,
    type: UsersActionTypes.SELECT_INVITES
  }
}

const setUsersAction: ActionCreator<Action> = (users: IUser[]): IActionSetUsers => {
  return {
    payload: users,
    type: UsersActionTypes.SET_USERS
  }
}

const setUserInvitesAction: ActionCreator<Action> = (invites: IUserInvite[]): IActionSetUserInvites => {
  return {
    payload: invites,
    type: UsersActionTypes.SET_USER_INVITES
  }
}

export const actions: UsersActions = {
  selectInvite: selectInviteAction,
  selectInvites: selectInvitesAction,
  setUsers: setUsersAction,
  setUserInvites: setUserInvitesAction
}

// thunks

export const getUsers: ActionCreator<UsersThunkAction> = (params: IParams) =>
  (dispatch: UsersThunkDispatch): Promise<AxiosResponse> =>
    usersService.getUsers({ params })
      .then((resp) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(setUsersAction(resp.data ?? []))

          const pagination = getPagination(resp.headers)
          pagination && pagination.itemsPerPage <= PER_PAGE_ITEMS_MAX && dispatch(setPagination(pagination))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))

export const getUserInvites: ActionCreator<UsersThunkAction> = (params: IParams) =>
  (dispatch): Promise<AxiosResponse<IUserInvite[]>> =>
    usersService.getUserInvites({ params })
      .then((resp) => {
        if (resp.status === ResponseCode.GET) {
          dispatch(setUserInvitesAction(resp.data ?? []))

          const pagination = getPagination(resp.headers)
          pagination && pagination.itemsPerPage <= PER_PAGE_ITEMS_MAX && dispatch(setPagination(pagination))
        }

        return resp
      })
      .catch((err: AxiosError) => Promise.reject(err))
