import React, { FC } from 'react'

import { withRouter } from 'react-router-dom'

import SideWindowButton from './SideWindowButton'

const Buttons: FC = () => (
  <>
    <SideWindowButton isMessages={false}/>
    <SideWindowButton />
  </>
)

export default withRouter(Buttons)
