import { Action, ActionCreator } from 'redux'

import { ModuleThunkDispatch } from '@store/types/commonTypes'

import { IMessage, IResponses } from '@store/modules/tasks/types'
import { ThunkAction } from 'redux-thunk'
import { AxiosResponse } from 'axios'
import { Nullable } from '@infologistics/frontend-libraries'

export interface IMessagesState {
  messages: IMessage[]
  responses: IResponses
  isLoading: boolean
  isLoadingFromActionButtons: boolean
  page: Nullable<number>
}

export interface IMessagesQuantity {
  docWorkflowUnfinishedQuantity: number
}
// actions

export enum MessagesActionTypes {
  SET_MESSAGES_RESPONSES = 'SET_MESSAGES_RESPONSES',
  SET_MESSAGES = 'SET_MESSAGES',
  SET_MESSAGES_PAGE = 'SET_MESSAGES_PAGE',
  TOGGLE_MESSAGES_LOADER = 'TOGGLE_MESSAGES_LOADER',
  SET_LOADED_FROM_ACTION_BUTTONS = 'SET_LOADED_FROM_ACTION_BUTTONS'
}

export interface IActionSetMessages extends Action<string> {
  payload: IMessage[]
  type: MessagesActionTypes.SET_MESSAGES
}

export interface IActionSetMessagesPage extends Action<string> {
  payload: Nullable<number>
  type: MessagesActionTypes.SET_MESSAGES_PAGE
}

export interface ILoadedFromActionButtons extends Action<string> {
  payload: boolean
  type: MessagesActionTypes.SET_LOADED_FROM_ACTION_BUTTONS
}

export interface IActionSetResponses extends Action<string> {
  payload: IResponses
  type: MessagesActionTypes.SET_MESSAGES_RESPONSES
}

export interface IActionToggleLoader extends Action<string> {
  payload: boolean
  type: MessagesActionTypes.TOGGLE_MESSAGES_LOADER
}

export type Actions = IActionSetMessages | IActionSetResponses | IActionToggleLoader | IActionSetMessagesPage | ILoadedFromActionButtons

type ActionKeys = 'setMessages' | 'setResponses' | 'toggleLoader' | 'setMassagesPage' | 'setLoadedFromActiveButtons'

export type MessagesActions = { [key in ActionKeys]: ActionCreator<Action<string>> }

export type MessagesThunkAction = ThunkAction<Promise<AxiosResponse> | Promise<void>, IMessagesState, void, Action<string>>

export type MessagesThunkDispatch = ModuleThunkDispatch<IMessagesState>
